import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import { setTablePagination, setTableSort, resetTableSort } from "@store/ui";
import { setSelectedCountry, findAllActiveCountries } from "@store/country";
import TableHeader from "@components/table-header";
import { map, set } from "lodash";

const Country = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatch(
          findAllActiveCountries({ filter: {} })
        ).unwrap();
        const { data: countriesData, total } = data;

        setTableData(countriesData);
        setTableTotalData(total);

        dispatch(resetTableSort());
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      align: "left",
      fixed: "left",
      width: 80,
      render: (_, record) => {
        const { country_name, country_iso_code } = record;

        return (
          <div className={styles.table_column_title}>
            {`${country_name} (${country_iso_code})`}
          </div>
        );
      },
    },
    {
      title: "Currencies",
      dataIndex: "currencies",
      align: "left",
      width: 100,
      render: (_, record) => {
        const { currency } = record;

        if (currency.length > 0) {
          return map(currency, (item) => (
            <div className={styles.table_column_title}>
              {item.iso_code} - {item.currency_name}
            </div>
          ));
        } else {
          return <div>-</div>;
        }
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        const { is_enabled } = record;

        let status;
        let color;
        if (is_enabled === 1) {
          status = "Active";
          color = "#95de64";
        } else {
          status = "Disabled";
          color = "#FF0000";
        }
        return (
          <div align={"middle"} style={{ color }}>
            {status}
          </div>
        );
      },
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   align: "center",
    //   width: 80,
    //   fixed: "right",
    //   render: (_, record) => {
    //     return (
    //       <div align={"middle"}>
    //         <Space size="middle">
    //           <Button
    //             size={"small"}
    //             className="ant-btn-action "
    //             onClick={() => {
    //               dispatch(
    //                 setSelectedCountry({
    //                   selectedCountry: record,
    //                 })
    //               );

    //               navigate(`/new-country/${record.id}`);
    //             }}
    //           >
    //             View
    //           </Button>
    //         </Space>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Active Countries"
        showCreateButton={false}
        showResetButton={false}
        showFilterButton={false}
      />
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default Country;
