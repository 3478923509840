import React from "react";
import { Modal, Select } from "antd";
import { map } from "lodash";

// ============
import styles from "./styles.module.less";

const AssignUserPopUp = ({
  visible,
  data,
  handleOnOk,
  handleOnCancel,
  renderOption,
  onChange,
}) => {
  return (
    <Modal
      className={styles.modal_container}
      bodyStyle={{
        paddingTop: 24,
        paddingBottom: 12,
        textAlign: "center",
      }}
      visible={visible}
      closable={false}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      okText="Assign"
      cancelButtonProps={{
        style: { display: "inline-block" },
      }}
      okButtonProps={{
        style: { display: "inline-block" },
      }}
    >
      <div style={{ fontWeight: 700, fontSize: 18, marginBottom: 18 }}>
        Assign To User
      </div>
      <div style={{ fontWeight: 500, fontSize: 14, marginBottom: 18 }}>
        By choosing one of the user below, this case will be assigned to the
        user
      </div>
      <Select
        name="dashboard_user"
        size="middle"
        style={{
          width: "50%",
          minWidth: 100,
        }}
        className={styles.select_container}
        placeholder="Assign to user"
        bordered={false}
        onChange={onChange}
      >
        {map(data, (item, index) => {
          return renderOption(item, index);
        })}
      </Select>
    </Modal>
  );
};

// AssignUserPopUp.propTypes = {
// };

export default AssignUserPopUp;
