import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Input, Button, message, Modal } from "antd";
import { FiUser, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//====
import styles from "./styles.module.less";
import { resetTfa, setIsLoggedIn, login } from "@store/app";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tfaStatus = useSelector((state) => state.app.tfaStatus);
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn);
  const [modalVisible, setModalVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
      console.log("logged in");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(resetTfa());
  }, []);

  useEffect(() => {
    if (tfaStatus == true) {
      // navigate("/tfa");
      console.log("navigate tfa");
    } else {
      console.log("navigate home");
    }
  }, [tfaStatus]);

  const onFinish = async (values) => {
    console.log(values);

    try {
      const { data } = await dispatch(
        login({
          username: values.username,
          password: values.password,
        })
      ).unwrap();

      const { user: userData } = data;

      setUserInfo(userData);

      if (userData.is_temp_password == 1) {
        // setModalVisible(true);
        navigate({
          pathname: "/reset-password",
          search: `?username=${values.username}`,
        });
      } else {
        dispatch(setIsLoggedIn(true));

        //Temporarily navigate to NEW UI
        navigate("/top-up/bank/pending");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const onButtonOk = () => {
    navigate({
      pathname: "/reset-password",
      search: `?username=${userInfo.username}`,
    });
  };
  return (
    <>
      <Row className={styles.signin_container}>
        <Card className={styles.signin_card}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className={styles.signin_title}>Sign In</div>
            </Col>
            <Col span={24}>
              <div className={styles.signin_desc}>
                Log in to your account to continue.
              </div>
            </Col>
          </Row>

          <Form
            name="control-hooks"
            layout="vertical"
            requiredMark="optional"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="USERNAME"
              className={styles.signin_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your username",
                },
              ]}
            >
              <Input
                prefix={<FiUser className="grey_fill_icon" />}
                placeholder="e.g John_Doe"
              />
            </Form.Item>

            <Link
              to="/forgot-password"
              className={styles.signin_forgot_password_link}
            >
              Forgot Password?
            </Link>

            <Form.Item
              name="password"
              label="PASSWORD"
              className={styles.signin_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password
                prefix={<FiLock className="grey_fill_icon" />}
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 18,
                    width: 18,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="Password"
              />
            </Form.Item>

            <Button type="default" htmlType="submit" className="save-auth-form">
              Login
            </Button>
          </Form>

          <Modal
            className={styles.modal_container}
            bodyStyle={{
              paddingTop: 24,
              paddingBottom: 12,
              textAlign: "center",
            }}
            visible={modalVisible}
            closable={false}
            onOk={() => onButtonOk()}
            cancelButtonProps={{
              style: { display: "none" },
            }}
            okButtonProps={{
              style: { display: "inline-block" },
            }}
          >
            <div className={styles.reset_password_title}>
              You need to update your password because
              <br />
              this is the first time you are signing in.
            </div>
          </Modal>

          {/* <Row
            className={styles.signin_divider}
            align="middle"
            justify="center"
          >
            <span>OR</span>
          </Row>

          <Row align="middle" justify="center">
            <Button
              type="default"
              className={styles.signin_social_btn}
              onClick={() => console.log("clicked")} //Need to change
            >
              <FiFacebook className={styles.signin_social_icon} />
              <span>Facebook</span>
            </Button>
          </Row>

          <Row className={styles.signin_footer} align="middle" justify="center">
            <p>
              Not registered?
              <Link to="/signup" className={styles.signin_signup_link}>
                Create an account
              </Link>
            </p>
          </Row> */}
        </Card>
      </Row>
    </>
  );
};

export default SignIn;
