import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Image,
  Space,
  Divider
} from "antd";
// ============
import styles from "./styles.module.less";
import {
  findUserTransactionsByReferenceId,
  findRedeemById
} from "@store/transaction";
import { find, map, filter } from "lodash";
import moment from "moment-timezone";

const { Option } = Select;

const GiftCardEdit = () => {
  const { params } = useMatch("top-up/gift-card/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transaction = await dispatch(
          findRedeemById({
            id: params.id
          })
        ).unwrap();

        const { data } = transaction;
        console.log(data);
        setTransactionData(data);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const renderStatusColor = () => {
    return { color: "#339933" };
  };

  const renderStatus = () => {
    return "Successful";
  };
  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">View Top-Up </div>
        </Row>
        <Form
          colon={false}
          // ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
        >
          {/* <Row> */}
          {/* <Col span={18}> */}
          <Form.Item name="status" label="Status" className="label-details">
            <div style={renderStatusColor(transactionData.status)}>
              {renderStatus(transactionData.status)}
            </div>
          </Form.Item>
          <Form.Item name="doc_id" label="Top-Up ID" className="label-details">
            <div>{transactionData.doc_id}</div>
          </Form.Item>
          <Form.Item name="type" label="Top-Up Type" className="label-details">
            <div>
              {transactionData.transaction_type == "REDEEM"
                ? "Gift Card"
                : transactionData.transaction_type}
            </div>
          </Form.Item>
          <Form.Item
            name="code"
            label="Redemption Code"
            className="label-details"
          >
            <div>{transactionData.voucher_code}</div>
          </Form.Item>
          <Form.Item
            name="date"
            label="Submission Date"
            className="label-details"
          >
            <div>
              {moment
                .utc(transactionData.created_at)
                .tz("Asia/Phnom_Penh")
                .format("DD-MM-YYYY, hh:mm:ss A")}
            </div>
          </Form.Item>

          <Divider className="divider" />
          <div className="section_subtitle">Member</div>
          <Form.Item
            name="customer_topkash_id"
            label="Topkash ID"
            className="label-details"
          >
            <div>
              {transactionData.customer_topkash_id
                ? transactionData.customer_topkash_id
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="username" label="Username" className="label-details">
            <div>
              {transactionData.reference_username
                ? transactionData.reference_username
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="name" label="Full Name" className="label-details">
            <div>
              {transactionData.customer_fullname
                ? transactionData.customer_fullname
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="mobile" label="Mobile" className="label-details">
            <div>
              {transactionData.customer_mobile
                ? transactionData.customer_mobile
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="email" label="Email" className="label-details">
            <div>
              {transactionData.customer_email
                ? transactionData.customer_email
                : "-"}
            </div>
          </Form.Item>
          <Divider className="divider" />
          <Form.Item name="currency" label="Currency" className="label-details">
            <div>{transactionData.from_currency_code}</div>
          </Form.Item>
          <Form.Item name="amount" label="Amount" className="label-details">
            <div style={{ color: "#027e54" }}>
              {numeral(parseFloat(transactionData.amount)).format("0,0.00")}
            </div>
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default GiftCardEdit;
