import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler, queryBuilder } from "../configs";

const initialState = {};

export const retrieveFaqCategories = createAsyncThunk(
  "faq/retrieveFaqCategories",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("faqs/category", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findFaqCategoryById = createAsyncThunk(
  "faq/findFaqCategoryById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`faqs/category/${id}`);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findFaqLayerById = createAsyncThunk(
  "faq/findFaqLayerById",
  async ({ id, type }) => {
    let url = `faqs/layer-one/${id}`;
    if (type === "two") {
      url = `faqs/layer-two/${id}`;
    }
    try {
      const { data } = await axios.get(url);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const addFaqCategory = createAsyncThunk(
  "faq/createFaqCategory",
  async (values) => {
    try {
      const { data } = await axios.post("faqs/category/add", values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const addFaqLayer = createAsyncThunk(
  "faq/addFaqLayer",
  async (values) => {
    const { type, ...payload } = values;

    try {
      const { data } = await axios.post(`faqs/layer/add/${type}`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateFaqCategoryById = createAsyncThunk(
  "faq/updateFaqCategoryById",
  async (values) => {
    try {
      const { id, ...allData } = values;

      const { data } = await axios.patch(`faqs/category/${id}`, allData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateFaqLayerById = createAsyncThunk(
  "faq/updateFaqLayerById",
  async (values) => {
    try {
      const { id, type, ...allData } = values;

      let url = `faqs/layer-one/${id}`;

      if (type === "two") {
        url = `faqs/layer-two/${id}`;
      }
      const { data } = await axios.patch(url, allData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = faqSlice.actions;

export default faqSlice.reducer;
