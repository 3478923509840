import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  Menu,
  Dropdown,
  Button,
  Modal,
  message,
  Tooltip,
  Space,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { retrieveBanks, deleteBank, setSelectedBank } from "../../store/bank";

const Bank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [isEnableDelete, setIsEnableDelete] = useState(false);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedDeleteBankId, setSelectedDeleteBankId] = useState(null);

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isEnableDelete]);

  const editOnClick = async (item) => {
    navigate(`/bank/${item.id}`);

    dispatch(
      setSelectedBank({
        selectedBank: item.name,
      })
    );
  };

  const deleteOnClick = async (item) => {
    setIsConfirmModalVisible(true);
    setSelectedDeleteBankId(item.id);
    setIsEnableDelete(false);
  };

  const deleteSelectedBank = async () => {
    try {
      const { data } = await dispatch(
        deleteBank({
          id: selectedDeleteBankId,
        })
      ).unwrap();

      setIsConfirmModalVisible(false);
      message.success(data.message);

      setTimeout(() => {
        setIsEnableDelete(true);
      }, 1000);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      const bank = await dispatch(retrieveBanks()).unwrap();
      const { data } = bank.data;

      setTableData(data);

      setTableTotalData(data.length);

      dispatch(resetTableSearch());
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(setTableSearch({ search: text }));
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const columns = [
    // {
    //   title: "Bank ID",
    //   dataIndex: "id",
    //   key: "id",
    //   width: "10%",
    //   align: "center",
    // },
    {
      title: "Bank Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
      key: "action",
      render: (_, record) => {
        return (
          <div>
            <Space size="middle">
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#4361ee" }}
                  onClick={() => editOnClick(record)}
                />
              </Tooltip>

              <Tooltip title="Delete">
                <DeleteOutlined
                  style={{ fontSize: "15px", color: "#FF0000" }}
                  onClick={() => deleteOnClick(record)}
                />
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row justify="space-between" align="middle">
          <Row align="middle">
            <div className="table_search_input">
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
                onChange={(e) => {
                  onChangeText(e.target.value);
                }}
              />
            </div>
          </Row>
          <Row justify="end">
            <div className={styles.secondary_btn}>
              <Button
                className="secondary_btn"
                onClick={() => navigate("/bank-add")}
              >
                + Create
              </Button>
            </div>
          </Row>
        </Row>
      </div>

      <Table
        className={styles.pagination_item}
        columns={columns}
        onChange={onChange}
        // expandable={{
        //   expandedRowRender,
        //   rowExpandable,
        //   defaultExpandedRowKeys: ["0"],
        // }}
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        dataSource={tableData}
        size="middle"
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
        }}
      />

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={isConfirmModalVisible}
        closable={false}
        onOk={deleteSelectedBank}
        onCancel={() => {
          setIsConfirmModalVisible(false);
        }}
        okText="Confirm"
        cancelButtonProps={{
          style: { display: "inline-block" },
        }}
        okButtonProps={{
          style: { display: "inline-block" },
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 14, marginBottom: 18 }}>
          Do you confirm to delete this bank?
        </div>
      </Modal>
    </div>
  );
};

export default Bank;
