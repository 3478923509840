import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  message,
  Form,
  Select,
  DatePicker,
  Space,
  Button,
  Row
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import numeral from "numeral";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";

import {
  retrieveCryptoNetworks,
  retrieveCryptoProviders,
  findAllCryptoReloads
} from "../../../../store/crypto";
import { findAllStatuses, assignCaseToUser } from "@store/app";
import TableHeader from "@components/table-header";
import { findCountryFilterOptionsByType } from "@store/country";
import { findCurrencyFilterOptionsByType } from "@store/currency";
import { findAllCaseAssignee } from "@store/user";
import { useAbility } from "@casl/react";
import { AbilityContext } from "@configs/can";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Transaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [provider, setProvider] = useState([]);
  const [network, setNetwork] = useState([]);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const ability = useAbility(AbilityContext);

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());

    initialize();
    fetchData();
  }, []);

  const initialize = async () => {
    try {
      const statusPayload = await dispatch(findAllStatuses()).unwrap();
      const { data: statusData } = statusPayload;

      const filteredStatusData = statusData.filter((item) =>
        ["Pending", "Processing", "Successful", "Unsuccessful"].includes(
          item.name
        )
      );

      setStatuses(filteredStatusData);

      const dashboardUserPayload = await dispatch(
        findAllCaseAssignee({ paginate: false })
      ).unwrap();
      const { data: dashboardUserData } = dashboardUserPayload;

      const dashboardUser = map(dashboardUserData, (item) => {
        return {
          value: item.username,
          label: item.username
        };
      });
      setDashboardUsers(dashboardUser);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let transaction = await dispatch(
        findAllCryptoReloads({ filter })
      ).unwrap();

      let { data, total } = transaction.data;

      let { data: countryFilterOptions } = await dispatch(
        findCountryFilterOptionsByType({ type: "reloads" })
      ).unwrap();

      countryFilterOptions = map(countryFilterOptions, (item) => {
        return {
          label: item.name,
          value: item.iso_code
        };
      });

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "reloads" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      // setCountries(countryFilterOptions);
      // setCurrencies(currencyFilterOptions);

      // Retrieve providers
      const provider = await dispatch(retrieveCryptoProviders()).unwrap();
      const { data: providersData } = provider;
      setProvider(providersData);

      // Retrieve networks
      const network = await dispatch(retrieveCryptoNetworks()).unwrap();
      const { data: networksData } = network;
      setNetwork(networksData);

      setTableData(data);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const actionBtnOnClick = async (status, refId) => {
    if (ability.can("update", "Reload")) {
      if (status === 1) {
        navigate(`/top-up/crypto/${refId}?mode=edit`);
        // if (ability.cannot("update", "Reload")) {
        try {
          await dispatch(
            assignCaseToUser({
              ref_id: refId,
              type: "reloads",
              action: "assign"
            })
          ).unwrap();
        } catch (error) {
          message.error(error.message);
        }
        // }
      } else if (status === 2) {
        navigate(`/top-up/crypto/${refId}?mode=edit`);
      } else {
        navigate(`/top-up/crypto/${refId}?mode=view`);
      }
    } else {
      console.log("cannot");
      navigate(`/top-up/crypto/${refId}?mode=view`);
    }
  };

  // const resetButtonOnClick = () => {
  //   dispatch(resetTablePagination());
  //   dispatch(resetTableFilter());
  //   setFilter({});
  //   fetchData();
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 110,
      render: (_, record) => {
        return <div>{record.doc_id}</div>;
      }
    },
    {
      title: (
        <>
          <div>Submission</div>
          <div>Date (GMT +7)</div>
        </>
      ),
      dataIndex: "created_at",
      align: "left",
      width: 200,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {created_at != null
              ? moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : "-"}
          </div>
        );
      }
    },

    {
      title: (
        <>
          <div>Sender</div>
          <div>Topkash ID</div>
        </>
      ),
      dataIndex: "user_topkash_id",
      align: "left",
      width: 110,
      render: (_, record) => {
        if (record.user_topkash_id) {
          return <div>{record.user_topkash_id}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Sender</div>
          <div>Username</div>
        </>
      ),
      dataIndex: "user_username",
      align: "left",
      width: 110,
      render: (_, record) => {
        if (record.user_username) {
          return <div>{record.user_username}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Currency",
      dataIndex: "reload_currency_iso_code",
      align: "right",
      width: 80
    },

    {
      title: <div style={{ textAlign: "right" }}>Amount</div>,
      dataIndex: "amount",
      align: "right",
      width: 160,
      render: (_, record) => {
        if (record.amount) {
          return (
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(record.amount)).format("0,0.00")}
            </div>
          );
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Crypto</div>
          <div>Provider</div>
        </>
      ),
      dataIndex: "crypto_provider",
      align: "left",
      width: 160,
      render: (_, record) => {
        if (record.crypto_account_provider) {
          return <div>{record.crypto_account_provider}</div>;
        } else if (record.crypto_account_provider) {
          return <div>{record.crypto_account_provider}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Crypto</div>
          <div>Network</div>
        </>
      ),
      dataIndex: "crypto_network",
      align: "left",
      width: 160,
      render: (_, record) => {
        if (record.crypto_account_network) {
          return <div>{record.crypto_account_network}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Address",
      dataIndex: "crypto_address",
      align: "left",
      width: 160,
      render: (_, record) => {
        if (record.crypto_account_address) {
          return <div>{record.crypto_account_address}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Assigned To",
      dataIndex: "assigned_user_username",
      align: "left",
      width: 100,
      render: (_, record) => {
        if (record.assigned_user_username) {
          return <div>{record.assigned_user_username}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: (
        <>
          <div>Processed</div>
          <div>Date (GMT +7)</div>
        </>
      ),
      dataIndex: "processed_at",
      align: "left",
      width: 200,
      sorter: true,
      render: (_, record) => {
        return (
          <div>
            {record.processed_at != null
              ? moment
                  .utc(record.processed_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : "-"}
          </div>
        );
      }
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        let fontWeight;

        if (record.status === 3) {
          status = "Successful";
          color = "#339933";
        } else if (record.status === 5) {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.status === 2) {
          status = "Processing";
          color = "#4f8bc2";
        } else {
          status = "Pending";
          color = "#ff8000";
          fontWeight = 700;
        }

        return <div style={{ color, fontWeight }}>{status}</div>;
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        if (ability.can("update", "Reload")) {
          const { status, ref_id } = record;

          let ctaText;

          if (status === 3 || status === 5) {
            ctaText = "View";
          } else {
            ctaText = "Edit";
          }

          return (
            <div align={"middle"}>
              <Space size="middle">
                <Button
                  size={"small"}
                  className="ant-btn-action "
                  onClick={() => actionBtnOnClick(status, ref_id)}
                >
                  {ctaText}
                </Button>
              </Space>
            </div>
          );
        } else {
          const { status, ref_id } = record;
          return (
            <div align={"middle"}>
              <Space size="middle">
                <Button
                  size={"small"}
                  className="ant-btn-action "
                  onClick={() => actionBtnOnClick(status, ref_id)}
                >
                  View
                </Button>
              </Space>
            </div>
          );
        }
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Top-Ups (Crypto)"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"user_topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Sender Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"user_username"} initialValue={null}>
                  <Input
                    placeholder="Sender Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"crypto_provider"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Provider"
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {map(provider, (item) => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"crypto_network"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Network"
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {map(network, (item) => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"crypto_address"} initialValue={null}>
                  <Input
                    placeholder="Address"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"assigned_to"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Assigned To"
                    options={
                      dashboardUsers && dashboardUsers.length > 0
                        ? dashboardUsers
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"status"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    showSearch
                    className="table-search-select"
                    placeholder="Status"
                    showArrow
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {map(statuses, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <div className={styles.filter_date_label}>Submission Date:</div>
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    placeholder={["Submission start date", "End date"]}
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
              <Col span="8" offset={16}>
                <div className={styles.filter_date_label}>Processed Date:</div>
                <Form.Item name="processed_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="processed_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    placeholder={["Processed start date", "End date"]}
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Transaction;
