import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";
import { isEmpty } from "lodash";

const initialState = {};

export const retrieveBaseFixedRates = createAsyncThunk(
  "exchange/retrieveBaseFixedRates",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("base-fixed-rates", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveBaseFixedRatesList = createAsyncThunk(
  "exchange/retrieveBaseFixedRates",
  async ({ filter }, { getState }) => {
    try {
      let url = queryBuilder(
        "base-fixed-rates/list",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createProcessingFeeBaseFixedRate = createAsyncThunk(
  "base-fixed-rates/createProcessingFeeBaseFixedRate",
  async (values) => {
    const { from_currency_id, to_currency_id, amount, date } = values;

    try {
      let formData = new FormData();

      formData.append("from_currency_id", from_currency_id);
      formData.append("to_currency_id", to_currency_id);
      formData.append("amount", amount);
      formData.append("date", date);

      const { data } = await axios.post("base-fixed-rates/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const baseFixedRateSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    resetExchange: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { resetExchange } = baseFixedRateSlice.actions;

export default baseFixedRateSlice.reducer;
