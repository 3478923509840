const constant = {
  activityLogPath: {
    reloads: {
      TB: "/top-up/bank/:id?mode=view",
      TC: "/top-up/crypto/:id?mode=view",
    },
    transfers: {
      SB: "/send/bank/:id?mode=view",
      SM: "/send/member/:id",
      CV: "/conversion/:id",
    },
    currencies: "/settings/currency/:id",
    members: "/member/:id?mode=edit",
    bank_accounts: "/settings/bank-account/:id",
    banks: "/settings/bank/:id",
    customer_service_users: "/customer-service-user/:id",
    exchange_margin_rates: "/settings/margin",
    processing_fee_base_fixed_rates: "/settings/fee",
    money_changers: "/settings/money-changer/:id",
    merchants: "/settings/merchant/:id",
    users: "/settings/staff/:id",
    redeems: "/top-up/gift-card/:id",
    banner: "/settings/banner/:id",
    crypto_accounts: "/settings/crypto-account/:id",
    crypto_providers: "/settings/crypto-provider/:id",
    communication_channels: "/settings/communication-channel/:id",
    error: "/error",
    adjustments: "adjustments/:id",
    exchange_rate_settings: "/settings/manual-cost-exchange-rate/:id",
    business_types: "settings/business-type/:id",
  },
};
export default constant;
