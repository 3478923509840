import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Select,
  Button,
  Form,
  Col,
  DatePicker
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce, lowerCase, map, startCase } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "../../../store/ui";
import { retrieveBanks } from "@store/bank";
import { findAllBankAccounts, setSelectedBankAccount } from "@store/bank";
import TableHeader from "../../../components/table-header";

const { Option } = Select;
const { RangePicker } = DatePicker;

const BankAccounts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const currencies = useSelector((state) => state.currency.currencies);
  const countries = useSelector((state) => state.country.allCountries);
  const banks = useSelector((state) => state.bank.banks);
  const [isLoading, setIsLoading] = useState(false);
  // const temp = useSelector((state) => console.log(state.bank));

  //   const [statuses, setStatuses] = useState([])
  const [filter, setFilter] = useState({});

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const statusOption = [
    {
      value: "1",
      label: "Active"
    },
    {
      value: "2",
      label: "Inactive"
    }
  ];

  useEffect(() => {
    dispatch(resetTableFilter());
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(findAllBankAccounts({ filter })).unwrap();
      await dispatch(retrieveBanks()).unwrap();

      const { data: bankAccData, total } = data;

      setTableData(bankAccData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const editOnClick = async (item) => {
    dispatch(setSelectedBankAccount({ selectedBankAccount: item }));
    navigate(`/settings/bank-account/${item.bank_acc_id}`);
  };

  const columns = [
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} left`}>
            <div>Bank Acc ID</div>
          </span>
        );
      },
      dataIndex: "bank_acc_id",
      align: "left",
      fixed: "left",
      width: 60,
      key: "id",
      render: (_, record) => {
        const { bank_acc_id } = record;
        return <div>{bank_acc_id ? bank_acc_id : "-"}</div>;
      }
    },
    {
      title: "Country",
      dataIndex: "country_",
      align: "left",
      key: "country_name",
      width: 120,
      render: (_, record) => {
        const { country_name } = record;
        return <div style={{ textAlign: "left" }}>{country_name}</div>;
      }
    },
    {
      title: "Currency",
      dataIndex: "currency_code",
      align: "right",
      key: "currency_code",
      width: 120,
      render: (_, record) => {
        const { currency_code } = record;
        return <div style={{ textAlign: "right" }}>{currency_code}</div>;
      }
    },
    {
      title: "Bank",
      dataIndex: "bank_name",
      align: "left",
      width: 120,
      key: "bank_name",
      render: (_, record) => {
        const { bank_name } = record;
        return <div>{bank_name ? bank_name : "-"}</div>;
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} left`}>
            <div>Bank</div>
            <div>Acc No</div>
          </span>
        );
      },
      dataIndex: "acc_no",
      align: "left",
      width: 120,
      key: "account_no",
      render: (_, record) => {
        const { acc_no } = record;
        return <div>{acc_no ? acc_no : "-"}</div>;
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} left`}>
            <div>Bank</div>
            <div>Acc Name</div>
          </span>
        );
      },
      dataIndex: "acc_name",
      align: "left",
      width: 120,
      key: "account_name",
      render: (_, record) => {
        const { acc_name } = record;
        return <div>{acc_name ? acc_name : "-"}</div>;
      }
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 120,
      sorter: true,
      key: "created_at",
      render: (_, record) => {
        return <div>{moment(record.created_at).format("DD-MM-YYYY")}</div>;
      }
    },
    {
      title: "Top-Up Status",
      dataIndex: "status",
      align: "center",
      fixed: "right",
      width: 90,
      key: "status",
      render: (_, record) => {
        let status;
        let color;

        if (record.top_up_status === 1) {
          status = "Active";
          color = "#95de64";
        } else if (record.top_up_status === 2) {
          status = "Inactive";
          color = "#FF0000";
        }

        return <div style={{ color }}>{status}</div>;
      }
    },
    {
      title: "Send Status",
      dataIndex: "status",
      align: "center",
      fixed: "right",
      width: 90,
      key: "status",
      render: (_, record) => {
        let status;
        let color;

        if (record.send_status === 1) {
          status = "Active";
          color = "#95de64";
        } else if (record.send_status === 2) {
          status = "Inactive";
          color = "#FF0000";
        }

        return <div style={{ color }}>{status}</div>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 90,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Bank Accounts"
        showCreateButton={true}
        showResetButton={true}
        showFilterButton={true}
        createButtonText="Create New Bank Account"
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
        createOnClick={() => navigate("/settings/bank-account/add")}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="Bank Acc ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"country"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Country"
                    options={
                      countries && countries.length > 0
                        ? map(countries, (item) => {
                            return {
                              value: item.iso_code_2,
                              label: item.name
                            };
                          })
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"currency_code"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Currency"
                    options={
                      currencies && currencies.length > 0
                        ? map(currencies, (item) => {
                            return {
                              value: item.iso_code,
                              label: item.iso_code
                            };
                          })
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"bank"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Bank"
                    options={
                      banks && banks.length > 0
                        ? map(banks, (item) => {
                            return {
                              value: item.name,
                              label: item.name
                            };
                          })
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name={"acc_no"} initialValue={null}>
                  <Input
                    placeholder="Bank Acc No"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name={"acc_name"} initialValue={null}>
                  <Input
                    placeholder="Bank Acc Name"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8"></Col>
              <Col span="8">
                <Form.Item
                  name={"status"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Status"
                    showArrow
                  >
                    {map(statusOption, (item) => {
                      return (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        onChange={onChange}
        loading={isLoading}
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        dataSource={tableData}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default BankAccounts;
