import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Row,
  Input,
  Table,
  Select,
  Form,
  Col,
  message,
  DatePicker,
  Space,
  Button
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { map } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useAbility } from "@casl/react";
import numeral from "numeral";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "../../../store/ui";
import { assignCaseToUser } from "../../../store/app";
import { findAllConverts } from "../../../store/transfer";
import AssignUserPopUp from "../../../components/assign-user-popup";
import TableHeader from "../../../components/table-header";
import { AbilityContext } from "@configs/can";
import { WebSocketContext } from "@configs";
import { findCurrencyFilterOptionsByType } from "@store/currency";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Conversion = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [currencies, setCurrencies] = useState([]);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableTotalData, setTableTotalData] = useState(0);
  const webSocket = useContext(WebSocketContext);

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [filter, setFilter] = useState({});

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const viewOnClick = (item) => {
    navigate(`/conversion/${item.id}`);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(findAllConverts({ filter })).unwrap();
      const { data: transferData, total } = data;

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "transfers" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      setCurrencies(currencyFilterOptions);

      setTableData(transferData);
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const resetButtonOnClick = () => {
  //   dispatch(resetTablePagination());
  //   dispatch(resetTableFilter());
  //   setFilter({});
  //   fetchData();
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      width: 110,
      render: (_, record) => {
        return (
          <>
            <div style={{ textAlign: "left" }}>{record.doc_id}</div>
          </>
        );
      }
    },
    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Conversion</div>
            <div>Date (GMT +7)</div>
          </span>
        );
      },
      dataIndex: "created_at",
      width: 200,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <>
            <div style={{ textAlign: "left" }}>
              {moment
                .utc(created_at)
                .tz("Asia/Phnom_Penh")
                .format("DD-MM-YYYY, hh:mm:ss A")}
            </div>
          </>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Member</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      align: "left",
      width: 150,
      dataIndex: "user_topkash_id",
      key: "user_topkash_id",
      render: (_, record) => {
        const { user_topkash_id } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {user_topkash_id ? user_topkash_id : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Member</div>
            <div>Username</div>
          </span>
        );
      },
      align: "left",
      width: 150,
      dataIndex: "user_username",
      key: "user_username",
      render: (_, record) => {
        const { user_username } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {user_username ? user_username : "-"}
          </div>
        );
      }
    },

    // {
    //   title: () => {
    //     return (
    //       <span className={styles.table_column_title}>
    //         <div>Country</div>
    //       </span>
    //     );
    //   },
    //   dataIndex: "country",
    //   align: "center",
    //   width: "10%",
    //   key: "country",

    //   render: (_, record) => {
    //     return (
    //       <div style={{ textAlign: "left" }}>
    //         {record.country_name ? record.country_name : "-"}
    //       </div>
    //     );
    //   },
    // },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div align={"right"}>From</div>
            <div align={"right"}>Currency</div>
          </span>
        );
      },
      align: "right",
      width: 110,
      dataIndex: "from_currency_code",
      key: "from_currency_code",
      render: (_, record) => {
        const { from_currency_code } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {from_currency_code ? from_currency_code : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>From</div>
            <div>Amount</div>
          </span>
        );
      },
      align: "right",
      width: 180,
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        const { amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {numeral(parseFloat(amount)).format("0,0.00")}
          </div>
        );
      }
    },
    {
      title: "Fees",
      align: "right",
      width: 140,
      dataIndex: "processing_fee",
      key: "processing_fee",
      render: (_, record) => {
        const { processing_fee } = record;

        let fee = processing_fee;
        if (parseFloat(processing_fee) === 0.0) {
          fee = "-";
        } else {
          fee = processing_fee;
        }

        return (
          <>
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(fee)).format("0,0.00") ? fee : "-"}
            </div>
          </>
        );
      }
      //
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Amount</div>
            <div>To Convert</div>
          </span>
        );
      },
      align: "right",
      width: 180,
      dataIndex: "amount_convert",
      key: "amount_convert",
      render: (_, record) => {
        const { amount, processing_fee } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(amount - processing_fee) > 0
              ? numeral(parseFloat(amount - processing_fee)).format("0,0.00")
              : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Exchange</div>
            <div>Rate</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "exchange_rate",
      key: "exchange_rate",
      render: (_, record) => {
        const { exchange_rate, from_currency_code, to_currency_code } = record;

        let rate = exchange_rate;
        if (from_currency_code === to_currency_code) {
          rate = "1.000000";
        }

        return (
          <div style={{ textAlign: "right" }}>
            {rate ? numeral(parseFloat(rate)).format("0,0.000000") : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>To</div>
            <div>Currency</div>
          </span>
        );
      },
      align: "right",
      width: 110,
      dataIndex: "to_currency_code",
      key: "to_currency_code",
      render: (_, record) => {
        const { to_currency_code } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {to_currency_code ? to_currency_code : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>To</div>
            <div>Amount</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "converted_amount",
      key: "converted_amount",
      render: (_, record) => {
        const { converted_amount } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(converted_amount)).format("0,0.00")}
            </div>
          </>
        );
      }
    },

    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Cost</div>
            <div>Exchange Rate</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "cost_exchange_rate",
      key: "exchange_rate",
      render: (_, record) => {
        const { cost_exchange_rate } = record;

        return (
          <div style={{ textAlign: "right" }}>
            {cost_exchange_rate ? cost_exchange_rate : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Margin</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "exchange_margin_rate",
      key: "exchange_margin_rate",
      render: (_, record) => {
        const { exchange_margin_rate } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>{exchange_margin_rate}</div>
          </>
        );
      }
    },

    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Earning</div>
            <div>Currency</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "to_currency_code",
      key: "to_currency_code",
      render: (_, record) => {
        const { to_currency_code } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>{to_currency_code}</div>
          </>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Earning</div>
            <div>Amount</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "exchange_rate_margin",
      key: "exchange_rate_margin",
      render: (_, record) => {
        const { exchange_rate_margin } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(exchange_rate_margin)).format("0,0.00")}
            </div>
          </>
        );
      }
    },
    {
      title: () => {
        return (
          <span
            className={styles.table_column_title}
            style={{ textAlign: "right" }}
          >
            <div>Earning</div>
            <div>Amount (6 D.P.)</div>
          </span>
        );
      },
      align: "right",
      width: 140,
      dataIndex: "exchange_rate_margin",
      key: "exchange_rate_margin",
      render: (_, record) => {
        const { exchange_rate_margin } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>
              {" "}
              {numeral(parseFloat(exchange_rate_margin)).format("0,0.000000")}
            </div>
          </>
        );
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"center"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => viewOnClick(record)}
              >
                View
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Conversions"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            {/* <Col span="24"> */}
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name={"topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Member Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"username"} initialValue={null}>
                  <Input
                    placeholder="Member Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"from_currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="From Currency"
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"to_currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="To Currency"
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* </Col> */}
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Conversion;
