import React, { useRef } from "react";
import { Modal, Select, Row, Col, Form, Button, Space, Divider } from "antd";
import PropTypes from "prop-types";
import styles from "./styles.module.less";

const ConfirmationPopUp = ({
  children,
  visible,
  title,
  handleOnOk,
  handleOnCancel,
  modalFormOnFinish,
}) => {
  const formRef = useRef();

  const formOnFinish = (values) => {
    modalFormOnFinish(values);
  };

  return (
    <>
      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 0,
          paddingBottom: 0,
          textAlign: "left",
        }}
        visible={visible}
        title={
          <>
            <div lassName="page-title">{title}</div>
            <Divider />
          </>
        }
        closable={false}
        footer={[
          <Divider />,
          <Row justify="end">
            <Button htmlType="cancel" onClick={handleOnCancel}>
              Cancel
            </Button>

            <Button onClick={handleOnOk} className="ant-btn-green">
              Save
            </Button>
          </Row>,
        ]}
      >
        {children}
      </Modal>
    </>
  );
};

ConfirmationPopUp.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ConfirmationPopUp;
