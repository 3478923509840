import React, { useEffect, useState, useCallback } from "react";
import { Col, Table, message, DatePicker, Form, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { capitalCase } from "change-case";
import { findAllDashboardUsers } from "@store/user";
import { findAllExchangeMarginRate } from "@store/exchange";
import TableHeader from "@components/table-header";
import { map, find } from "lodash";

const { Option } = Select;

const ExchangeRateMargin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const [filter, setFilter] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);
  const [optionFromCurrency, setOptionFromCurrency] = useState(0);
  const [optionToCurrency, setOptionToCurrency] = useState(0);
  const [fromCurrency, setFromCurrency] = useState([]);
  const [toCurrency, setToCurrency] = useState([]);
  const [users, setUsers] = useState([]);
  const currencies = useSelector((state) => state.currency?.currencies);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    currencyList();
  }, [currencies]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        findAllExchangeMarginRate({ filter })
      ).unwrap();
      const { data: marginData, total } = data;

      const { data: userData } = await dispatch(
        findAllDashboardUsers({ paginate: false })
      ).unwrap();
      setUsers(userData);

      setTableData(marginData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const currencyList = () => {
    let option = map(currencies, (item) => {
      return {
        label: item.iso_code,
        value: item.id
      };
    });

    setOptionFromCurrency(option);
    let optionTo = remappedCurrencyList(fromCurrency);
    setOptionToCurrency(optionTo);
  };

  const handleToCurrency = (value) => {
    setToCurrency(value);
    let option = remappedCurrencyList(value);
    setOptionFromCurrency(option);
  };

  const handleFromCurrency = (values) => {
    setFromCurrency(values);
    let option = remappedCurrencyList(values);
    setOptionToCurrency(option);
  };

  const remappedCurrencyList = (values) => {
    let option = map(currencies, (item) => {
      if (values.includes(item.id)) {
        return {
          label: item.iso_code,
          value: item.id,
          disabled: true
        };
      } else {
        return {
          label: item.iso_code,
          value: item.id
        };
      }
    });

    return option;
  };

  const typeOption = [
    { value: "normal", label: "Normal" },
    { value: "crypto", label: "Crypto" }
  ];

  const columns = [
    {
      title: "Date of Last Update",
      dataIndex: "created_at",
      align: "left",
      width: 80,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment
              .utc(created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        ); // 16:23
        // return <div>{moment(created_at).format("DD-MM-YYYY, hh:mm:ss A")}</div>; // 12:23
      }
    },
    {
      title: "Margin Type ",
      dataIndex: "rate",
      align: "left",
      width: 50,
      render: (_, record) => {
        const { type } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {type ? capitalCase(type) : "-"}
          </div>
        );
      }
    },

    {
      title: "From Currency",
      dataIndex: "from_currency_id",
      align: "right",
      width: 80,
      render: (_, record) => {
        const { from_currency_id } = record;
        const from_currency = find(
          currencies,
          (item) => item.id === from_currency_id
        );

        return (
          <div style={{ textAlign: "right" }}>
            {from_currency?.iso_code ?? "-"}
          </div>
        );
      }
    },

    {
      title: "To Currency",
      dataIndex: "to_currency_id",
      align: "right",
      width: 80,
      render: (_, record) => {
        const { to_currency_id } = record;
        const to_currency = find(
          currencies,
          (item) => item.id === to_currency_id
        );
        return (
          <div style={{ textAlign: "right" }}>
            {to_currency?.iso_code ?? "-"}
          </div>
        );
      }
    },
    {
      title: "Margin (Percentage Rate)",
      dataIndex: "rate",
      align: "right",
      width: 80,
      render: (_, record) => {
        const { rate } = record;

        return <div style={{ textAlign: "right" }}>{rate}</div>;
      }
    },
    {
      title: () => (
        <span
          className={styles.table_column_title}
          style={{ textAlign: "left" }}
        >
          <div>Created By</div>
        </span>
      ),
      dataIndex: "created_by",
      align: "center",
      width: 50,
      render: (_, record) => {
        const { created_by } = record;
        const dashboardUsers = find(users, (item) => item.id === created_by);
        return (
          <div style={{ textAlign: "left" }}>
            {dashboardUsers?.username ?? "-"}
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Margin"
        showCreateButton={true}
        createButtonText={"Update Margin"}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        createOnClick={() => navigate("/settings/margin/add")}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>

          <Col span="6">
            <Form.Item
              name={"type"}
              initialValue={[]}
              className={styles.multiple_select}
            >
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Margin Type"
                showArrow
              >
                {map(typeOption, (item) => {
                  return (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"from_currency"} initialValue={[]}>
              <Select
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="From Currency"
                options={optionFromCurrency}
                onChange={(values) => handleFromCurrency(values)}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"to_currency"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="To Currency"
                options={optionToCurrency}
                onChange={(values) => handleToCurrency(values)}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default ExchangeRateMargin;
