import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Row, Button, Alert, message, Select } from "antd";
// ============
// import styles from "./styles.module.less";
// import { addBank } from "@store/bank";
// import { getAllCountries } from "@store/country";

const ErrorPage = () => {
  return (
    <div>
      <Card>You can't access to this page</Card>
    </div>
  );
};

export default ErrorPage;
