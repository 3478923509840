import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
import { map } from "lodash";
import { paramCase, capitalCase } from "change-case";
// ============
import styles from "./styles.module.less";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { addFaqLayer } from "../../../store/faq";

const { Option } = Select;

const FaqLayerAdd = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusOption = [
    { value: 0, label: "Disabled" },
    { value: 1, label: "Enabled" },
  ];
  const [value, setValue] = useState("**Hello world!!!**");
  const { type, id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = async (values) => {
    let payload = {
      type,
      faq_category_id: id,
      ...values,
    };

    if (type === "two") {
      payload = {
        type,
        faq_layer_one_id: id,
        ...values,
      };
    }
    if (values) {
      try {
        const { data } = await dispatch(addFaqLayer(payload)).unwrap();

        message.success(data.message);
        navigate(-1);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>
            New FAQ Layer {capitalCase(type)}
          </div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
            is_enable_mail_support: statusOption[0].value,
            is_enable_call_us: statusOption[0].value,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="title"
            className={styles.input}
            label="Title"
            rules={[
              {
                required: true,
                message: "Please enter your title",
              },
              {
                required: true,
                validator: async (_, value) => {
                  const regex = /^\s+|\s+$/g;

                  if (value) {
                    if (regex.test(value)) {
                      throw new Error(
                        "Title should not contain space before and after it"
                      );
                    }
                  }
                },
              },
            ]}
          >
            <Input
              placeholder="Title"
              onChange={(e) => {
                formRef.current.setFieldValue(
                  "slug",
                  paramCase(e.target.value)
                );
              }}
            />
          </Form.Item>

          <Form.Item name="slug" label="Slug" className="input">
            <Input disabled placeholder="Slug" />
          </Form.Item>

          <Form.Item name="content" label="Content" className="md_editor_style">
            <MDEditor
              value={value}
              onChange={setValue}
              commands={[
                commands.bold,
                commands.strikethrough,
                commands.hr,
                commands.title,
                commands.link,
                commands.quote,
                commands.code,
                commands.codeBlock,
                commands.comment,
                commands.image,
                commands.table,
                commands.checkedListCommand,
                commands.unorderedListCommand,
                commands.codePreview,
                commands.divider,
                commands.fullscreen,
                commands.codeEdit,
              ]}
              height={500}
              data-color-mode="light"
            />
          </Form.Item>

          <Form.Item
            name="code"
            className={styles.input}
            label="Code"
            rules={[
              {
                required: false,
                validator: async (_, value) => {
                  const regex = /\s/g;

                  if (value && regex.test(value)) {
                    return Promise.reject(
                      new Error("Code should not contain any space")
                    );
                  }
                },
              },
            ]}
          >
            <div className={styles.password_input}>
              <Input placeholder="Code" />
            </div>
          </Form.Item>

          <Form.Item
            name="is_enable_mail_support"
            label="Enable mail support"
            rules={[
              {
                required: true,
                message: "Please enable or disable mail support",
              },
            ]}
          >
            <Select
              name="is_enable_mail_support"
              initialValues={statusOption[0]}
            >
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="is_enable_call_us"
            label="Enable call us"
            rules={[
              {
                required: true,
                message: "Please enable or disable call us",
              },
            ]}
          >
            <Select
              name="is_enable_mail_support"
              initialValues={statusOption[0]}
            >
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
          <br />
        </Form>
      </Card>
    </div>
  );
};

export default FaqLayerAdd;
