import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTablePagination,
} from "@store/ui";
import {
  findAllCommissionLogs,
  approveCommissionLogs,
  calculateAllTransactionCommission,
} from "@store/transaction";
import TableHeader from "@components/table-header";
import { isEmpty, map, set, size } from "lodash";
import numeral from "numeral";

const CommissionLog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "days")
  );

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    if (isEmpty(filter)) {
      setFilter({ selected_date: moment().subtract(1, "days") });
    }
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        findAllCommissionLogs({ filter })
      ).unwrap();
      const { data: commissionsData, total } = data;

      setTableData(
        map(commissionsData, (item, index) => {
          return {
            ...item,
            key: index,
          };
        })
      );
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "UserID",
      dataIndex: "id",
      key: "id",
      align: "left",
      // width: 500,
      render: (_, record) => {
        const { id } = record;

        return <div className={styles.table_column_title}>{id}</div>;
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      align: "left",
      // fixed: "left",
      // width: 2000,
      render: (_, record) => {
        const { username } = record;

        return <div className={styles.table_column_title}>{username}</div>;
      },
    },
    {
      title: "Amount",
      dataIndex: "all_currencies_amount",
      key: "all_currencies_amount",
      align: "left",
      // width: 500,
      render: (_, record) => {
        const { all_currencies_amount } = record;

        return map(
          all_currencies_amount &&
          size(all_currencies_amount) > 0 &&
          all_currencies_amount,
          (item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div>{item.currency_name}</div>
              <div>{item.total_amount}</div>
            </div>
          )
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Commission ID",
        dataIndex: "commission_id",
        key: "commission_id",
        align: "left",
        width: 50,
        render: (_, record) => {
          return <div>{record.commission_id}</div>;
        },
      },
      {
        title: "Currencies",
        dataIndex: "currencies",
        key: "currencies",
        align: "left",
        width: 100,
        render: (_, record) => {
          return (
            <div>
              {record.iso_code} - {record.currency_name}
            </div>
          );
        },
      },
      {
        title: "Downline",
        dataIndex: "downline",
        key: "downline",
        align: "left",
        width: 100,
        render: (_, record) => {
          return <div>{record.downline}</div>;
        },
      },
      {
        title: "Transaction Amount",
        dataIndex: "transaction_amount",
        key: "transaction_amount",
        align: "right",
        width: 100,
        render: (_, record) => {
          return (
            <div style={{ textAlign: "right" }}>
              {numeral(record.transaction_amount).format("0,0.00")}
            </div>
          );
        },
      },
      {
        title: "Commission Rate (%)",
        dataIndex: "earned_rate",
        key: "earned_rate",
        align: "right",
        width: 100,
        render: (_, record) => {
          return (
            <div style={{ textAlign: "right" }}>
              {numeral(record.earned_rate).format("0,0.00")}
            </div>
          );
        },
      },
      {
        title: "Commission Amount",
        dataIndex: "earned_amount",
        key: "earned_amount",
        align: "right",
        width: 100,
        render: (_, record) => {
          return (
            <div style={{ textAlign: "right" }}>
              {numeral(record.earned_amount).format("0,0.00")}
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (_, record) => {
          let status;
          let color;

          if (record.status === 3) {
            status = "Successful";
            color = "#95de64";
          } else if (record.status === 5) {
            status = "Unsuccessful";
            color = "#FF0000";
          } else if (record.status === 2) {
            status = "Processing";
            color = "#4f8bc2";
          } else {
            status = "Pending";
            color = "#4f8bc2";
          }

          return <div style={{ color, textAlign: "center" }}>{status}</div>;
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.commission}
        pagination={false}
      />
    );
  };

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }
  };

  const customOnClick = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        approveCommissionLogs({ selectedDate })
      ).unwrap();

      message.success(data.message);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const customOnClick2 = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        calculateAllTransactionCommission({ selectedDate })
      ).unwrap();

      message.success(data.message);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
      setFilter({ selected_date: selectedDate });
      fetchData();
    }
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Commissions"
        showCreateButton={false}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
        showResetButton={true}
        showFilterButton={false}
        // showCustomButton={
        //   !(
        //     (tableData.every((x) => x.commission.every((y) => y.status == 3)) &&
        //       tableData.length > 0) ||
        //     tableData.length === 0
        //   )
        // }
        // customButtonText={"Approve Commission"}
        // customOnClick={customOnClick}
        // showCustomButton2={
        //   !(
        //     tableData.every((x) => x.commission.every((y) => y.status == 3)) &&
        //     tableData.length > 0
        //   )
        // }
        // customButtonText2={"Generate Commission"}
        // customOnClick2={customOnClick2}

        customButtons={[
          {
            show: !(
              (tableData.every((x) => x.commission.every((y) => y.status == 3)) &&
                tableData.length > 0) ||
              tableData.length === 0
            ),
            text: 'Approve Commission',
            onClick: customOnClick,
            disabled: false
          },
          {
            show: !(
              tableData.every((x) => x.commission.every((y) => y.status == 3)) &&
              tableData.length > 0
            ),
            text: 'Generate Commission',
            onClick: customOnClick2,
            disabled: false
          }
        ]}
      >
        <Col span="6">
          <div className={styles.filter_date_label}>Transaction Date:</div>
          <Form.Item name="selected_date">
            <DatePicker
              style={{ width: "100%" }}
              name="selected_date"
              onChange={(value) => {
                setSelectedDate(value);
                setFilter({ selected_date: value });
              }}
              disabledDate={(date) => date > moment().subtract(1, "days")}
              className="date-range-picker"
              defaultValue={moment().subtract(1, "days")}
            />
          </Form.Item>
        </Col>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        rowkey={(record) => record.id}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
        expandable={{
          expandedRowRender,

          // defaultExpandedRowKeys: [0],
        }}
      // rowSelection={rowSelection}
      />
    </div>
  );
};

export default CommissionLog;
