import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Row,
  Input,
  Table,
  Button,
  Dropdown,
  Tooltip,
  Space,
  Menu,
  Modal,
  Select,
  Tag,
  Form,
  Col,
  message,
  DatePicker
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { debounce, reject, find, findIndex, map } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useAbility } from "@casl/react";
import numeral from "numeral";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
// import { assignCaseToUser, findAllDashboardUsers } from "@store/app";
import { findAllPendingKyc, assignSubmission } from "@store/user";
import AssignUserPopUp from "@components/assign-user-popup";
import TableHeader from "@components/table-header";
import { AbilityContext } from "@configs/can";
import { WebSocketContext } from "@configs";
import { findCurrencyFilterOptionsByType } from "@store/currency";
import { findCountryFilterOptionsByType } from "@store/country";
import { findAllCaseAssignee } from "@store/user";
import { capitalCase } from "change-case";

const { Option } = Select;
const { RangePicker } = DatePicker;

const MemberKyc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const webSocket = useContext(WebSocketContext);
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (webSocket) {
  //     webSocket.on("add-transfer", (arg) => {
  //       addData(arg);
  //     });

  //     webSocket.on("remove-transfer", (arg) => {
  //       removeData(arg);
  //     });

  //     webSocket.on("update-transfer", (arg) => {
  //       updateData(arg);
  //     });
  //   }

  //   return () => {
  //     if (webSocket) {
  //       webSocket.off("add-transfer");
  //       webSocket.off("remove-transfer");
  //       webSocket.off("update-transfer");
  //     }
  //   };
  // }, [webSocket]);

  const location = useLocation();

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    // fetchData();
    setFilter({});
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
    fetchDashboardUsers();
  }, []);

  const fetchDashboardUsers = async () => {
    try {
      const { data } = await dispatch(
        findAllCaseAssignee({ paginate: false })
      ).unwrap();
      const dashboardUser = map(data, (item) => {
        return {
          value: item.username,
          label: item.username
        };
      });
      setDashboardUsers(dashboardUser);
    } catch (error) {
      message.error(error.message);
    }
  };

  const kycTypeOptions = [
    { value: "nric", label: "NRIC" },
    { value: "passport", label: "Passport" }
  ];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // if (filter != null) {
      //   filter["localTimeOffset"] = localTimeOffset;
      // }
      const { data } = await dispatch(findAllPendingKyc({ filter })).unwrap();
      const { data: transferData, total } = data;

      let { data: countryFilterOptions } = await dispatch(
        findCountryFilterOptionsByType({ type: "transfers" })
      ).unwrap();

      countryFilterOptions = map(countryFilterOptions, (item) => {
        return {
          label: item.name,
          value: item.iso_code
        };
      });

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "transfers" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      setCountries(countryFilterOptions);
      setCurrencies(currencyFilterOptions);

      setTableData(transferData);
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const editOnClick = async (item) => {
    if (ability.can("update", "Kyc")) {
      if (item.status === 1) {
        navigate(`/member/${item.user_id}?mode=edit&kyc=true`);
        try {
          await dispatch(
            assignSubmission({
              id: item.id,
              action: "assign"
            })
          ).unwrap();
        } catch (error) {
          message.error(error.message);
        }
      } else {
        navigate(`/member/${item.user_id}?mode=edit&kyc=true`);
      }
    } else {
      navigate(`/member/${item.user_id}?mode=view&kyc=true`);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "left",
      width: 60
    },
    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Submission</div>
            <div>Date (GMT +7)</div>
          </span>
        );
      },
      dataIndex: "created_at",
      key: "created_at",
      width: 200,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;

        return (
          <>
            <div style={{ textAlign: "left" }}>
              {created_at != null
                ? moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
                : "-"}
            </div>
          </>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Member</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      align: "left",
      width: 110,
      dataIndex: "topkash_id",
      key: "topkash_id",
      render: (_, record) => {
        const { topkash_id } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {topkash_id ? topkash_id : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Member</div>
            <div>Username</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "username",
      key: "username",
      render: (_, record) => {
        const { username } = record;
        return (
          <div style={{ textAlign: "left" }}>{username ? username : "-"}</div>
        );
      }
    },

    {
      title: "ID Type",
      dataIndex: "id_type",
      align: "left",
      key: "id_type",
      width: 120,
      render: (_, record) => {
        if (record.id_type) {
          return (
            <div>
              {record?.id_type === "nric"
                ? record?.id_type.toUpperCase()
                : capitalCase(record?.id_type)}
            </div>
          );
        }
        else {
          return (
            <div>
              -
            </div>
          );
        }
      }
    },

    {
      title: "Assigned To",
      align: "left",
      width: 120,
      dataIndex: "assigned_user_username",
      key: "assigned_user_username",
      render: (_, record) => {
        const { assigned_user_username } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {assigned_user_username ? assigned_user_username : "-"}
          </div>
        );
      }
    },

    {
      title: "Status",
      align: "center",
      fixed: "right",
      width: 90,
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status;
        let color;
        let fontWeight;

        if (record.status === 3) {
          status = "Successful";
          color = "#339933";
        } else if (record.status === 5) {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.status === 2) {
          status = "Processing";
          color = "#4f8bc2";
        } else {
          status = "Pending";
          color = "#ff8000";
          fontWeight = 700;
        }

        return <div style={{ color, fontWeight }}>{status}</div>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 90,
      fixed: "right",
      render: (_, record) => {
        if (ability.can("update", "Kyc")) {
          return (
            <div>
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => editOnClick(record)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Space>
            </div>
          );
        } else {
          return (
            <div>
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => editOnClick(record)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Space>
            </div>
          );
        }
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let key = sorter.columnKey;
      let field = sorter.field;

      if (key === "country") {
        field = "transfer_currency_iso_code";
      } else if (key === "amount") {
        field = "amount";
      } else if (key === "bank") {
        field = "recipient_bank_name";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Pending ID Verifications"
        showKycAdditionalNote={true}
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter(null);
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            {/* <Col span="24"> */}
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Member Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"username"} initialValue={null}>
                  <Input
                    placeholder="Member Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"id_type"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="ID Type"
                    options={kycTypeOptions}
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"assigned_to"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Assigned To"
                    options={
                      dashboardUsers && dashboardUsers.length > 0
                        ? dashboardUsers
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* </Col> */}
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default MemberKyc;
