import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";
import {
  Card,
  Form,
  InputNumber,
  Row,
  Select,
  DatePicker,
  Button,
  message,
  Space,
} from "antd";
import moment from "moment";
import { map } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// ============
import styles from "./styles.module.less";
import {
  retrieveExchangeRateById,
  updateExchangeRateById,
} from "@store/exchange";

const { Option } = Select;

const ExchangeRateEdit = () => {
  const { params } = useMatch("exchange-rate/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [inputDisable, setInputDisable] = useState(true);
  const [currentRate, setCurrentRate] = useState("");

  const currencies = useSelector((state) => state.currency.currencies);

  useEffect(() => {
    const fetchExchangeRateById = async () => {
      try {
        const { data } = await dispatch(
          retrieveExchangeRateById({ id: params.id })
        ).unwrap();

        if (data) {
          // const { data } = res.payload;

          formRef?.current?.setFieldsValue({
            from_currency: data.from_currency.id,
            to_currency: data.to_currency.id,
            rate: data.rate,
            date: moment(data.date),
          });
          setCurrentRate(data.rate);
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchExchangeRateById();
  }, []);

  const onFinish = async (values) => {
    try {
      const { data } = await dispatch(
        updateExchangeRateById({ id: params.id, values })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  const exchangeRateChange = (event) => {
    if (currentRate == event) {
      setInputDisable(true);
    } else {
      setInputDisable(false);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>EXCHANGE RATE INFORMATION</div>
        </Row>

        <Form
          layout="vertical"
          ref={formRef}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            name="from_currency"
            label="From Currency"
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Please enter source currency",
              },
            ]}
          >
            <Select
              disabled={true}
              className={styles.select_container}
              placeholder="Please select a currency"
              bordered={false}
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.currency_iso}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="to_currency"
            label="To Currency"
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Please enter target currency",
              },
            ]}
          >
            <Select
              disabled={true}
              className={styles.select_container}
              placeholder="Please select a currency"
              bordered={false}
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.currency_iso}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="rate"
            label="Exchange Rate"
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Please enter exchange rate",
              },
              {
                type: "number",
                message: "Rate must be a floating point number",
              },
            ]}
          >
            <InputNumber
              className={styles.input}
              min="0"
              placeholder="Enter exchange rate"
              onChange={exchangeRateChange}
            />
          </Form.Item>

          <Form.Item
            name="date"
            label="Effective Date"
            rules={[
              {
                required: true,
                message: "Please choose effective date",
              },
            ]}
          >
            <DatePicker
              disabled
              disabledDate={(date) => moment().add(-1, "days") >= date}
              className={styles.date_picker}
            />
          </Form.Item>

          <Row justify="end">
            <Space>
              <div>
                <Button onClick={() => navigate(-1)} className="default_btn">
                  Cancel
                </Button>
              </div>
              <div className={styles.button}>
                <Button
                  htmlType="submit"
                  className="secondary_btn"
                  disabled={inputDisable}
                >
                  Save
                </Button>
              </div>
            </Space>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ExchangeRateEdit;
