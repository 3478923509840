import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useMatch,
  useNavigate,
  useLocation,
  useSearchParams,
  Link
} from "react-router-dom";
import { map, find, values, includes, filter, isEmpty } from "lodash";
import moment from "moment-timezone";
import {
  Card,
  Form,
  Input,
  Row,
  Select,
  Button,
  message,
  Space,
  Divider,
  Table,
  DatePicker,
  Tabs,
  TabsProps,
  Image,
  Col,
  Tag,
  Empty,
  Spin
} from "antd";

import TableHeader from "@components/table-header";
import FullPageSpinner from "@components/full-page-spinner";

import styles from "./styles.module.less";
import {
  findCountryStatesMaritalStatus,
  findActivityLogsByRefIdAndRefTable,
  assignCaseToUser
} from "@store/app";
import HistoryTable from "../../../components/history-table";
import {
  findCustomerById,
  updateCustomerById,
  resetPinAttemptsByUserId,
  updateCustomerStatusById,
  findCustomerWalletsById,
  findUserDevice,
  findUserKycById,
  updateKycById
} from "@store/user";
import { findLastestUserTransactionsByUserId } from "@store/transaction";
import numeral from "numeral";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@configs/can";
import { updateCustomerCommissionRateById } from "../../../store/app";
import { unformat, formatNumber } from "accounting-js";
import ConfirmationPopUp from "@components/confirmation-popup";
import { LoadingOutlined } from "@ant-design/icons";
import { capitalCase } from "change-case";

const { Option } = Select;

const CustomerEdit = () => {
  const { params } = useMatch("member/:id");
  const dispatch = useDispatch();
  const formRef = useRef();
  const kycRef = useRef();
  const { TextArea } = Input;

  const referralFormRef = useRef();
  const [form] = Form.useForm();
  const [referralForm] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ability = useAbility(AbilityContext);
  const location = useLocation();

  const [mode, setMode] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [historyData, setHistoryData] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [deviceData, setDeviceData] = useState({});
  const [kycData, setKycData] = useState({});
  const [fileUrl, setFileUrl] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [activeKey, setActiveKey] = useState("1");

  const [isLoading, setIsLoading] = useState(false);

  const [allowEditing, setAllowEditing] = useState(false);

  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const userId = useSelector((state) => state.app.user.id);

  const [transactionData, setTransactionData] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedReasonIndex, setSelectedReasonIndex] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: "#ffffff"
      }}
      spin
    />
  );

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  const reason = [
    {
      index: 1,
      value:
        "picture of document blur / not clear / unintelligible / incorrect picture",
      label:
        "picture of document blur / not clear / unintelligible / incorrect picture"
    },
    {
      index: 2,
      value: "error in the name / name does not match document / typo",
      label: "error in the name / name does not match document / typo"
    },
    {
      index: 3,
      value: "error in the document number / does not match document / typo",
      label: "error in the document number / does not match document / typo"
    },

    {
      index: 4,
      value: "document expired / invalid",
      label: "document expired / invalid"
    },
    {
      index: 5,
      value: "nationality entered does not match document submitted",
      label: "nationality entered does not match document submitted"
    },

    { index: 6, value: "others", label: "others" }
  ];

  const idTypeOption = [
    { value: "identity_card", label: "Identity Card" },
    { value: "passport", label: "Passport" }
  ];

  const genderOption = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
  ];

  const renderStatus = (status) => {
    if (status === 1) {
      return "Pending";
    } else if (status === 2) {
      return "Processing";
    } else if (status === 3) {
      return "Successful";
    } else if (status === 5) {
      return "Unsuccessful";
    } else if (status === 8) {
      return "Pending Member's Action";
    } else {
      return "N/A";
    }
  };

  const renderStatusColor = (status) => {
    if (status === 3) {
      return { color: "#339933" };
    } else if (status === 5) {
      return { color: "#ff0000" };
    } else {
      return { color: "#4f8bc2" };
    }
  };
  const onCancelModal = () => {
    form.resetFields();
    setShowRejectModal(false);
  };

  useEffect(() => {
    let currentMode = searchParams.get("mode");
    let kycStatus = searchParams.get("kyc");
    let wallet = searchParams.get("wallet");

    if (!includes(["edit", "view"], currentMode)) {
      navigate("/error");
    }

    setMode(currentMode);

    if (currentMode && kycStatus) {
      setActiveKey("5");
    } else if (currentMode && wallet) {
      setActiveKey("2");
    } else {
      setActiveKey("1");
    }

    if (ability.cannot("update", "Transfer") && currentMode === "edit") {
      const { pathname, search } = location;

      navigate(`${pathname}${search.replace(/edit/gi, "view")}`, {
        replace: true
      });
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data: initializeData } = await dispatch(
          findCountryStatesMaritalStatus()
        ).unwrap();

        setCountries(initializeData.country);

        setMaritalStatus(initializeData.marital_status);

        const userPayload = await dispatch(
          findCustomerById({
            id: params.id,
            mode: currentMode
          })
        ).unwrap();

        const { data: userData } = userPayload;

        if (userData) {
          formRef.current.setFieldsValue({
            username: userData.username,
            fullname: userData.fullname,
            email: userData.email,
            phone_no: userData.phone_no,
            id_type: userData.id_type,
            id_number: userData.id_number,
            dob: userData?.dob ? moment(userData.dob) : null,
            age: userData?.dob
              ? moment().diff(moment(userData.dob), "years")
              : "-",
            gender: userData.gender,
            marital_status_id: userData.marital_status_id,
            address_line_1: userData?.address?.address_1,
            address_line_2: userData?.address?.address_2,
            country: userData?.address?.country_id,
            state: userData?.address?.state_id,
            postcode: userData?.address?.postcode,
            city: userData?.address?.city,
            status:
              userData.status === 1
                ? statusOption[0].value
                : statusOption[1].value,
            created_at: moment(userData.created_at).format(
              "DD-MM-YYYY, hh:mm:ss A"
            )
          });

          setUserDetail(userData);

          let nationalityById = find(initializeData.country, {
            id: userData.nationality_id
          });

          let countryById = find(initializeData.country, {
            id: userData.address?.country_id
          });

          setNationality(userData.nationality_id ? nationalityById.name : null);
          setStates(countryById?.state);

          // get member wallet info
          const walletPayload = await dispatch(
            findCustomerWalletsById({
              id: params.id
            })
          ).unwrap();

          const { data: walletData } = walletPayload;

          setTableData(walletData.data);

          // get member device info
          const devicePayload = await dispatch(
            findUserDevice({
              id: params.id
            })
          ).unwrap();

          const { data: userDeviceData } = devicePayload;

          if (userDeviceData) {
            const attributesToTrim = [
              "sys_build_id",
              "device_name",
              "mac_address",
              "manufacturer",
              "unique_id",
              "user_agent"
            ];

            const finalDeviceData = { ...userDeviceData };

            attributesToTrim.forEach((attribute) => {
              finalDeviceData[attribute] =
                userDeviceData[attribute]?.replace(/\"+/g, "") || "";
            });

            setDeviceData(finalDeviceData);
          }

          // get latest transactions
          const transactionPayload = await dispatch(
            findLastestUserTransactionsByUserId({
              id: params.id
            })
          ).unwrap();

          const { data: transactionData } = transactionPayload;

          setTransactionData(transactionData);

          let historyData = [];

          const { data: userHistoryData } = await dispatch(
            findActivityLogsByRefIdAndRefTable({
              reference_id: params.id,
              reference_table: "users"
            })
          ).unwrap();

          const { data: addressHistoryData } = await dispatch(
            findActivityLogsByRefIdAndRefTable({
              reference_id: userData.address_id,
              reference_table: "addresses"
            })
          ).unwrap();

          // get member kyc info
          const kycPayload = await dispatch(
            findUserKycById({
              id: params.id
            })
          ).unwrap();

          const { data: kycData } = kycPayload;

          if (kycData) {
            setKycData(kycData);

            if (kycData.assigned_user_id) {
              let assignedUserId = kycData.assigned_user_id;

              if (assignedUserId == userId) {
                setAllowEditing(true);
              }
            }
          }

          setKycData(kycData);

          // sort by id desc (get latest first)
          historyData = userHistoryData
            .concat(addressHistoryData)
            .sort((x, y) => {
              return y.id - x.id;
            });

          const filteredHistoryData = filter(
            historyData,
            (item) => item.action_type !== "View"
          );

          setHistoryData(filteredHistoryData);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const statusOnClick = async (status) => {
    try {
      if (ability.can("update", "Kyc")) {
        let finalReason = selectedReason;

        if (selectedReason === "others") {
          const customReason = form.getFieldValue("custom_reason");

          finalReason = customReason;
        }

        if (status === 5) {
          setShowRejectModal(true);
        } else if (status === 3) {
          await dispatch(
            updateKycById({
              id: params.id,
              status,
              reason: finalReason
            })
          ).unwrap();
          message.success(
            "You have successfully approved member ID verification"
          );
          navigate(-1);
        } else if (status === 8) {
          await dispatch(
            updateKycById({
              id: params.id,
              status,
              reason: finalReason
            })
          ).unwrap();
          message.success("You have enabled member to update KYC");
          navigate(-1);
        }
      } else {
        throw { message: "Invalid Permission" };
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const resetPinAttemptsOnClick = async () => {
    values = {
      id: params.id
    };

    try {
      if (
        ability.can("update", "Member") ||
        ability.can("update", "MemberStatus")
      ) {
        let { data } = await dispatch(
          resetPinAttemptsByUserId({ values })
        ).unwrap();

        message.success(data.message);

        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        throw { message: "Invalid Permission" };
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleModalOk = () => {
    formRef.current.validateFields().then(async (values) => {
      const finalReason =
        values.reason === "others" ? values.custom_reason : values.reason;

      await dispatch(
        updateKycById({
          id: params.id,
          status: 5,
          reason: finalReason
        })
      ).unwrap();
      message.success("You have successfully rejected ID verification");
      navigate(-1);

      setShowRejectModal(false);
    });
  };

  const isButtonDisabled = (status) => {
    return (
      (status === 2 && !allowEditing) ||
      status === 3 ||
      status === 5 ||
      status === 8 ||
      mode === "view"
    );
  };

  useEffect(() => {
    setMode(searchParams.get("mode"));
  }, [searchParams]);

  const columns = [
    {
      title: "Currency Code",
      dataIndex: "iso_code",
      align: "left",
      fixed: "left",
      width: 80
    },
    {
      title: "Currency Name",
      dataIndex: "currency_name",
      align: "left",
      width: 140
    },
    {
      title: "Credit Balance",
      dataIndex: "amount",
      align: "right",
      width: 100,
      render: (_, record) => {
        return (
          <div style={{ textAlign: "right" }}>
            {numeral(parseFloat(record.amount)).format("0,0.00")}
          </div>
        );
      }
    }
  ];

  const transactionColumns = [
    {
      title: "ID",
      dataIndex: "doc_id",
      align: "left",
      key: "doc_id",
      fixed: "left",
      width: 120
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Submission</div>
            <div>Date</div>
          </span>
        );
      },
      dataIndex: "submission_date",
      width: 200,
      // sorter: true,
      key: "submission_date",

      render: (_, record) => {
        const { created_at } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {created_at != null
              ? moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} right`}>
            <div>Sender</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      dataIndex: "topkash_id",
      align: "left",
      width: 100,
      key: "topkash_id",
      render: (_, record) => {
        const { customer_topkash_id } = record;
        return <div>{customer_topkash_id ? customer_topkash_id : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_right`}>
            <div>Sender</div>
            <div>Username</div>
          </span>
        );
      },
      dataIndex: "username",
      align: "left",
      width: 100,
      key: "username",
      render: (_, record) => {
        const { customer_username } = record;
        return <div>{customer_username ? customer_username : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>From</div>
            <div>Currency</div>
          </span>
        );
      },
      dataIndex: "from_currency_code",
      align: "right",
      key: "from_currency_code",
      width: 100,
      render: (_, record) => {
        const { from_currency_code } = record;
        return <div style={{ textAlign: "right" }}>{from_currency_code}</div>;
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>From</div>
            <div>Amount</div>
          </span>
        );
      },
      dataIndex: "amount",
      align: "right",
      key: "amount",
      width: 100,
      render: (_, record) => {
        const { amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {numeral(Math.abs(parseFloat(amount))).format("0,0.00")}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Fees</div>
          </span>
        );
      },
      dataIndex: "processing_fee",
      align: "right",
      key: "processing_fee",
      width: 100,
      render: (_, record) => {
        const { processing_fee } = record;

        let fee = processing_fee;
        if (parseFloat(processing_fee) === 0.0) {
          fee = "-";
        } else if (processing_fee) {
          fee = processing_fee;
        } else {
          fee = "-";
        }

        return (
          <>
            <div style={{ textAlign: "right" }}>
              {parseFloat(fee) > 0
                ? numeral(parseFloat(fee)).format("0,0.00")
                : "-"}
            </div>
          </>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Amount </div>
            <div>To Convert</div>
          </span>
        );
      },
      dataIndex: "amount",
      align: "right",
      key: "amount",
      width: 100,
      render: (_, record) => {
        const { amount_to_convert } = record;

        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(amount_to_convert) > 0
              ? numeral(parseFloat(amount_to_convert)).format("0,0.00")
              : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Exchange </div>
            <div>Rate</div>
          </span>
        );
      },
      width: 150,
      dataIndex: "exchange_rate",
      align: "right",
      key: "exchange_rate",
      render: (_, record) => {
        const { exchange_rate, from_currency_code, to_currency_code } = record;

        let rate = exchange_rate;
        if (from_currency_code === to_currency_code) {
          rate = "1.0000";
        }

        return <div style={{ textAlign: "right" }}>{rate ? rate : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>To</div>
            <div>Currency</div>
          </span>
        );
      },
      dataIndex: "currency",
      align: "right",
      key: "currency",
      width: 100,
      render: (_, record) => {
        const { to_currency_code } = record;
        return <div style={{ textAlign: "right" }}>{to_currency_code}</div>;
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>To</div>
            <div>Amount</div>
          </span>
        );
      },
      dataIndex: "to_amount",
      align: "right",
      key: "to_amount",
      width: 100,
      render: (_, record) => {
        const { to_amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {numeral(parseFloat(to_amount)).format("0,0.00")}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Country</div>
          </span>
        );
      },
      dataIndex: "recipient_country_name",
      align: "left",
      key: "recipient_country_name",
      width: 120,
      render: (_, record) => {
        const {
          transaction_type,
          transfer_bank_country_name,
          reload_bank_country_name
        } = record;

        let country_name = null;
        if (transaction_type === "SEND") {
          country_name = transfer_bank_country_name;
        }

        return <div>{country_name ? country_name : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank</div>
          </span>
        );
      },
      dataIndex: "recipient_bank",
      align: "left",
      key: "recipient_bank",
      width: 120,
      render: (_, record) => {
        const { transaction_type, transfer_bank } = record;
        let recipient_bank = null;

        if (transaction_type === "SEND") {
          recipient_bank = transfer_bank;
        }

        return <div>{recipient_bank ? recipient_bank : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Acc No</div>
          </span>
        );
      },
      dataIndex: "recipient_bank_acc_no",
      align: "left",
      key: "recipient_bank_acc_no",
      width: 120,
      render: (_, record) => {
        const { transaction_type, user_bank_account_no } = record;
        let recipient_bank_acc_no = null;

        if (transaction_type === "SEND") {
          recipient_bank_acc_no = user_bank_account_no;
        }

        return <div>{recipient_bank_acc_no ? recipient_bank_acc_no : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Acc Name</div>
          </span>
        );
      },
      dataIndex: "recipient_bank_acc_name",
      align: "left",
      key: "recipient_bank_acc_name",
      width: 120,
      render: (_, record) => {
        const { transaction_type, user_bank_account_name } = record;
        let recipient_bank_acc_name = null;

        if (transaction_type === "SEND") {
          recipient_bank_acc_name = user_bank_account_name;
        }

        return (
          <div>{recipient_bank_acc_name ? recipient_bank_acc_name : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Receipt</div>
            <div>Reference</div>
          </span>
        );
      },
      dataIndex: "receipt_reference_no",
      align: "left",
      width: 120,
      render: (_, record) => {
        const { receipt_reference_no } = record;

        return <div>{receipt_reference_no ? receipt_reference_no : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Source</div>
            <div>Bank</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "source_bank_name",
      render: (_, record) => {
        const { source_bank_name } = record;

        return <div>{source_bank_name ? source_bank_name : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Source</div>
            <div>Bank Acc No</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "source_bank_account_no",
      render: (_, record) => {
        const { source_bank_account_no } = record;

        return (
          <div>{source_bank_account_no ? source_bank_account_no : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Source</div>
            <div>Bank Acc Name</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "source_bank_account_name",
      render: (_, record) => {
        const { source_bank_account_name } = record;

        return (
          <div>{source_bank_account_name ? source_bank_account_name : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      align: "left",
      dataIndex: "reference_topkash_id",
      width: 120,
      render: (_, record) => {
        const {
          reference_topkash_id,
          customer_topkash_id,
          user_bank_account_type,
          amount
        } = record;

        let referenceTopkashId = reference_topkash_id;

        if (reference_topkash_id === customer_topkash_id) {
          referenceTopkashId = "-";
        }

        if (user_bank_account_type === "TOPKASH" && parseFloat(amount) > 0) {
          referenceTopkashId = "-";
        }

        return <div>{referenceTopkashId}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Username</div>
          </span>
        );
      },

      align: "left",
      dataIndex: "reference_username",
      width: 120,
      render: (_, record) => {
        const {
          reference_username,
          customer_username,
          user_bank_account_type,
          amount
        } = record;

        let referenceUsername = reference_username;

        if (reference_username === customer_username) {
          referenceUsername = "-";
        }

        if (user_bank_account_type === "TOPKASH" && parseFloat(amount) > 0) {
          referenceUsername = "-";
        }

        return <div>{referenceUsername}</div>;
      }
    },

    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      align: "left",
      // width: "10%",
      width: 100,
      key: "assigned_to",
      render: (_, record) => {
        const { assigned_user_username } = record;
        return (
          <div>{assigned_user_username ? assigned_user_username : "-"}</div>
        );
      }
    },
    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Proccessed</div>
            <div>Date</div>
          </span>
        );
      },
      dataIndex: "processed_date",
      width: 200,
      // sorter: true,
      key: "processed_date",
      render: (_, record) => {
        const { processed_date } = record;
        return (
          <>
            <div style={{ textAlign: "left" }}>
              {processed_date
                ? moment
                    .utc(processed_date)
                    .tz("Asia/Phnom_Penh")
                    .format("DD-MM-YYYY, hh:mm:ss A")
                : "-"}
            </div>
          </>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      key: "status",
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;

        if (record.status === "3") {
          status = "Successful";
          color = "#339933";
        } else if (record.status === "5") {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.status === "2") {
          status = "Processing";
          color = "#4f8bc2";
        } else if (record.status === "1") {
          status = "Pending";
          color = "#ff8000";
        }

        return <div style={{ color }}>{status}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Action</div>
          </span>
        );
      },
      dataIndex: "action",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        if (
          record.transaction_type == "SEND" ||
          record.transaction_type == "CONVERT"
        ) {
          if (ability.can("update", "Transfer")) {
            const { status } = record;

            let ctaText;

            if (status == 3 || status == 5) {
              ctaText = "View";
            } else {
              ctaText = "Edit";
            }

            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    {ctaText}
                  </Button>
                </Space>
              </div>
            );
          } else {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        } else if (record.transaction_type == "RELOAD") {
          if (ability.can("update", "Reload")) {
            const { status } = record;

            let ctaText;

            if (status == 3 || status == 5) {
              ctaText = "View";
            } else {
              ctaText = "Edit";
            }

            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    {ctaText}
                  </Button>
                </Space>
              </div>
            );
          } else {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        } else if (record.transaction_type == "REDEEM") {
          if (ability.can("update", "Redeem")) {
            const { status } = record;

            let ctaText;

            if (status == 3 || status == 5) {
              ctaText = "View";
            } else {
              ctaText = "Edit";
            }

            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    {ctaText}
                  </Button>
                </Space>
              </div>
            );
          } else {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        }
      }
    }
  ];

  const actionBtnOnClick = async (item) => {
    const { status, reference_id, transaction_type, user_bank_account_type } =
      item;

    if (status === "1") {
      if (transaction_type === "SEND") {
        if (ability.can("update", "Transfer")) {
          window.open(
            `/send/bank/${reference_id}?mode=edit`,
            "_blank",
            "noreferrer"
          );
          await dispatch(
            assignCaseToUser({
              ref_id: reference_id,
              type: "transfers",
              action: "assign"
            })
          ).unwrap();
        } else {
          window.open(
            `/send/bank/${reference_id}?mode=view`,
            "_blank",
            "noreferrer"
          );
        }
      } else if (transaction_type === "RELOAD") {
        if (ability.can("update", "Reload")) {
          window.open(
            `/top-up/bank/${reference_id}?mode=edit`,
            "_blank",
            "noreferrer"
          );
          await dispatch(
            assignCaseToUser({
              ref_id: reference_id,
              type: "reloads",
              action: "assign"
            })
          ).unwrap();
        } else {
          window.open(
            `/top-up/bank/${reference_id}?mode=view`,
            "_blank",
            "noreferrer"
          );
        }
      } else {
        return;
      }
    } else if (status === "2") {
      if (transaction_type === "SEND") {
        if (ability.can("update", "Transfer")) {
          window.open(
            `/send/bank/${reference_id}?mode=edit`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.open(
            `/send/bank/${reference_id}?mode=view`,
            "_blank",
            "noreferrer"
          );
        }
      } else if (transaction_type === "RELOAD") {
        if (ability.can("update", "Reload")) {
          window.open(
            `/top-up/bank/${reference_id}?mode=edit`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.open(
            `/top-up/bank/${reference_id}?mode=view`,
            "_blank",
            "noreferrer"
          );
        }
      } else {
        return;
      }
    } else {
      if (transaction_type === "SEND") {
        if (user_bank_account_type === "TOPKASH") {
          window.open(
            `/send/member/${reference_id}?mode=view`,
            "_blank",
            "noreferrer"
          );
        } else {
          window.open(
            `/send/bank/${reference_id}?mode=view`,
            "_blank",
            "noreferrer"
          );
        }
      } else if (transaction_type === "RELOAD") {
        window.open(
          `/top-up/bank/${reference_id}?mode=view`,
          "_blank",
          "noreferrer"
        );
      } else if (transaction_type === "CONVERT") {
        window.open(`/conversion/${reference_id}`, "_blank", "noreferrer");
      } else if (transaction_type === "REDEEM") {
        window.open(
          `/top-up/gift-card/${reference_id}`,
          "_blank",
          "noreferrer"
        );
      } else {
        return;
      }
    }
  };

  const phoneNumberOnChange = async ({ target }) => {
    const { value } = target;

    let text = value;

    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/gi, "");
    formRef.current.setFieldValue("phone_no", formattedPhoneNumber);
  };

  const updateCustomer = async (values) => {
    values = {
      ...values,
      dob: values.dob ? moment(values.dob).format("YYYY-MM-DD") : null,
      state_id: values.state,
      country_id: values.country,
      // reference_id: userDetail.address ? userDetail.address.reference_id : userDetail.id,
      status: parseInt(values.status)
    };

    try {
      if (ability.can("update", "Member")) {
        let { data } = await dispatch(
          updateCustomerById({ id: params.id, values })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      } else if (ability.can("update", "MemberStatus")) {
        values = {
          status: parseInt(values.status)
        };
        let { data } = await dispatch(
          updateCustomerStatusById({ id: params.id, values })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      } else {
        throw { message: "Invalid Permission" };
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const updateCustomerCommissionRate = async (values) => {
    try {
      // Will implement user right for commission part on future
      // if (ability.can("update", "MemberCommissionRate")) {
      let { data } = await dispatch(
        updateCustomerCommissionRateById({ userId: params.id, values })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
      // } else {
      //   throw { message: "Invalid Permission" };
      // }
    } catch (error) {
      message.error(error.message);
    }
  };

  const commissionRateOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const commission_rate = unformat(text.replace(/(\.|\,)/g, "")) / 100;

    const formatted_rate = formatNumber(commission_rate, { precision: 2 });

    referralFormRef.current.setFieldsValue({
      commission_rate: formatted_rate
    });
  });

  const tabOnChange = (key) => {
    if (key === "4") {
      if (referralFormRef) {
        setTimeout(() => {
          referralFormRef.current.setFieldsValue({
            commission_rate: userDetail.commission_rate
              ? formatNumber(userDetail.commission_rate, {
                  precision: 2
                })
              : 0
          });
        }, 1);
      }
    }

    setActiveKey(key);
  };

  const onChangeCountries = (value) => {
    formRef.current.setFieldsValue({
      state: null
    });

    let selectedCountry = find(countries, { id: value });

    if (selectedCountry) {
      setStates(selectedCountry.state);
    }
  };

  const onChangeDOB = (date) => {
    let age = moment().diff(moment(date, "dd-mm-yyyy"), "years");

    if (!isNaN(age)) {
      formRef.current.setFieldsValue({
        age
      });
    } else {
      formRef.current.setFieldsValue({
        age: "-"
      });
    }
  };

  const renderIdType = (idType) => {
    let type = find(idTypeOption, (item) => item.value === idType);

    return type ?? "-";
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        {isLoading ? (
          <Col
            style={{
              position: "absolute",
              textAlign: "center",
              zIndex: 1100,
              height: "100%",
              width: "98%",
              backgroundColor: "rgb(255 255 255 / 70%)",
              lineHeight: "100vh"
            }}
          >
            <Spin />
          </Col>
        ) : null}
        <Row>
          <div className="page-title-with-tabs">
            {mode === "edit" ? "Edit Member" : "View Member"}
          </div>
        </Row>

        <Tabs activeKey={activeKey} onChange={(key) => tabOnChange(key)}>
          <Tabs.TabPane tab="Member Info" key="1">
            <Form
              colon={false}
              form={form}
              ref={formRef}
              labelCol={{
                span: 4
              }}
              wrapperCol={{
                span: 20
              }}
              initialValues={{
                remember: true
              }}
              onFinish={(values) => {
                updateCustomer(values);
              }}
              className="form-details"
            >
              <Form.Item name="status" label="Status">
                <Select
                  name="status"
                  options={statusOption}
                  value={"Active"}
                ></Select>
              </Form.Item>

              <Form.Item
                name="topkash_id"
                label="Topkash ID"
                className="label-details"
              >
                <Row style={{ flexDirection: "row" }}>
                  <Col>{userDetail.topkash_id}</Col>
                  {userDetail.is_testing_user ? (
                    <Tag color="green" style={{ marginLeft: 10 }}>
                      TESTING
                    </Tag>
                  ) : null}
                </Row>
              </Form.Item>

              {
                <Form.Item
                  name="change_status"
                  label="Unlock Account"
                  className="label-details"
                >
                  <Button
                    disabled={
                      userDetail?.block_time &&
                      userDetail?.pin_attempts >= 3 &&
                      userDetail?.temp_block_pin === 1
                        ? false
                        : true
                    }
                    onClick={() => resetPinAttemptsOnClick()}
                    className="ant-btn-green"
                  >
                    Reset PIN Attempts
                  </Button>
                </Form.Item>
              }

              <Form.Item
                name="username"
                label="Username"
                className="label-details"
              >
                <div>{userDetail.username}</div>
              </Form.Item>

              <Form.Item name="email" label="Email" className="label-details">
                <div>{userDetail.email}</div>
              </Form.Item>

              <Form.Item
                name="phone_no"
                label="Mobile"
                className="label-details"
              >
                <div>{userDetail.phone_no}</div>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item name="fullname" label="Full Name">
                {mode === "view" ? (
                  <div>{userDetail.fullname ?? "-"}</div>
                ) : (
                  <Input placeholder="Full Name" />
                )}
              </Form.Item>

              <Form.Item name="type" label="ID Type" className="label-details">
                <div>
                  {kycData.id_type
                    ? kycData.id_type === "nric"
                      ? "NRIC"
                      : "Passport"
                    : "-"}
                </div>
              </Form.Item>

              <Form.Item
                name="id_number"
                label="ID Number"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("id_type") === "passport") {
                        const regex = /^(?!^0+$)[a-zA-Z0-9]{6,}$/gi;

                        if (value && !regex.test(value)) {
                          return Promise.reject(
                            new Error("Please enter a valid ID Number")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.resolve();
                      }
                    }
                  })
                ]}
              >
                {mode === "view" ? (
                  <div>{userDetail.id_number ?? "-"}</div>
                ) : (
                  <Input placeholder="ID Number" />
                )}
              </Form.Item>

              <Form.Item
                name="dob"
                label="Date of Birth"
                className={styles.date_container}
              >
                {mode === "view" ? (
                  <div>{userDetail?.dob ?? "-"}</div>
                ) : (
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Date of Birth"
                    onChange={onChangeDOB}
                    disabledDate={(date) => moment().add(-1, "days") <= date}
                    disabled={mode === "view"}
                  />
                )}
              </Form.Item>

              <Form.Item name="age" label="Age">
                {mode === "view" ? (
                  <div>
                    {userDetail.dob
                      ? moment().diff(moment(userDetail.dob), "years")
                      : "-"}
                  </div>
                ) : (
                  <Input disabled />
                )}
              </Form.Item>

              <Form.Item name="gender" label="Gender">
                {mode === "view" ? (
                  <div className="label-details">
                    {userDetail.gender === "male"
                      ? genderOption[0].label
                      : userDetail.gender === "female"
                      ? genderOption[1].label
                      : "-"}
                  </div>
                ) : (
                  <Select
                    name="gender"
                    placeholder="Gender"
                    options={genderOption}
                  ></Select>
                )}
              </Form.Item>

              <Form.Item name="marital_status_id" label="Marital Status">
                {mode === "view" ? (
                  <div className="label-details">
                    {maritalStatus.filter(
                      (x) => x.id === userDetail.marital_status_id
                    ).length != 0
                      ? maritalStatus.filter(
                          (x) => x.id === userDetail.marital_status_id
                        )[0].name
                      : "-"}
                  </div>
                ) : (
                  <Select
                    name="marital_status_id"
                    placeholder="Marital Status"
                    disabled={mode === "view"}
                  >
                    {map(maritalStatus, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                name="nationality"
                label="Nationality"
                className="label-details"
              >
                <div>{nationality}</div>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                name="address_line_1"
                label="Address Line 1"
                className="input"
              >
                {mode === "view" ? (
                  <div>{userDetail?.address?.address_1 ?? "-"}</div>
                ) : (
                  <Input
                    placeholder="Address Line 1"
                    // disabled={mode === "view"}
                  />
                )}
              </Form.Item>

              <Form.Item
                name="address_line_2"
                label="Address Line 2"
                className="input"
              >
                {mode === "view" ? (
                  <div>{userDetail?.address?.address_2 ?? "-"}</div>
                ) : (
                  <Input
                    placeholder="Address Line 2"
                    // disabled={mode === "view"}
                  />
                )}
              </Form.Item>

              <Form.Item name="country" label="Country">
                {mode === "view" ? (
                  <div className="label-details">
                    {countries.filter(
                      (x) => x.id === userDetail?.address?.country_id
                    ).length != 0
                      ? countries.filter(
                          (x) => x.id === userDetail?.address?.country_id
                        )[0].name
                      : "-"}
                  </div>
                ) : (
                  <Select
                    name="country"
                    placeholder="Country"
                    onChange={onChangeCountries}
                    showSearch
                    optionFilterProp="children"
                    disabled={mode === "view"}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {map(countries, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item name="state" label="State">
                {mode === "view" ? (
                  <div className="label-details">
                    {states != undefined &&
                    states.filter((x) => x.id === userDetail?.address?.state_id)
                      .length != 0
                      ? states.filter(
                          (x) => x.id === userDetail?.address?.state_id
                        )[0].name
                      : "-"}
                  </div>
                ) : (
                  <Select
                    name="state"
                    placeholder="State"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={mode === "view" || !form.getFieldValue("country")}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {map(states, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item name="city" label="City" className="input">
                {mode === "view" ? (
                  <div>{userDetail?.address?.city ?? "-"}</div>
                ) : (
                  <Input placeholder="City" disabled={mode === "view"} />
                )}
              </Form.Item>

              <Form.Item name="postcode" label="Postcode" className="input">
                {mode === "view" ? (
                  <div>{userDetail?.address?.postcode ?? "-"}</div>
                ) : (
                  <Input placeholder="Postcode" disabled={mode === "view"} />
                )}
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                name="created_at"
                label="Creation Date"
                className="label-details"
              >
                <div>
                  {moment
                    .utc(userDetail.created_at)
                    .tz("Asia/Phnom_Penh")
                    .format("DD-MM-YYYY, hh:mm:ss A")}
                </div>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Space>
                      <Button
                        onClick={() => navigate(-1)}
                        className="default_btn"
                      >
                        Cancel
                      </Button>

                      <Button htmlType="submit" className="ant-btn-green">
                        Save
                      </Button>
                    </Space>
                  </div>
                </Row>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Member Wallet Info" key="2">
            <div className={styles.section_title} style={{ marginBottom: 16 }}>
              {"Member Wallet Info"}
            </div>
            <Table
              className={styles.pagination_item}
              columns={columns}
              dataSource={tableData}
              // onChange={onChange}
              size="small"
              scroll={{
                y: contentHeight - 90 - 40 - 56 - 70
              }}
              pagination={false}
            />

            <Row
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
                marginBottom: 20
              }}
            >
              <div>
                <Space>
                  <Button onClick={() => navigate(-1)} className="default_btn">
                    Back
                  </Button>
                </Space>
              </div>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Member Device Info" key="3">
            <div className={styles.section_title} style={{ marginBottom: 16 }}>
              {"Member Device Info"}
            </div>
            <Form
              colon={false}
              form={form}
              ref={formRef}
              labelCol={{
                span: 4
              }}
              wrapperCol={{
                span: 20
              }}
              initialValues={{
                remember: true
              }}
              onFinish={(values) => {
                updateCustomer(values);
              }}
              className="form-details"
            >
              <Form.Item name="brand" label="Brand" className="label-details">
                <div>{deviceData.brand ?? "-"}</div>
              </Form.Item>

              <Form.Item name="model" label="Model" className="label-details">
                <div>{deviceData.model ?? "-"}</div>
              </Form.Item>

              {/* <Form.Item
                name="device_type"
                label="Device Type"
                className="label-details"
              >
                <div>{deviceData.device_type ?? "-"}</div>
              </Form.Item> */}

              <Form.Item
                name="version"
                label="App Version"
                className="label-details"
              >
                <div>{deviceData.version ?? "-"}</div>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                name="sys_build_id"
                label="SYS Build ID"
                className="label-details"
              >
                <div>{deviceData.sys_build_id ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="sys_build_number"
                label="SYS Build No"
                className="label-details"
              >
                <div>{deviceData.sys_build_number ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="sys_name"
                label="SYS Name"
                className="label-details"
              >
                <div>{deviceData.sys_name ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="sys_version"
                label="SYS Version"
                className="label-details"
              >
                <div>{deviceData.sys_version ?? "-"}</div>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                name="device_id"
                label="Device ID"
                className="label-details"
              >
                <div>{deviceData.device_id ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="device_name"
                label="Device Name"
                className="label-details"
              >
                <div>{deviceData.device_name ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="device_type"
                label="Device Type"
                className="label-details"
              >
                <div>{deviceData.device_type ?? "-"}</div>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                name="mac_address"
                label="Mac Address"
                className="label-details"
              >
                <div>
                  {deviceData.mac_address ? deviceData.mac_address : "-"}
                </div>
              </Form.Item>

              <Form.Item
                name="ip_address"
                label="IP Address"
                className="label-details"
              >
                <div>{deviceData.ip_address ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="manufacturer"
                label="Manufacturer"
                className="label-details"
              >
                <div>
                  {deviceData.manufacturer ? deviceData.manufacturer : "-"}
                </div>
              </Form.Item>

              <Form.Item name="model" label="Model" className="label-details">
                <div>{deviceData.model ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="unique_id"
                label="Unique ID"
                className="label-details"
              >
                <div>{deviceData.unique_id ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="user_agent"
                label="User Agent"
                className="label-details"
              >
                <div>{deviceData.user_agent ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="bundle_id"
                label="Bundle ID"
                className="label-details"
              >
                <div>{deviceData.bundle_id ?? "-"}</div>
              </Form.Item>

              <Form.Item
                name="carrier"
                label="Carrier"
                className="label-details"
              >
                <div>{deviceData.carrier ?? "-"}</div>
              </Form.Item>

              <Divider className="divider" />

              <Form.Item
                name="has_gms"
                label="Has Gms"
                className="label-details"
              >
                <div>{deviceData.has_gms ? "Yes" : "No"}</div>
              </Form.Item>

              <Form.Item
                name="has_hms"
                label="Has Hms"
                className="label-details"
              >
                <div>{deviceData.has_hms ? "Yes" : "No"}</div>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Space>
                      <Button
                        onClick={() => navigate(-1)}
                        className="default_btn"
                      >
                        Back
                      </Button>
                    </Space>
                  </div>
                </Row>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Latest Transactions" key="4">
            <div className={styles.section_title} style={{ marginBottom: 16 }}>
              {"Latest Transactions"}
            </div>
            <Table
              className={styles.pagination_item}
              columns={transactionColumns}
              dataSource={transactionData}
              // onChange={onChange}
              size="small"
              scroll={{
                y: contentHeight - 90 - 40 - 56 - 70
              }}
              pagination={false}
            />

            <Row justify="end">
              <div className={styles.btn_container}>
                <Space style={{ marginRight: 10 }}>
                  <Button onClick={() => navigate(-1)} className="default_btn">
                    Back
                  </Button>
                </Space>

                <Button
                  type="default"
                  className="default_btn"
                  // onClick={() => navigate(`/transaction/all?username=${userDetail.username}`)}
                  onClick={() =>
                    window.open(
                      `/transaction/all?username=${userDetail.username}`,
                      "_blank",
                      "noreferrer"
                    )
                  }
                >
                  View All
                </Button>
              </div>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Member ID Verification" key="5">
            <div className={styles.section_title} style={{ marginBottom: 16 }}>
              {"Member ID Verification"}
            </div>

            {userDetail?.user_kyc_id !== null && kycData?.status !== 0 && (
              <div>
                <Form
                  colon={false}
                  form={form}
                  ref={kycRef}
                  labelCol={{
                    span: 4
                  }}
                  wrapperCol={{
                    span: 20
                  }}
                  // initialValues={{
                  //   remember: true
                  // }}
                  onFinish={(values) => {
                    statusOnClick(values);
                  }}
                  className="form-details"
                >
                  <Form.Item
                    name="status"
                    label="Status"
                    className="label-details"
                  >
                    <div style={renderStatusColor(kycData.status)}>
                      {renderStatus(kycData.status)}
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="change_status"
                    label="Click to Change Status"
                    className="label-details"
                  >
                    <Space>
                      <Button
                        type="default"
                        className="red_btn outline"
                        onClick={() => statusOnClick(5)}
                        disabled={isButtonDisabled(kycData.status)}
                      >
                        Reject
                      </Button>

                      <Button
                        type="default"
                        className="green_btn outline"
                        onClick={() => statusOnClick(3)}
                        disabled={isButtonDisabled(kycData.status)}
                      >
                        Approve
                      </Button>

                      {kycData?.status === 3 && (
                        <Button
                          onClick={() => statusOnClick(8)}
                          className="ant-btn-green"
                        >
                          Enable Update
                        </Button>
                      )}
                    </Space>
                  </Form.Item>

                  <Form.Item
                    name="type"
                    label="ID Type"
                    className="label-details"
                  >
                    <div>
                      {kycData.id_type === "nric" ? "NRIC" : "Passport"}
                    </div>
                  </Form.Item>
                  {kycData.reason && (
                    <Form.Item
                      name="reasons"
                      label="Rejected Reason"
                      className="label-details"
                    >
                      <div>{kycData.reason}</div>
                    </Form.Item>
                  )}
                  <Form.Item
                    name="submission_date"
                    label="Submission Date"
                    className="label-details"
                  >
                    <div>
                      {kycData.created_at
                        ? moment
                            .utc(kycData.created_at)
                            .tz("Asia/Phnom_Penh")
                            .format("DD-MM-YYYY, hh:mm:ss A")
                        : "-"}
                    </div>
                  </Form.Item>

                  <Form.Item
                    name="processed_date"
                    label="Processed Date"
                    className="label-details"
                  >
                    <div>
                      {kycData.processed_at
                        ? moment
                            .utc(kycData.processed_at)
                            .tz("Asia/Phnom_Penh")
                            .format("DD-MM-YYYY, hh:mm:ss A")
                        : "-"}
                    </div>
                  </Form.Item>

                  <Divider className="divider" />

                  <Form.Item
                    name="topkash_id"
                    label="Topkash ID"
                    className="label-details"
                  >
                    <div>{kycData.topkash_id}</div>
                  </Form.Item>
                  <Form.Item
                    name="username"
                    label="Username"
                    className="label-details"
                  >
                    <div>{kycData.username}</div>
                  </Form.Item>
                  <Form.Item
                    name="nationality"
                    label="Nationality"
                    className="label-details"
                  >
                    <div>{nationality}</div>
                  </Form.Item>

                  <Divider className="divider" />

                  <Form.Item
                    name="id_number"
                    label="NRIC / Passport No."
                    className="label-details"
                  >
                    <div>{kycData.id_number}</div>
                  </Form.Item>
                  <Form.Item
                    name="media"
                    label="Supporting Materials"
                    className="label-details"
                  >
                    <Image.PreviewGroup>
                      {map(kycData.media, (item) => {
                        return (
                          <Image
                            // width={300}
                            height={250}
                            src={item.path}
                            style={{
                              border: "2px solid #ddd",
                              padding: "5px"
                            }}
                          />
                        );
                      })}
                    </Image.PreviewGroup>
                  </Form.Item>

                  <Divider className="divider" />

                  <Form.Item
                    name="assigned_to"
                    label="Assigned To"
                    className="label-details"
                  >
                    <div>
                      {kycData.assigned_user_username
                        ? kycData.assigned_user_username
                        : "-"}
                    </div>
                  </Form.Item>
                </Form>

                <Row justify="end">
                  <div className={styles.btn_container}>
                    <Space style={{ marginRight: 10 }}>
                      <Button
                        onClick={() => navigate(-1)}
                        className="default_btn"
                      >
                        Back
                      </Button>
                    </Space>
                  </div>
                </Row>
              </div>
            )}

            {userDetail.user_kyc_id === null && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            {kycData?.status == 0 && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Tabs.TabPane>

          {/* {!isEmpty(userDetail) && !userDetail.upline_id ? (
            <Tabs.TabPane tab="Referral Settings" key="5">
              <Form
                colon={false}
                form={referralForm}
                ref={referralFormRef}
                labelCol={{
                  span: 4,
                }}
                wrapperCol={{
                  span: 20,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={(values) => {
                  updateCustomerCommissionRate(values);
                }}
                className="form-details"
              >
                <Form.Item
                  name="commission_rate"
                  label="Commission rate"
                  className="label-details"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the commission rate",
                    },
                    {
                      required: true,
                      validator: async (_, value) => {
                        value = value
                          ? unformat(value.replace(/(\,)/g, ""))
                          : "";

                        if (value === 0) {
                          throw new Error("Please enter the commission rate");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    className="input"
                    placeholder="0.00"
                    style={{ textAlign: "right" }}
                    onChange={(e) => {
                      commissionRateOnChange(e.target.value);
                    }}
                    disabled={mode === "view"}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div>
                      <Space>
                        <Button
                          onClick={() => navigate("/member")}
                          className="default_btn"
                        >
                          Cancel
                        </Button>

                        <Button htmlType="submit" className="ant-btn-green">
                          Save
                        </Button>
                      </Space>
                    </div>
                  </Row>
                </Form.Item>
              </Form>
            </Tabs.TabPane>
          ) : null} */}
        </Tabs>

        <HistoryTable data={historyData} />

        {/* Reject Reason Popup */}
        <ConfirmationPopUp
          visible={showRejectModal}
          title="Reason for Rejection"
          handleOnOk={handleModalOk}
          handleOnCancel={onCancelModal}
          modalFormOnFinish={(values) => {}}
        >
          <Form
            ref={formRef}
            style={{ width: "100%" }}
            // className="form-details"
          >
            <Form.Item
              name="reason"
              label="Reject Reason"
              className="label-details"
            >
              {mode === "view" ? (
                <div className="label-details"></div>
              ) : (
                <Select
                  name="reason"
                  placeholder="Reason"
                  className="label-details"
                  onSelect={(value, key) => {
                    setSelectedReason(value);
                    setSelectedReasonIndex(key);
                  }}
                >
                  {map(reason, (item) => {
                    return (
                      <Option key={item.index} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
            {selectedReason == "others" && (
              <Form.Item
                name="custom_reason"
                label="Other Reason"
                className="label-details"
                rules={[
                  {
                    required: true,
                    message: "Please enter your reason"
                  }
                ]}
              >
                <TextArea rows={4} placeholder="Custom Reason" />
              </Form.Item>
            )}
          </Form>
        </ConfirmationPopUp>
      </Card>
    </div>
  );
};

export default CustomerEdit;
