import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";

const initialState = {
  isLoading: true,
  reload: []
};

export const getReloadCases = createAsyncThunk(
  "reload/getReloadCases",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("cases/reload", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllReloads = createAsyncThunk(
  "reload/findAllReloads",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder("cases/reload/all", getState().ui.table, filter);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllPendingReloads = createAsyncThunk(
  "reload/findAllPendingReloads",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "cases/reload/pending",
        getState().ui.table,
        filter
      );

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findReloadById = createAsyncThunk(
  "reload/findReloadById",
  async ({ id, mode }) => {
    try {
      const { data } = await axios.get(`cases/reload/${id}?mode=${mode}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateReloadById = createAsyncThunk(
  "reload/updateReloadById",
  async (values) => {
    try {
      let dataTemp = JSON.stringify({
        // bank_acc_id: values.bank_acc_id,
        // amount: values.amount,
        status: values.status,
        reject_reason: values.reject_reason
          ? values.reject_reason.trim()
          : null,
        from_crypto_address: values.from_crypto_address
          ? values.from_crypto_address
          : null
      });

      const { data } = await axios.put(`cases/reload/${values.id}`, dataTemp, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const reloadSlice = createSlice({
  name: "reload",
  initialState,
  reducers: {
    resetReload: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    const matchBuilder = (action, status) =>
      action.type.startsWith("reload/updateReloadById") &&
      action.type.endsWith(status);

    builder.addCase(getReloadCases.fulfilled, (state, { payload }) => {
      // state.loading = false;
      state.reload = payload.data.data;
    });

    builder.addCase(findReloadById.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateReloadById.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addMatcher(
      (action) => matchBuilder(action, "/pending"),
      (state) => ({
        ...state,
        isLoading: true
      })
    );

    builder.addMatcher(
      (action) => matchBuilder(action, "/Unsuccessful"),
      (state) => ({
        ...state,
        isLoading: false
      })
    );
  }
});

export const { resetReload } = reloadSlice.actions;

export default reloadSlice.reducer;
