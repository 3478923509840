import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Row,
  Button,
  message,
  Select,
  Upload,
  Space,
  InputNumber,
  Spin
} from "antd";
// ============
import styles from "./styles.module.less";
import { addCurrency } from "../../../store/currency";
import { map } from "lodash";
import { unformat, formatNumber } from "accounting-js";
const { Option } = Select;

const CurrencyAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [typeSelected, setTypeSelected] = useState(null);

  const currencyTypeOption = [
    { index: 1, value: "normal", label: "Normal" },
    { index: 2, value: "crypto", label: "Crypto" }
  ];

  useEffect(() => {
    const currencyLocale = new Intl.NumberFormat().resolvedOptions().locale;
    formRef.current.setFieldsValue({
      locale: currencyLocale
    });
  }, []);
  const handleSubmit = async (values) => {
    console.log("values");
    console.log(values);
    setIsLoading(true);
    try {
      if (values) {
        let minimum_amount =
          unformat(values.minimum_amount.replace(/(\.|\,)/gi, "")) / 100;
        let unverified_maximum_amount =
          unformat(values.unverified_maximum_amount.replace(/(\.|\,)/gi, "")) /
          100;

        await dispatch(
          addCurrency({
            currency_name: values.currency_name,
            iso_code: values.iso_code,
            type: values?.type,
            minimum_amount,
            unverified_maximum_amount
          })
        ).unwrap();
        message.success("You have successfully added the currency");

        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const minAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const minimum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(minimum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      minimum_amount: formattedAmount
    });
  });

  const maxAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const maximum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(maximum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      unverified_maximum_amount: formattedAmount
    });
  });

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <Card className={styles.card_container}>
          <Row>
            <div className={styles.section_title}>New Currency</div>
          </Row>

          <Form
            ref={formRef}
            colon={false}
            name="basic"
            labelCol={{
              span: 4
            }}
            wrapperCol={{
              span: 20
            }}
            initialValues={{
              remember: true
            }}
            onFinish={handleSubmit}
            autoComplete="off"
            className="form-details"
          >
            <Form.Item
              name="iso_code"
              label="Currency Code"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the currency code"
                }
              ]}
            >
              <Input placeholder="Currency Code" />
            </Form.Item>

            <Form.Item
              name="currency_name"
              label="Currency Name"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the currency name"
                }
              ]}
            >
              <Input placeholder="Currency Name" />
            </Form.Item>

            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Please enter the currency type"
                }
              ]}
            >
              <Select
                name="type"
                placeholder="Type"
                onSelect={(value) => {
                  setTypeSelected(value);
                }}
              >
                {map(currencyTypeOption, (item) => {
                  return (
                    <Option key={item.index} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="minimum_amount"
              label="Minimum Amount Limit Per Transaction"
              className="two-rows-label"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the minimum amount limit per transaction"
                },
                {
                  required: true,
                  validator: async (_, value) => {
                    value = value ? unformat(value.replace(/(\,)/g, "")) : null;

                    if (value) {
                      if (value === 0) {
                        throw new Error("Please enter Min Amount");
                      }
                    } else {
                      // throw new Error("Please enter Min Amount");
                    }
                  }
                }
              ]}
            >
              <Input
                className="input"
                placeholder="0.00"
                style={{ textAlign: "right" }}
                onChange={(e) => {
                  minAmountOnChange(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="unverified_maximum_amount"
              label="Maximum Amount Limit Per Transaction"
              className="two-rows-label"
              extra="(This will only be applied to unverified members)"
              rules={[
                {
                  required: true,
                  message: "Please enter the maximum amount"
                },
                {
                  required: true,
                  validator: async (_, value) => {
                    value = value ? unformat(value.replace(/(\,)/g, "")) : null;

                    if (value) {
                      if (value === 0) {
                        throw new Error("Please enter Min Amount");
                      }
                    } else {
                      // throw new Error("Please enter Min Amount");
                    }
                  }
                }
              ]}
            >
              <Input
                className="input"
                placeholder="0.00"
                style={{ textAlign: "right" }}
                onChange={(e) => {
                  maxAmountOnChange(e.target.value);
                }}
              />
            </Form.Item>

            {/* =========== TO DO : UPLOAD THE LOGO INTO THE AWS SERVER =========== */}
            {/* Temporary omit this part first*/}
            {/* <div>
            <Form.Item
              name="flag"
              label={<div className="label">Currency Flag</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the minimum amount",
                },
              ]}
            >
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                }}
                size="large"
              >
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  maxCount={1}
                  style={{
                    width: "100%",
                    backgroundColor: "pink",
                  }}
                  onChange={handleOnChangeFlag}
                >
                  <Button icon={<UploadOutlined />}>Upload Flag</Button>
                </Upload>
              </Space>
            </Form.Item>
          </div> */}

            {/* <div className="input">
            <Form.Item
              name="locale"
              label={<div className="label">Locale</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the locale exp: en-US",
                },
              ]}
            >
              <Input value="hello" placeholder="Currency code" />
            </Form.Item>
          </div> */}
            <Row justify="end">
              <div className={styles.btn_container}>
                <Button
                  type="default"
                  className="default_btn"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
              <div className={styles.btn_container}>
                <Button
                  type="default"
                  htmlType="submit"
                  className="ant-btn-green"
                >
                  Save
                </Button>
              </div>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default CurrencyAdd;
