import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Tag,
  Select,
  Button,
  Col,
  Form,
  DatePicker
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findAllRedeemTransactions } from "@store/transaction";
import TableHeader from "@components/table-header";
import numeral from "numeral";
import { findCurrencyFilterOptionsByType } from "@store/currency";

const { CheckableTag } = Tag;

const TopupGiftCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [selectedTags, setSelectedTags] = useState([]);

  const { RangePicker } = DatePicker;
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [currencies, setCurrencies] = useState([]);

  const location = useLocation();

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
  }, [location]);

  const viewOnClick = (item) => {
    navigate(`/top-up/gift-card/${item.reference_id}`);
  };

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());
    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let transaction = await dispatch(
        findAllRedeemTransactions({
          filter
        })
      ).unwrap();

      let { data, total } = transaction.data;

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "reloads" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      setCurrencies(currencyFilterOptions);

      setTableData(data);
      setTableTotalData(total);
      // console.log('filter-change')
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(setTableSearch({ search: text }));
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      key: "id",
      width: 90,
      fixed: "left",
      render: (_, record) => {
        return <div>{record.doc_id}</div>;
      }
    },
    {
      title: () => {
        return (
          <>
            <span className={styles.table_column_title}>
              <div>Redemption</div>
              <div>Date (GMT +7)</div>
            </span>
          </>
        );
      },
      dataIndex: "created_at",
      align: "left",
      key: "created_at",
      width: 200,
      sorter: true,
      render: (_, record) => {
        return (
          <div>
            {moment
              .utc(record.created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <>
            <span className={styles.table_column_title}>
              <div>Member</div>
              <div>Topkash ID</div>
            </span>
          </>
        );
      },
      dataIndex: "customer_topkash_id",
      align: "left",
      key: "customer_topkash_id",
      width: 120,
      render: (_, record) => {
        return <div>{record.customer_topkash_id}</div>;
      }
    },
    {
      title: () => {
        return (
          <>
            <span className={styles.table_column_title}>
              <div>Member</div>
              <div>Username</div>
            </span>
          </>
        );
      },
      dataIndex: "customer_username",
      align: "left",
      width: 120,
      key: "customer_username",
      render: (_, record) => {
        if (record.customer_username) {
          return <div>{record.customer_username}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Currency",
      dataIndex: "from_currency_code",
      align: "right",
      key: "from_currency_code",
      width: 100
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      key: "amount",
      width: 130,
      render: (_, record) => {
        return (
          <div>
            {/* {(Math.round(record.amount * 100) / 100).toFixed(2)} */}
            {numeral(parseFloat(record.amount)).format("0,0.00")}
          </div>
        );
      }
    },
    {
      title: "Redemption Code",
      dataIndex: "voucher_code",
      align: "left",
      key: "voucher_code",
      width: 160,
      render: (_, record) => {
        return <div>{record.voucher_code}</div>;
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"center"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => viewOnClick(record)}
              >
                View
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      {/* <div style={{ marginBottom: 40 }}>
        <Row justify="space-between" align="middle">
          <Space>
            <div className="table_search_input">
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
                onChange={(e) => {
                  onChangeText(e.target.value);
                }}
              />
            </div>
            <Select
              mode="multiple"
              allowClear
              className="table-search-select"
              labelInValue
              placeholder="Status"
              defaultValue={[]}
              onChange={handleStatusChange}
              options={statusOptions}
            />
          </Space>
        </Row>
        <div style={{ marginTop: 20 }}>
          <span
            style={{
              marginRight: 8,
            }}
          >
            Currencies:
          </span>
          <Space size={[0, 8]} wrap>
            {currencies.map((tag) => (
              <CheckableTag
                key={tag.id}
                checked={selectedTags.includes(tag.id)}
                onChange={(checked) => handleChange(tag.id, checked)}
              >
                {tag.currency_iso}
              </CheckableTag>
            ))}
          </Space>
        </div>
      </div> */}
      <TableHeader
        title="Top-Ups (Gift Card)"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        // createOnClick={() => navigate("/customer-service-user/add")}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
          // dispatch(resetTablePagination());
          // dispatch(resetTableFilter());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"id"} initialValue={null}>
              <Input
                placeholder="ID"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"topkash_id"} initialValue={null}>
              <Input
                placeholder="Member TopKash ID"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"username"} initialValue={null}>
              <Input
                placeholder="Member Username"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"currency"} initialValue={[]}>
              <Select
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Currency"
                options={
                  currencies && currencies.length > 0 ? currencies : null
                }
                showArrow
                filterOption={(input, option) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                filterSort={(optionA, optionB) =>
                  optionA.label
                    .toLowerCase()
                    .localeCompare(optionB.label.toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>

      <Table
        className={styles.pagination_item}
        columns={columns}
        onChange={onChange}
        loading={isLoading}
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        dataSource={tableData}
        size="middle"
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default TopupGiftCard;
