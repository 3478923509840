import React from "react";
import { Row, Button, Space, Form, Col } from "antd";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";

import PropTypes from "prop-types";
// ============
import styles from "./styles.module.less";
import { map } from "lodash";

const TableHeader = ({
  children,
  title,
  showCreateButton,
  createButtonText,
  showResetButton,
  showFilterButton,
  filterFormOnFinish,
  createOnClick,
  resetOnClick,
  showAdditionalNote,
  showKycAdditionalNote,
  customButtons
}) => {
  const formOnFinish = (values) => {
    filterFormOnFinish(values);
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Row align="middle">
        <div className="page-title">{title}</div>
      </Row>
      <Row align="middle" style={{ marginBottom: 20 }}>
        {showCreateButton && (
          <div>
            <Button
              onClick={createOnClick}
              type="secondary"
              icon={<PlusOutlined />}
            >
              {createButtonText ? createButtonText : "Create"}
            </Button>
          </div>
        )}
      </Row>

      <Form onFinish={formOnFinish}>
        <Row justify="end" gutter={12}>
          {children}
        </Row>
        <Row className={styles.filter_button} justify="end">
          <Col span={12}>
            {showAdditionalNote
              ? showAdditionalNote && (
                  <span>
                    <div className={styles.additional_note}>
                      Note: Whoever clicks
                      <span style={{ fontWeight: 700 }}> EDIT </span>
                      first, for a
                      <span style={{ fontWeight: 700 }}> PENDING </span>
                      transaction,
                    </div>
                    <div className={styles.additional_note}>
                      will be the{" "}
                      <span style={{ fontWeight: 700 }}> ONLY </span>
                      user who can approve or reject the transaction.
                    </div>
                  </span>
                )
              : showKycAdditionalNote && (
                  <span>
                    <div className={styles.additional_note}>
                      Note: Whoever clicks
                      <span style={{ fontWeight: 700 }}> EDIT </span>
                      first, for a
                      <span style={{ fontWeight: 700 }}> PENDING </span>
                      ID verification,
                    </div>
                    <div className={styles.additional_note}>
                      will be the{" "}
                      <span style={{ fontWeight: 700 }}> ONLY </span>
                      user who can approve or reject the ID verification.
                    </div>
                  </span>
                )}
          </Col>

          <Col
            span={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Space>
              {/* {showCustomButton2 && (
                <Button
                  htmlType="button"
                  onClick={customOnClick2}
                  disabled={customButtonDisable2}
                >
                  {customButtonText2}
                </Button>
              )}

              {showCustomButton && (
                <Button
                  htmlType="button"
                  onClick={customOnClick}
                  disabled={customButtonDisable}
                >
                  {customButtonText}
                </Button>
              )} */}

              {customButtons &&
                customButtons.length > 0 &&
                map(customButtons, (button) => {
                  if (button.show) {
                    return (
                      <Button
                        htmlType="button"
                        onClick={() => button.onClick()}
                        disabled={button.disabled}
                      >
                        {button.text}
                      </Button>
                    );
                  }
                })}

              {showResetButton && (
                <Button htmlType="reset" onClick={resetOnClick}>
                  Reset
                </Button>
              )}

              {showFilterButton && (
                <Button type="primary" htmlType="submit">
                  <FilterOutlined />
                  Filter
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

TableHeader.propTypes = {
  title: PropTypes.bool.isRequired,
  //Create
  showCreateButton: PropTypes.bool,
  createOnClick: PropTypes.func,

  //Reset
  showResetButton: PropTypes.bool.isRequired,
  resetOnClick: PropTypes.func.isRequired,

  //Filter
  showFilterButton: PropTypes.bool.isRequired,
  filterFormOnFinish: PropTypes.func.isRequired,

  // Custom Button
  customButtonDisable: PropTypes.bool,

  customButtons: PropTypes.arrayOf(
    PropTypes.shape({
      show: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool.isRequired
    })
  )
};

TableHeader.defaultProps = {
  showCreateButton: true
};

export default TableHeader;
