import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  Space,
  Button,
  Modal,
  message,
  Tooltip,
} from "antd";

import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { map } from "lodash";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../../store/ui";
import { retriveBusinessTypes } from "../../../store/business-type";
import TableHeader from "../../../components/table-header";

const BusinessType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnableDelete, setIsEnableDelete] = useState(false);
  // const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  // const [selectedDeleteCurrencyId, setSelectedDeleteCurrencyId] =
  //   useState(null);
  // const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  useEffect(() => {
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isEnableDelete]);

  // const deleteOnClick = async (item) => {
  //   setIsConfirmModalVisible(true);
  //   setSelectedDeleteCurrencyId(item.id);
  //   setIsEnableDelete(false);
  // };

  const editOnClick = (item) => {
    navigate(`/settings/business-type/${item.id}`);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data: businessTypeData, total } = await dispatch(
        retriveBusinessTypes()
      ).unwrap();

      // const { data: businessTypeData, total } = data;

      setTableData(businessTypeData);
      setTableTotalData(total);

      dispatch(resetTableSearch());
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(
          setTableSearch({
            search: text,
          })
        );
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 40,
      align: "left",
      render: (_, record) => {
        return <div align={"left"}>{record.name}</div>;
      },
    },
    {
      title: "Label",
      dataIndex: "translations",
      align: "left",
      width: 80,
      render: (_, record) => {
        const { translations } = record;
        if (translations.length > 0) {
          return map(translations, (item) => (
            <div className={styles.table_column_title}>
              {item.language && item.language.code ? item.language.code : " - "}{" "}
              - {item.content}
            </div>
          ));
        } else {
          return <div> - </div>;
        }
      },
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 80,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div align="left">
            {moment
              .utc(created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 40,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Business Types"
        createButtonText="Create New Business Type"
        showCreateButton={true}
        showResetButton={false}
        showFilterButton={false}
        createOnClick={() => navigate("/settings/business-type/add")}
      ></TableHeader>

      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default BusinessType;
