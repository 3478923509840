import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useMatch,
  useNavigate,
  useSearchParams,
  useLocation
} from "react-router-dom";
import numeral from "numeral";
import {
  Card,
  Form,
  Row,
  Select,
  Button,
  message,
  Divider,
  Space,
  Input
} from "antd";
import { map, filter, includes, find, chain } from "lodash";
// ============
import styles from "./styles.module.less";
import { findTransferCaseById, updateTransferById } from "@store/transfer";
import { findAllSendBankAccounts } from "@store/bank";
import moment from "moment-timezone";
import HistoryTable from "@components/history-table";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
import ConfirmationPopUp from "@components/confirmation-popup";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@configs/can";

const { Option } = Select;

const SendBankDepositEdit = () => {
  const { params } = useMatch("send/bank/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const formRef = useRef();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const ability = useAbility(AbilityContext);

  const userId = useSelector((state) => state.app.user.id);

  const [mode, setMode] = useState(null);
  const [transferInfo, setTransferInfo] = useState({});
  const [allowEditing, setAllowEditing] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const localTimeOffset = useSelector((state) => state.app.localTimeOffset);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showSourceBankModal, setShowSourceBankModal] = useState(false);
  const [sourceBanks, setSourceBanks] = useState([]);
  const [sourceBankAccounts, setSourceBankAccounts] = useState([]);
  const [formValues, setFormValues] = useState({});

  const { TextArea } = Input;

  useEffect(() => {
    let currentMode = searchParams.get("mode");

    if (!includes(["edit", "view"], currentMode)) {
      navigate("/error");
    }

    setMode(currentMode);

    if (ability.cannot("update", "Transfer") && currentMode === "edit") {
      const { pathname, search } = location;

      navigate(`${pathname}${search.replace(/edit/gi, "view")}`, {
        replace: true
      });
    }

    const fetchData = async () => {
      try {
        const res = await dispatch(
          findTransferCaseById({
            id: params.id,
            mode: currentMode
          })
        ).unwrap();

        let details = res;

        // retry search if still pending
        // if (details.data.status == 1) {
        //   const res2 = await dispatch(
        //     findTransferCaseById({
        //       id: params.id,
        //     })
        //   ).unwrap();

        // if pending, assign case to user then get info again
        // setTimeout(() => {}, 500);
        let retryCount = 0;
        while (
          details.data.status == 1 &&
          currentMode == "edit" &&
          retryCount < 5
        ) {
          const res2 = await dispatch(
            findTransferCaseById({
              id: params.id
            })
          ).unwrap();

          details = res2;
          retryCount++;
        }

        const { data: transferData } = details;

        if (transferData) {
          setTransferInfo(transferData);
          if (transferData.status == 2) {
            // setTransferInfo(transferData);

            if (transferData.assigned_user_id) {
              let assignedUserId = transferData.assigned_user_id;

              if (assignedUserId == userId) {
                setAllowEditing(true);
              }
            }
          }
          // } else {
          //   message.error("This transfer record is not available for editing");
          //   navigate("/transfer");
          // }
        }

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "transfers"
          })
        ).unwrap();
        const filteredHistoryData = filter(
          historyData,
          (item) => item.action_type !== "View"
        );

        const { data: bankData } = await dispatch(
          findAllSendBankAccounts({ paginate: false })
        ).unwrap();

        let sourceBanks = chain(bankData)
          .groupBy((item) => item.bank_name)
          .map((value, key) => ({
            bank_name: key,
            accounts: value
          }))
          .value();

        setSourceBanks(sourceBanks);

        setHistoryData(filteredHistoryData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const renderStatus = (status) => {
    if (status === 1) {
      return "Pending";
    } else if (status === 2) {
      return "Processing";
    } else if (status === 3) {
      return "Successful";
    } else if (status === 5) {
      return "Unsuccessful";
    } else {
      return "N/A";
    }
  };

  const renderStatusColor = (status) => {
    if (status === 3) {
      return { color: "#339933" };
    } else if (status === 5) {
      return { color: "#ff0000" };
    } else {
      return { color: "#4f8bc2" };
    }
  };

  const isButtonDisabled = (status) => {
    // const hasAccessToUpdate = ability.can("update", "Transfer");

    return (
      (status === 2 && !allowEditing) ||
      status === 3 ||
      status === 5 ||
      mode === "view"
    );
  };

  const statusOnClick = async (status) => {
    if (status === 3) {
      setShowSourceBankModal(true);
    } else if (status === 5) {
      const updated = await updateTransfer({ status: 5 });

      if (updated) {
        message.success("You have successfully edited send info");
        navigate(-1);
      }
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    setShowSourceBankModal(false);
    setShowRejectModal(false);
    setFormValues({});
  };

  const onChangeSourceBank = (value) => {
    let selectedBank = find(sourceBanks, (item) => {
      return item.bank_name == value;
    });

    form.setFieldValue("source_bank_account", null);

    setSourceBankAccounts(selectedBank.accounts);
  };

  const formOnChange = (_, existingValues) => {
    setFormValues(existingValues);
  };

  const updateTransfer = async ({
    tk_bank_account_id,
    tk_reference_no,
    status
  }) => {
    try {
      if (ability.can("update", "Transfer")) {
        await dispatch(
          updateTransferById({
            id: params.id,
            status,
            reject_reason: null,
            tk_bank_account_id: tk_bank_account_id ?? null,
            tk_reference_no: tk_reference_no ?? null
          })
        ).unwrap();

        return true;
      } else {
        throw { message: "Invalid Permission" };
      }
    } catch (error) {
      message.error(error.message);

      return false;
    }
  };

  const onSaveSelectSourceBank = async (values) => {
    const updated = await updateTransfer({
      tk_bank_account_id: values.source_bank_account,
      tk_reference_no: values.reference_no,
      status: 3
    });

    if (updated) {
      setShowSourceBankModal(false);
      message.success("You have successfully edited send info");
      navigate(-1);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">
            {mode === "edit" ? "Edit Send" : "View Send"}
          </div>
        </Row>

        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
        >
          <Form.Item name="status" label="Status" className="label-details">
            <div style={renderStatusColor(transferInfo.status)}>
              {renderStatus(transferInfo.status)}
            </div>
          </Form.Item>

          <Form.Item
            name="change_status"
            label="Click to Change Status"
            className="label-details"
          >
            <Space>
              <Button
                type="default"
                className="red_btn outline"
                onClick={() => statusOnClick(5)}
                disabled={isButtonDisabled(transferInfo.status)}
              >
                Reject
              </Button>

              <Button
                type="default"
                className="green_btn outline"
                onClick={() => statusOnClick(3)}
                disabled={isButtonDisabled(transferInfo.status)}
              >
                Approve
              </Button>
            </Space>
          </Form.Item>

          <Form.Item name="doc_id" label="ID" className="label-details">
            <div>{transferInfo.doc_id}</div>
          </Form.Item>

          <Form.Item
            name="send_type"
            label="Send Type"
            className="label-details"
          >
            <div>Bank</div>
          </Form.Item>

          <Form.Item
            name="submission_date"
            label="Submission Date"
            className="label-details"
          >
            <div>
              {transferInfo.created_at
                ? moment
                    .utc(transferInfo.created_at)
                    .tz("Asia/Phnom_Penh")
                    .format("DD-MM-YYYY, hh:mm:ss A")
                : "-"}
            </div>
          </Form.Item>

          <Form.Item
            name="processed_date"
            label="Processed Date"
            className="label-details"
          >
            <div>
              {transferInfo.processed_at
                ? moment
                    .utc(transferInfo.processed_at)
                    .tz("Asia/Phnom_Penh")
                    .format("DD-MM-YYYY, hh:mm:ss A")
                : "-"}
            </div>
          </Form.Item>

          <Divider className="divider" />

          <Form.Item
            name="topkash_id"
            label="Topkash ID"
            className="label-details"
          >
            <div>{transferInfo.user_topkash_id}</div>
          </Form.Item>

          <Form.Item name="username" label="Username" className="label-details">
            <div>{transferInfo.user_username}</div>
          </Form.Item>

          <Form.Item
            name="fullname"
            label="Full Name"
            className="label-details"
          >
            <div>
              {transferInfo.user_fullname ? transferInfo.user_fullname : "-"}
            </div>
          </Form.Item>

          <Form.Item name="mobile" label="Mobile" className="label-details">
            <div>{transferInfo.user_phone_no}</div>
          </Form.Item>

          <Form.Item name="email" label="Email" className="label-details">
            <div>{transferInfo.user_email}</div>
          </Form.Item>

          <Divider className="divider" />

          <div className="section_subtitle">Conversion</div>
          {/* <Form.Item label="Conversion" className={styles.section_subtitle} /> */}

          <Form.Item
            name="from_currency"
            label="From Currency"
            className="label-details"
          >
            <div>
              {transferInfo.from_currency_code} -{" "}
              {transferInfo.from_currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="from_amount"
            label="From Amount"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(transferInfo.amount)).format("0,0.00")}
            </div>
          </Form.Item>

          <Form.Item name="fee" label="Fee" className="label-details">
            <div>
              {numeral(parseFloat(transferInfo.processing_fee)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="amount_to_converted"
            label="Amount To Converted"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(transferInfo.to_convert_amount)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="exchange_rate"
            label="Exchange Rate"
            className="label-details"
          >
            <div>
              {transferInfo.from_currency_code == transferInfo.to_currency_code
                ? "1.000000"
                : numeral(parseFloat(transferInfo.exchange_rate)).format(
                    "0,0.000000"
                  )}
            </div>
          </Form.Item>

          <Form.Item
            name="to_currency"
            label="To Currency"
            className="label-details"
          >
            <div>
              {transferInfo.to_currency_code} - {transferInfo.to_currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="to_amount"
            label="To Amount"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(transferInfo.converted_amount)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Divider className="divider" />

          <div className="section_subtitle">Recipient Bank</div>

          {/* <Form.Item
            label="Recipient Bank"
            className={styles.section_subtitle}
          /> */}

          <Form.Item
            name="recipient_country"
            label="Country"
            className="label-details"
          >
            <div>
              {transferInfo.country_name ? transferInfo.country_name : "-"}
            </div>
          </Form.Item>

          <Form.Item
            name="recipient_bank"
            label="Bank"
            className="label-details"
          >
            <div>{transferInfo.bank_name ? transferInfo.bank_name : "-"}</div>
          </Form.Item>

          <Form.Item
            name="recipient_account"
            label="Acc No"
            className="label-details"
          >
            <div>
              {transferInfo.reference_bank_account_no
                ? transferInfo.reference_bank_account_no
                : "-"}
            </div>
          </Form.Item>

          <Form.Item
            name="recipient_acc_name"
            label="Acc Name"
            className="label-details"
          >
            <div>
              {transferInfo.reference_bank_account_name
                ? transferInfo.reference_bank_account_name
                : "-"}
            </div>
          </Form.Item>

          {transferInfo.status === 3 && (
            <>
              <Divider className="divider" />

              <div className="section_subtitle">Source Bank</div>

              {/* <Form.Item
                label="Source Bank"
                className={styles.section_subtitle}
              /> */}

              <Form.Item
                name="sender_country"
                label="Country"
                className="label-details"
              >
                <div>{transferInfo.topkash_bank_country}</div>
              </Form.Item>

              <Form.Item
                name="sender_bank"
                label="Bank"
                className="label-details"
              >
                <div>{transferInfo.topkash_bank_name}</div>
              </Form.Item>

              <Form.Item
                name="sender_account"
                label="Acc No"
                className="label-details"
              >
                <div>{transferInfo.acc_no}</div>
              </Form.Item>

              <Form.Item
                name="sender_acc_name"
                label="Acc Name"
                className="label-details"
              >
                <div>{transferInfo.acc_name}</div>
              </Form.Item>
            </>
          )}

          <Divider className="divider" />

          <Form.Item
            name="assigned_to"
            label="Assigned To"
            className="label-details"
          >
            <div>
              {transferInfo.assigned_user_fullname} (
              {transferInfo.assigned_user_username})
            </div>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            </div>
          </Row>
        </Form>

        <HistoryTable data={historyData} />

        {/* Reject Reason Popup */}
        <ConfirmationPopUp
          visible={showRejectModal}
          title="Please Input Reject Reason"
          handleOnOk={() => console.log("ok")}
          handleOnCancel={onCancelModal}
          modalFormOnFinish={(values) => {
            console.log(values);
          }}
        >
          <Form
            ref={formRef}
            style={{ width: "100%" }}
            // onFinish={handleSubmit}
          >
            <Form.Item
              name="reject_reason"
              label="Reject Reason"
              className="label-details"
            >
              <TextArea placeholder="Reason" rows={4} maxLength={360} />
            </Form.Item>
          </Form>
        </ConfirmationPopUp>

        {/* Source Bank Popup */}
        <ConfirmationPopUp
          visible={showSourceBankModal}
          title="Please Select Source Bank"
          handleOnOk={() => formRef.current.submit()}
          handleOnCancel={onCancelModal}
          modalFormOnFinish={(values) => {
            console.log(values);
          }}
        >
          <Form
            ref={formRef}
            form={form}
            style={{ width: "100%" }}
            onFinish={onSaveSelectSourceBank}
            labelCol={{
              span: 9
            }}
            wrapperCol={{
              span: 15
            }}
            className="modal-form"
            labelAlign="left"
            onValuesChange={formOnChange}
          >
            <Form.Item
              name="source_bank"
              label="Source Bank"
              className="label-details"
              style={{ textAlign: "left" }}
              rules={[{ required: true, message: "Please select source bank" }]}
            >
              <Select placeholder="Source Bank" onChange={onChangeSourceBank}>
                {map(sourceBanks, (item) => {
                  return (
                    <Option key={item.bank_name} value={item.bank_name}>
                      {item.bank_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="source_bank_account"
              label="Source Bank Account"
              className="label-details"
              style={{ textAlign: "left" }}
              rules={[
                {
                  required: true,
                  message: "Please select source bank account"
                }
              ]}
            >
              <Select
                placeholder="Source Bank Account"
                disabled={!formValues["source_bank"]}
              >
                {map(sourceBankAccounts, (item) => {
                  return (
                    <Option key={item.bank_acc_id} value={item.bank_acc_id}>
                      {item.acc_name} - {item.acc_no}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="reference_no"
              label="Reference No"
              className="label-details"
            >
              <Input placeholder="Reference No" />
            </Form.Item>
          </Form>
        </ConfirmationPopUp>
      </Card>
    </div>
  );
};

export default SendBankDepositEdit;
