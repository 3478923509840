import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Col } from "antd";
import { useSelector } from "react-redux";

const FullPageSpinner = () => {
  const isLoading = useSelector((state) => state.app.userHistory.loading);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
        color: "#ffffff",
      }}
      spin
    />
  );

  return (
    <>
      {isLoading ? (
        <Col
          style={{
            position: "fixed",
            textAlign: "center",
            zIndex: 1100,
            height: "100%",
            width: "100%",
            backgroundColor: "rgb(62 58 58 / 75%)",
            lineHeight: "100vh",
          }}
        >
          <Spin indicator={antIcon} />
        </Col>
      ) : null}
    </>
  );
};

export default FullPageSpinner;
