import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, Form, Row, Select, Button, message, Space } from "antd";

import styles from "./styles.module.less";
import {
  findExchangeRateSettingById,
  updateExchangeRateSettingById
} from "@store/exchange";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";

import HistoryTable from "@components/history-table";

const ExchangeRateSettingEdit = () => {
  const { params } = useMatch("settings/manual-cost-exchange-rate/:id");
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();

  const [detail, setDetail] = useState({});
  const [historyData, setHistoryData] = useState([]);

  const updateExchangeRateSetting = async (values) => {
    try {
      const { data } = await dispatch(
        updateExchangeRateSettingById({
          id: params.id,
          status: values.status,
          duration: values.duration
        })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve countries
      try {
        const { data } = await dispatch(
          findExchangeRateSettingById({
            id: params.id
          })
        ).unwrap();

        if (data) {
          formRef.current.setFieldsValue({
            duration: parseInt(data.duration),
            status:
              data.status === 1 && !data.has_expired
                ? statusOption[0].value
                : statusOption[1].value
          });

          setDetail(data);
        }

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "exchange_rate_settings"
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  const goBack = () => {
    window.history.go(-1);
  };

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  const durations = [
    { value: 5, label: "5 Minutes" },
    { value: 120, label: "2 Hours" },
    { value: 240, label: "4 Hours" },
    { value: 360, label: "6 Hours" },
    { value: 480, label: "8 Hours" },
    { value: 600, label: "10 Hours" },
    { value: 720, label: "12 Hours" },
    { value: 1320, label: "22 Hours" },
    { value: 1440, label: "24 Hours" }
  ];

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">
            {detail.has_expired ? "View" : "Edit"} Manual Cost Exchange Rate
          </div>
        </Row>
        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          // style={{
          //   maxWidth: 1000,
          // }}
          initialValues={{
            remember: true
          }}
          onFinish={(values) => {
            updateExchangeRateSetting(values);
          }}
          className="form-details"
        >
          <Form.Item name="status" label="Status">
            <Select
              name="status"
              options={statusOption}
              defaultValue={"Active"}
              disabled={detail.has_expired}
            ></Select>
          </Form.Item>

          <Form.Item name="id" label="ID" className="label-details">
            <div>{detail.id}</div>
          </Form.Item>

          <Form.Item
            name="from_currency"
            label="From Currency"
            className="label-details"
          >
            <div>{detail.from_currency?.iso_code}</div>
          </Form.Item>

          <Form.Item
            name="to_currency"
            label="To Currency"
            className="label-details"
          >
            <div>{detail.to_currency?.iso_code}</div>
          </Form.Item>

          <Form.Item name="rate" label="Rate" className="label-details">
            <div>{detail.exchange_rate?.rate}</div>
          </Form.Item>

          <Form.Item name="duration" label="Duration">
            <Select
              name="duration"
              options={durations}
              disabled={detail.has_expired}
            ></Select>
          </Form.Item>

          <Form.Item
            name="effective_date"
            label="Effective Date"
            className="label-details"
          >
            <div>
              {moment
                .utc(detail.created_at)
                .tz("Asia/Phnom_Penh")
                .format("DD-MM-YYYY, hh:mm:ss A")}
            </div>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16
            }}
          >
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Space>
                  <Button onClick={goBack}>
                    {detail.has_expired ? "Close" : "Cancel"}
                  </Button>
                  {!detail.has_expired && (
                    <Button
                      type="default"
                      htmlType="submit"
                      className="ant-btn-green"
                    >
                      Save
                    </Button>
                  )}
                </Space>
              </div>
            </Row>
          </Form.Item>
        </Form>

        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default ExchangeRateSettingEdit;
