import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";

const initialState = {};

export const addBusinessType = createAsyncThunk(
  "business-type/addBusinessType",
  async (values) => {
    const { name, content } = values;
    try {
      let formData = new FormData();

      formData.append("name", name.trim());

      for (let i = 0; i < content.length; i++) {
        const { language, content: contentValue } = content[i];
        formData.append(`content[${i}][language]`, language);
        formData.append(`content[${i}][content]`, contentValue);
      }

      const { data } = await axios.post("business-type/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return { data };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getBusinessTypeById = createAsyncThunk(
  "business-type/getBusinessTypeById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`business-type/${id}`);
      return {
        data,
      };
    } catch (error) {
      throw error.generateErrorString(error);
    }
  }
);

export const updateBusinessTypeById = createAsyncThunk(
  "business-type/updateBusinessTypeById",
  async ({ id, params }) => {
    try {
      let formData = {
        name: params.name,
        content: params.content,
      };

      const { data } = await axios.patch(`business-type/${id}`, formData);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retriveBusinessTypes = createAsyncThunk(
  "business-type/retriveBusinessTypes",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("business-type/all", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
