import React from "react";
import ReactEcharts from "echarts-for-react";
import PropTypes from "prop-types";

// ============
import styles from "./styles.module.less";
import { useEffect } from "react";

const PieChart = ({ title, data, style }) => {
  useEffect(() => {
    console.log(data);
  }, [data]);
  const option = {
    tooltip: {
      trigger: "item",
    },

    legend: {
      top: "5%",
      left: "left",
      orient: "vertical",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        color: ["#FF6B45", "#47B39C"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            formatter: "{b|{b}} \n {d}%",
            show: true,
            fontSize: 18,
            fontWeight: "bold",
            rich: {
              b: {
                lineHeight: 24,
              },
            },
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  return (
    <>
      <div className={styles.chart_title}>{title}</div>
      <ReactEcharts style={style} option={option} />
    </>
  );
};

PieChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  style: PropTypes.object,
};

export default PieChart;
