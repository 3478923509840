import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  Menu,
  Dropdown,
  Button,
  Modal,
  message,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  EllipsisOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { retrieveAllCurrencies, deleteCurrency } from "../../store/currency";

const Currency = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const dispatch = useDispatch();
  const [isEnableDelete, setIsEnableDelete] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedDeleteCurrencyId, setSelectedDeleteCurrencyId] =
    useState(null);

  useEffect(() => {
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isEnableDelete]);

  const deleteOnClick = async (item) => {
    setIsConfirmModalVisible(true);
    setSelectedDeleteCurrencyId(item.id);
    setIsEnableDelete(false);
  };

  const editOnClick = (item) => {
    console.log("edit");
    console.log(item);
    // navigate("/currency-add")

    navigate(`/currency/${item.id}`);
  };

  const deleteSelectedCurrency = async () => {
    try {
      await dispatch(
        deleteCurrency({
          id: selectedDeleteCurrencyId,
        })
      ).unwrap();

      setIsConfirmModalVisible(false);
      message.success("You have successfully deleted the currency");

      // setTimeout(() => {
      setIsEnableDelete(true);
      // }, 1000);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatch(retrieveAllCurrencies()).unwrap();
      
      const { data: currencyData, total } = data;
      console.log(currencyData);
      setTableData(currencyData);
      setTableTotalData(total);

      dispatch(resetTableSearch());
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(
          setTableSearch({
            search: text,
          })
        );
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      align: "center",
      key: "id",
      sorter: true,
      render: (_, record) => {
        return <div>{record.id}</div>;
      },
    },

    {
      title: "Currency Name",
      dataIndex: "currency_name",
      align: "center",
      width: "50%",
      key: "currency_name",
      sorter: true,
      render: (_, record) => {
        return <div>{record.currency_name}</div>;
      },
    },
    // May add currency symbol
    {
      title: "Currency Code",
      dataIndex: "country_code",
      width: "20%",
      align: "center",
      key: "country_code",
      render: (_, record) => {
        return <div>{record.currency_iso}</div>;
      },
    },

    {
      title: "Minimum Amount",
      dataIndex: "minimum_amount",
      width: "20%",
      align: "center",
      key: "minimum_amount",
      render: (_, record) => {
        return <div>{record.minimum_amount}</div>;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "left",
      width: "10%",
      key: "action",
      render: (_, record) => {
        return (
          <div>
            <Tooltip>
              <EditOutlined
                style={{
                  fontSize: "15px",
                  color: "#4361ee",
                  marginRight: "10px",
                }}
                onClick={() => editOnClick(record)}
              />
              <DeleteOutlined
                style={{ fontSize: "15px", color: "#FF0000" }}
                onClick={() => deleteOnClick(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let key = sorter.columnKey;
      let field = sorter.field;

      if (key == "currency_name") {
        field = "currency_name";
      }

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row Row justify="space-between" align="middle">
          <Row align="middle">
            <div className="table_search_input">
              <Input
                placeholder="Search..."
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
                onChange={(e) => {
                  onChangeText(e.target.value);
                }}
              />
            </div>
          </Row>

          {/* For current stage, need to hide add currency function and only allow to manage currency in database*/}
          <Row justify="end">
            <div className={styles.secondary_btn}>
              <Button
                className="secondary_btn"
                onClick={() => navigate("/currency/add")}
                icon={<PlusOutlined />}
              >
                Create
              </Button>
            </div>
          </Row>
        </Row>
      </div>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
        }}
      />

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={isConfirmModalVisible}
        closable={false}
        onOk={deleteSelectedCurrency}
        onCancel={() => {
          setIsConfirmModalVisible(false);
        }}
        okText="Confirm"
        cancelButtonProps={{
          style: { display: "inline-block" },
        }}
        okButtonProps={{
          style: { display: "inline-block" },
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 14, marginBottom: 18 }}>
          Do you confirm to delete this currency?
        </div>
      </Modal>
    </div>
  );
};

export default Currency;
