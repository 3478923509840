import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { Card, Form, Row, Button, message, Select, Input, Divider } from "antd";
import { map } from "lodash";
// ============
import styles from "./styles.module.less";
import {
  findCommunicationChannelById,
  updateCommunicationChannelById,
} from "../../../store/communication-channel";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
import HistoryTable from "../../../components/history-table";

const { Option } = Select;

const CommunicationChannelEdit = () => {
  const { params } = useMatch("settings/communication-channel/:id");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [historyData, setHistoryData] = useState([]);
  const [isUrl, setIsUrl] = useState();
  const [isEmail, setIsEmail] = useState();

  const formRef = useRef();

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const typeOption = [
    {
      value: "website",
      label: "Website",
    },
    {
      value: "email",
      label: "Email",
    },

    // Live Chat or any action for navigation
    {
      value: "other",
      label: "Other",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: communicationChannelData } = await dispatch(
          findCommunicationChannelById({
            id: params.id,
          })
        ).unwrap();

        const descriptions = communicationChannelData.translation.reduce(
          (text, translation) => {
            text[translation.language_id] = translation.description_content;

            return text;
          },
          {}
        );

        formRef.current.setFieldsValue({
          name: communicationChannelData.communication_channel.name,
          description: descriptions[1],
          kmDescription: descriptions[2],
          zhDescription: descriptions[3],
          thDescription: descriptions[4],
          type: communicationChannelData.communication_channel.type
            ? communicationChannelData.communication_channel.type
            : null,
          url: communicationChannelData.communication_channel.url
            ? communicationChannelData.communication_channel.url
            : null,
          email: communicationChannelData.communication_channel.email,

          is_enabled: communicationChannelData.communication_channel.is_enabled,
        });

        if (
          communicationChannelData?.communication_channel.url !== null &&
          communicationChannelData?.communication_channel.email === null
        ) {
          setIsUrl(true);
        } else if (
          communicationChannelData?.communication_channel.email !== null &&
          communicationChannelData?.communication_channel.url === null
        ) {
          setIsEmail(true);
        } else {
          setIsUrl(false);
          setIsEmail(false);
        }

        // Retrieve history data
        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "communication_channels",
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const descriptionArray = [];

        if (values.description) {
          descriptionArray.push({
            language: "en",
            description: values.description,
          });
        }

        const languages = ["km", "zh", "th"];

        languages.forEach((language) => {
          if (values[`${language}Description`]) {
            descriptionArray.push({
              language,
              description:
                values[`${language}Description`] === null
                  ? ""
                  : values[`${language}Description`],
            });
          } else {
            descriptionArray.push({
              language,
              description: "",
            });
          }
        });

        const { data: res } = await dispatch(
          updateCommunicationChannelById({
            id: params.id,
            params: {
              name: values.name,
              // description: values.description,
              description: descriptionArray,
              type: values.type,
              url: values.url,
              email: values.email,
              is_enabled: values.is_enabled,
            },
          })
        ).unwrap();

        message.success(res.message);
        navigate(-1);
      } else {
        console.error("Some required values are missing.");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Edit Communication Channel</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            label="Channel Name"
            name="name"
            className="input"
            rules={[
              {
                required: true,
                message: "Channel Name cannot be blank",
              },
            ]}
          >
            <Input placeholder="Channel Name" disabled />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            className="input"
            extra={
              <div className="rules">
                Maximum 30 characters, this will appear as a title in App
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please enter the description",
              },
              {
                required: true,
                validator: async (_, value) => {
                  if (value) {
                    if (value.length > 30) {
                      throw new Error(
                        "The length should not exceeding 30 characters"
                      );
                    }
                  }
                },
              },
              {
                pattern: /^[a-zA-Z,_. ]*$/,
                message: "Please insert valid description",
              },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item label="Khmer" name="kmDescription" className="input">
            <Input placeholder="ការពិពណ៌នា" />
          </Form.Item>
          <Form.Item label="Chinese" name="zhDescription" className="input">
            <Input placeholder="描述" />
          </Form.Item>
          <Form.Item label="Thai" name="thDescription" className="input">
            <Input placeholder="คำอธิบาย" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                validator: async (_, value) => {
                  if (value === "website") {
                    setIsUrl(true);
                    setIsEmail(false);
                    formRef.current.setFieldsValue({
                      email: null,
                    });
                  } else if (value === "email") {
                    setIsUrl(false);
                    setIsEmail(true);
                    formRef.current.setFieldsValue({
                      url: null,
                    });
                  } else {
                    setIsUrl(false);
                    setIsEmail(false);
                    formRef.current.setFieldsValue({
                      url: null,
                      email: null,
                    });
                  }
                },
              },
            ]}
          >
            <Select name="type">
              {map(typeOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {console.log(`isUrl : ${isUrl} | isEmail : ${isEmail}`)}

          {!isUrl && !isEmail ? null : isUrl && !isEmail ? (
            <Form.Item
              label="URL Link"
              name="url"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the URL link",
                },
              ]}
            >
              <Input placeholder="URL" />
            </Form.Item>
          ) : (
            <Form.Item
              label="Email Address"
              name="email"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Email Address cannot be blank",
                },
                {
                  pattern: /^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})$/,
                  message: "Please insert valid email address",
                },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
          )}

          <Form.Item name="is_enabled" label="Status">
            <Select name="is_enabled">
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default CommunicationChannelEdit;
