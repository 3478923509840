import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
// ============
import styles from "./styles.module.less";
import { updateBank } from "@store/bank";
import HistoryTable from "../../../components/history-table";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
import { retrieveAllCurrencies } from "../../../store/currency";
import { filter, map } from "lodash";

const BankEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const { params } = useMatch("settings/bank/:id");
  const selectedBank = useSelector((state) => state.bank.selectedBank);

  const [historyData, setHistoryData] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data: currencies } = await dispatch(
      retrieveAllCurrencies()
    ).unwrap();

    const mappedCurrencies = map(currencies, (item) => {
      return {
        id: item.id,
        currency_name: item.currency_name,
        iso_code: item.iso_code
      };
    });

    setCurrencyList(mappedCurrencies);

    formRef.current.setFieldsValue({
      bank_name: selectedBank.bank_name,
      currency: selectedBank.currency
    });

    // let selectedCurrency = selectedBank.currency;
    // const mappedSelectedCurrency = filter(mappedCurrencies, (item) => {
    //   if (selectedCurrency.includes(item.id)) {
    //     return item;
    //   }
    // });
    // setCurrency(mappedSelectedCurrency);

    const { data: historyData } = await dispatch(
      findActivityLogsByRefIdAndRefTable({
        reference_id: params.id,
        reference_table: "banks"
      })
    ).unwrap();

    setHistoryData(historyData);
  };

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const { data } = await dispatch(
          updateBank({
            id: params.id,
            bank_name: values.bank_name,
            currencies: values.currency
          })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">Edit Bank</div>
        </Row>
        <Form
          ref={formRef}
          colon={false}
          initialValues={{}}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          // requiredMark={false}
          className="form-details"
        >
          <Form.Item
            name="bank_name"
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Please enter the bank name"
              }
            ]}
          >
            <Input placeholder="Bank name" />
          </Form.Item>

          <Form.Item
            name="currency"
            label={<div className={styles.label}>Currencies</div>}
            rules={[
              {
                required: true,
                message: "Please select at least one currency"
              }
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Select Currency"
            >
              {map(currencyList, (item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate("/settings/bank")}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button htmlType="submit" className="ant-btn-green">
                Save
              </Button>
            </div>
          </Row>
        </Form>

        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default BankEdit;
