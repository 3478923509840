import React, { useEffect, useState, useCallback } from "react";
import { Col, Input, Table, message, DatePicker, Select, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { map, startCase } from "lodash";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findUsersMobileSecureCode } from "@store/user";
import TableHeader from "@components/table-header";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@configs/can";
const { Option } = Select;

const MobileOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    setInterval(() => {
      fetchData();
    }, 60000);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (filter != null) {
        filter["localTimeOffset"] = localTimeOffset;
      }
      const { data } = await dispatch(
        findUsersMobileSecureCode({ filter })
      ).unwrap();

      const { data: userData, total } = data;

      setTableData(userData);
      setTableTotalData(total);
      console.log(userData);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const columns = [
    {
      title: "Mobile",
      dataIndex: "phone_no",
      align: "left",
      fixed: "left",
      width: 120
    },

    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      width: 100
    },

    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      width: 200
    },

    {
      title: "Secure Code",
      dataIndex: "otp_code",
      align: "left",
      width: 140
    },

    {
      title: "Creation Time",
      dataIndex: "created_at",
      align: "left",
      width: 140,
      sorter: true,
      render: (_, record) => {
        const { created_at, updated_at } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {updated_at != null
              ? moment
                  .utc(updated_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      }
    },

    {
      title: "Usage",
      dataIndex: "purpose",
      align: "left",
      width: 140,
      render: (_, record) => {
        const { purpose } = record;
        return (
          <div style={{ textAlign: "left" }}>{startCase(purpose) ?? "-"}</div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Mobile Secure Code"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        createOnClick={() => navigate("/customer/add")}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"phone_no"} initialValue={null}>
              <Input
                placeholder="Mobile"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name={"username"} initialValue={null}>
              <Input
                placeholder="Username"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name={"email"} initialValue={null}>
              <Input
                placeholder="Email"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default MobileOTP;
