import React, { useRef, useState, useEffect } from "react";
import { Button, Row, Input, Form, message, Card, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { createExchangeMarginRate } from "@store/exchange";
import {
  retrieveAllCurrencies,
  getCurrencyById,
} from "../../../store/currency";
import { map, groupBy } from "lodash";
import { capitalCase } from "change-case";

const { Option } = Select;

const ExchangeRateMarginAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [fromCurrencyIsRequired, setFromCurrencyIsRequired] = useState(false);
  const [toCurrencyIsRequired, setToCurrencyIsRequired] = useState(false);
  const [groupedCurrencies, setGroupedCurrencies] = useState([]);

  const [optionFromCurrency, setOptionFromCurrency] = useState(0);
  const [optionToCurrency, setOptionToCurrency] = useState(0);
  const [fromCurrency, setFromCurrency] = useState([]);
  const [toCurrency, setToCurrency] = useState([]);
  const currencies = useSelector((state) => state.currency?.currencies);

  const typeOption = [
    { value: "normal", label: "Normal" },
    { value: "crypto", label: "Crypto" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;

        setCurrency(currencyData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (currencies) {
      const groupCurrencies = groupBy(currencies, "type");

      let finalCurrencies = [];
      for (const key in groupCurrencies) {
        finalCurrencies = [
          ...finalCurrencies,
          {
            label: capitalCase(key),
            options: map(groupCurrencies[key], (item) => {
              const { id, iso_code } = item;

              return {
                label: item.iso_code,
                value: item.id,
              };
            }),
          },
        ];
      }

      setGroupedCurrencies(finalCurrencies);

      setOptionFromCurrency(groupedCurrencies);
      let optionTo = remappedCurrencyList(fromCurrency);
      setOptionToCurrency(optionTo);
    }
  }, [currencies]);

  const onFinishAddMargin = async (values) => {
    try {
      const { data } = await dispatch(
        createExchangeMarginRate(values)
      ).unwrap();
      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  // const currencyList = () => {
  //   let option = map(currencies, (item) => {
  //     return {
  //       label: item.iso_code,
  //       value: item.id
  //     };
  //   });

  //   setOptionFromCurrency(option);
  //   let optionTo = remappedCurrencyList(fromCurrency);
  //   setOptionToCurrency(optionTo);
  // };

  const handleToCurrency = (value) => {
    setToCurrency(value);
    let option = remappedCurrencyList(value);
    setOptionFromCurrency(option);

    if (value) {
      setToCurrencyIsRequired(true);
    } else {
      setToCurrencyIsRequired(false);
    }
  };

  const handleFromCurrency = (value) => {
    setFromCurrency(value);
    let option = remappedCurrencyList(value);
    setOptionToCurrency(option);

    if (value) {
      setFromCurrencyIsRequired(true);
    } else {
      setFromCurrencyIsRequired(false);
    }
  };

  const remappedCurrencyList = (values) => {
    const groupCurrencies = groupBy(currencies, "type");

    let finalCurrencies = [];
    for (const key in groupCurrencies) {
      finalCurrencies = [
        ...finalCurrencies,
        {
          label: capitalCase(key),
          options: map(groupCurrencies[key], (item) => {
            const { id, iso_code } = item;

            if (values === item.id) {
              return {
                label: item.iso_code,
                value: item.id,
                disabled: true,
              };
            } else {
              return {
                label: item.iso_code,
                value: item.id,
              };
            }
          }),
        },
      ];
    }

    // let option = map(currencies, (item) => {
    //   if (values === item.id) {
    //     return {
    //       label: item.iso_code,
    //       value: item.id,
    //       disabled: true
    //     };
    //   } else {
    //     return {
    //       label: item.iso_code,
    //       value: item.id
    //     };
    //   }
    // });

    return finalCurrencies;
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Margin</div>
        </Row>

        <Form
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishAddMargin}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            label="Margin"
            name="margin"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the margin",
              },

              ({}) => ({
                validator(_, value) {
                  if (value === null || value === undefined || value === "") {
                    return Promise.resolve();
                  } else if (isNaN(value) || isNaN(parseInt(value))) {
                    return Promise.reject(new Error("Margin must be a number"));
                  } else if (value < 0 || value > 10 || value === "-0") {
                    return Promise.reject(
                      new Error("Margin must be between 0 - 10")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input placeholder="Margin" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Margin Type"
            rules={[
              {
                required: true,
                message: "Please select the margin type",
              },
            ]}
          >
            <Select
              name="type"
              placeholder="Select Margin Type"
              // onChange={(values) => handleMarginType(values)}
            >
              {map(typeOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="from_currency_id"
            label="From Currency"
            rules={[
              {
                required: toCurrencyIsRequired,
                message: "Please select From Currency",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Select From Currency"
              options={optionFromCurrency}
              onChange={(values) => handleFromCurrency(values)}
              showArrow
            />
          </Form.Item>

          <Form.Item
            name="to_currency_id"
            label="To Currency"
            rules={[
              {
                required: fromCurrencyIsRequired,
                message: "Please select To Currency",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Select To Currency"
              options={optionToCurrency}
              onChange={(values) => handleToCurrency(values)}
              showArrow
            />
          </Form.Item>

          {/* <Form.Item
            name="To Currency"
            // value={selectedCurrency}
            label="To Currency"
            rules={[
              {
                required: toCurrencyIsRequired
              }
            ]}
          >
            <Select
              name="to_currency_id"
              allowClear
              onChange={toCurrencyOnChange}
              placeholder="Select Currency"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ExchangeRateMarginAdd;
