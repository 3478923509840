import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card, Form, Row, Col, Button, message, Select, Input } from "antd";
import { find, map, isEmpty, filter } from "lodash";
import { unformat, formatNumber } from "accounting-js";
import numeral from "numeral";
// ============
import styles from "./styles.module.less";
import {
  retrieveAllCurrencies,
  getCurrencyById
} from "../../../store/currency";
import { getAllBanks, createBankAccount } from "../../../store/bank";
const { Option } = Select;

const BankAccountsAddAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [bank, setBank] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const country = useSelector((state) =>
    filter(state.country.allCountries, (item) => item.is_enabled == 1)
  );

  const formRef = useRef();

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  useEffect(() => {
    // set Status initial value
    formRef.current.setFieldsValue({
      status: statusOption[0].value
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve banks
        const bankPayload = await dispatch(getAllBanks()).unwrap();
        const { data: bankData } = bankPayload;
        setBank(bankData);

        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;

        setCurrency(currencyData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        console.log(values);
        let minimum_amount =
          unformat(values.minimum_amount.replace(/(\.|\,)/gi, "")) / 100;

        let maximum_amount =
          unformat(values.maximum_amount.replace(/(\.|\,)/gi, "")) / 100;
        await dispatch(
          createBankAccount({
            status: values.status,
            country_id: values.country,
            currency_id: values.currency_id,
            bank_id: values.bank_id,
            acc_no: values.account_no,
            acc_name: values.account_name,
            minimum_amount,
            maximum_amount
          })
        ).unwrap();

        message.success("You have successfully added new bank account");
        navigate(-1);
      } else {
        console.error("Some required values are missing.");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const minAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const minimum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(minimum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      minimum_amount: formattedAmount
    });
  });

  const maxAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const maximum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(maximum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      maximum_amount: formattedAmount
    });
  });

  const accountNumberOnChange = async ({ target }) => {
    const { value } = target;

    let text = value;

    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/g, "");
    formRef.current.setFieldValue("account_no", formattedPhoneNumber);
  };

  const currencyOnChange = async (id) => {
    const { data: currencyDetails } = await dispatch(
      getCurrencyById({
        id
      })
    ).unwrap();

    setSelectedCurrency(currencyDetails);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Bank Account</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item name="status" label="Status">
            <Select name="status">
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: "Please select a country"
              }
            ]}
          >
            <Select
              name="country"
              placeholder="Select Country"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(country, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="currency_id"
            // value={selectedCurrency}

            label="Currency"
            rules={[
              {
                required: true,
                message: "Please select a currency"
              }
            ]}
          >
            <Select
              name="currency_id"
              // value={selectedCurrency}
              onChange={currencyOnChange}
              placeholder="Select Currency"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="bank_id"
            label="Bank"
            rules={[
              {
                required: true,
                message: "Please select a bank"
              }
            ]}
          >
            <Select
              name="bank"
              placeholder="Select Bank"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(bank, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Account No."
            name="account_no"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the account no.",
                validator: async (_, value) => {
                  let text = value;
                  if (text) {
                    if (typeof text === "number") {
                      text = value.toString();
                    }
                  } else {
                    throw new Error("Please enter a valid account no");
                  }
                }
              },
              {
                pattern: /^[0-9]{1,34}$/i,
                message: "Account no. should not more than 34 digits"
              }
            ]}
          >
            <Input placeholder="Account No." onChange={accountNumberOnChange} />
          </Form.Item>

          <Form.Item
            label="Account Name"
            name="account_name"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the account name"
              }
            ]}
          >
            <Input placeholder="Account Name" />
          </Form.Item>
          <Form.Item
            label="Min Amount"
            name="minimum_amount"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the min amount"
              },
              {
                required: true,
                validator: async (_, value) => {
                  value = value ? unformat(value.replace(/(\,)/g, "")) : "";

                  if (value === 0) {
                    throw new Error("Please enter the min amount");
                  } else if (
                    parseFloat(value) <
                    numeral(
                      parseFloat(selectedCurrency?.minimum_amount)
                    ).format("0.00")
                  ) {
                    throw new Error(
                      `Minimum amount is ${numeral(
                        parseFloat(selectedCurrency?.minimum_amount)
                      ).format("0,0.00")} ${selectedCurrency?.iso_code}`
                    );
                  }
                }
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={(e) => {
                minAmountOnChange(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Max Amount"
            name="maximum_amount"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the max amount"
              },
              {
                validator: async (_, value) => {
                  let minAmount =
                    formRef.current.getFieldValue("minimum_amount");
                  minAmount = unformat(minAmount?.replace(/(\,)/g, ""));

                  value = value ? unformat(value.replace(/(\,)/g, "")) : null;

                  if (value === 0) {
                    throw new Error("Please enter the max amount");
                  } else if (parseFloat(value) <= minAmount) {
                    throw new Error(
                      "Max amount must be higher than min amount"
                    );
                  }
                }
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={(e) => {
                maxAmountOnChange(e.target.value);
              }}
            />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BankAccountsAddAccount;
