import React from "react";
import { Row, Col, Card, Form, Input, Checkbox, Button } from "antd";
import {
  FiFacebook,
  FiUser,
  FiLock,
  FiEye,
  FiEyeOff,
  FiAtSign,
} from "react-icons/fi";
import { Link } from "react-router-dom";
//====
import styles from "./styles.module.less";

const SignUp = () => {
  const onFinish = (values) => {};

  return (
    <div>
      <br />
      <br />

      <Row className={styles.signup_container}>
        <Card className={styles.signup_card}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className={styles.signup_title}>Register</div>
            </Col>
            <Col span={24}>
              <div className={styles.signup_desc}>
                Already have an account?{" "}
                <Link to="/signin" className={styles.signup_login_link}>
                  Log in
                </Link>
              </div>
            </Col>
          </Row>

          <Form
            name="control-hooks"
            layout="vertical"
            requiredMark="optional"
            onFinish={onFinish}
          >
            <Form.Item
              name="fullname"
              label="FULL NAME"
              className={styles.signup_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your full name",
                },
              ]}
            >
              <Input
                prefix={<FiUser className="grey_fill_icon" />}
                placeholder="Full Name"
              />
            </Form.Item>

            <Form.Item
              name="username"
              label="USERNAME"
              className={styles.signup_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your username",
                },
              ]}
            >
              <Input
                prefix={<FiUser className="grey_fill_icon" />}
                placeholder="Username"
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="EMAIL"
              className={styles.signup_form_item}
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            >
              <Input
                prefix={<FiAtSign className="grey_fill_icon" />}
                placeholder="Email"
              />
            </Form.Item>

            <Link
              to="/forgot-password"
              className={styles.signup_forgot_password_link}
            >
              Forgot Password?
            </Link>

            <Form.Item
              name="password"
              label="PASSWORD"
              className={styles.signup_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  min: 8,
                  message: "Password must have a minimum length of 8",
                },
                {
                  pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$",
                  message:
                    "Password must have at least one uppercase letter, one lowercase letter and one number",
                },
              ]}
            >
              <Input.Password
                prefix={<FiLock className="grey_fill_icon" />}
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 18,
                    width: 18,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              name="tnc"
              valuePropName="checked"
              className={styles.signup_form_item}
              rules={[
                {
                  required: true,
                  message: "You should accept terms and conditions",
                },
              ]}
            >
              <Checkbox>
                <span className={styles.signup_tnc}>
                  I agree to the <a href="/">terms and conditions</a>
                </span>
              </Checkbox>
            </Form.Item>

            <Button type="default" htmlType="submit" className="secondary_btn">
              Get Started!
            </Button>
          </Form>

          <Row
            className={styles.signup_divider}
            align="middle"
            justify="center"
          >
            <span>OR</span>
          </Row>

          <Row align="middle" justify="center">
            <Button type="default" className={styles.signup_social_btn}>
              <FiFacebook className={styles.signup_social_icon} />
              <span>Facebook</span>
            </Button>
          </Row>
        </Card>
      </Row>

      <br />
      <br />
      <br />
    </div>
  );
};

export default SignUp;
