import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Form, Input, Row, Button, message } from "antd";
import { useMatch } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { createBankAccount } from "../../store/bank";

const BanksByCountryAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { state: bankAccountData } = location;
  const { params } = useMatch("banks-by-country-add/:id");

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const { data } = await dispatch(
          createBankAccount({
            acc_name: values.account_name,
            acc_no: values.account_no,
            bank_country_id: params.id,
            country_id: bankAccountData.bank_account_country_id,
            currency_id: bankAccountData.country_currency_id,
          })
        ).unwrap();

        message.success("You have successfully added new bank account");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>ADD NEW BANK ACCOUNTS</div>
        </Row>
        <br />
        <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
          <div className={styles.input}>
            <Form.Item
              name="account_no"
              label={<div className={styles.label}>Account No.</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the account number",
                },
              ]}
            >
              <Input placeholder="Account No." />
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="account_name"
              label={<div className={styles.label}>Account Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the account name",
                },
              ]}
            >
              <Input placeholder="Account Name" />
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Add
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BanksByCountryAdd;
