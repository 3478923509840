import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Row, Button, Alert, message, Select } from "antd";
import { map } from "lodash";
// ============
import styles from "./styles.module.less";
import { createCryptoProvider } from "../../../store/crypto";
import { getAllCountries } from "@store/country";
import {
  retrieveAllCurrencies,
  getCurrencyById
} from "../../../store/currency";
const { Option } = Select;

const CryptoProviderAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [currency, setCurrency] = useState([]);
  const currencies = useSelector((state) => state.currency.currencies);
  const [selectedCurrency, setSelectedCurrency] = useState();

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {}, [countryList]);
  const fetchData = async () => {
    try {
      // Retrieve currencies
      const currency = await dispatch(retrieveAllCurrencies()).unwrap();
      const { data: currencyData } = currency;

      setCurrency(currencyData);
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = async (values) => {
    if (values) {
      try {
        const { data } = await dispatch(createCryptoProvider(values)).unwrap();

        message.success(data.message);
        navigate("/settings/crypto-provider");
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const currencyOnChange = async (id) => {
    const { data: currencyDetails } = await dispatch(
      getCurrencyById({
        id
      })
    ).unwrap();

    setSelectedCurrency(currencyDetails);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Crypto Provider</div>
        </Row>
        <Form
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="provider_name"
            className="input"
            label="Provider Name"
            rules={[
              {
                required: true,
                message: "Please enter the provider name"
              }
            ]}
          >
            <Input placeholder="Provider name" />
          </Form.Item>

          {/* <Form.Item
            name="currency_id"
            // value={selectedCurrency}
             
            label="Currency"
            rules={[
              {
                required: true,
                message: "Currency cannot be empty",
              },
            ]}
          >
            <Select
              name="currency_id"
              // value={selectedCurrency}
              onChange={currencyOnChange}
              placeholder="Select Currency"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CryptoProviderAdd;
