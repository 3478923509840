import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { Card, Form, Input, Row, Button, message } from "antd";
// ============
import styles from "./styles.module.less";
import { updateBank } from "../../store/bank";

const BankAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params } = useMatch("bank/:id");
  const selectedBank = useSelector((state) => state.bank.selectedBank);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const { data } = await dispatch(
          updateBank({
            id: params.id,
            bank_name: values.bank_name,
          })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>BANK INFORMATION</div>
        </Row>
        <br />
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
          initialValues={{
            bank_name: selectedBank,
          }}
        >
          <div className={styles.input}>
            <Form.Item
              name="bank_name"
              label={<div className={styles.label}>Bank Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the bank name",
                },
              ]}
            >
              <Input placeholder="Bank name" />
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BankAdd;
