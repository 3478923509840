import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Row, Button, Alert, message, Select } from "antd";
// ============
import styles from "./styles.module.less";
import { addBank } from "../../store/bank";
import { getAllCountries } from "../../store/country";

const BankAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {}, [countryList]);
  const fetchData = async () => {
    try {
      const { data } = await dispatch(getAllCountries()).unwrap();
      setCountryList(data);
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = async (values) => {
    if (values) {
      try {
        const { data } = await dispatch(addBank(values)).unwrap();

        message.success(data.message);
        navigate("/bank");
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>CREATE BANK</div>
        </Row>
        <br />
        <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
          <div className={styles.input}>
            {/* <Form.Item
              name="bank_country"
              label={<div className={styles.label}>Select Bank Country</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the bank name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "55%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={countryList.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
              />
            </Form.Item> */}
            {/* <Form.Item
              name="branch_name"
              label={<div className={styles.label}>Branch Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the Branch name",
                },
              ]}
            >
              <Input placeholder="Branch name" />
            </Form.Item> */}
            <Form.Item
              name="bank_name"
              label={<div className={styles.label}>Bank Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the bank name",
                },
              ]}
            >
              <Input placeholder="Bank name" />
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate("/bank-accounts")}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BankAdd;
