import { Navigate } from "react-router-dom";
//====
import Home from "../pages/home";
import PendingTransfer from "../pages/transfer";
import TransferHistory from "../pages/transfer/history";
import PendingReload from "../pages/reload";
import ReloadHistory from "../pages/reload/history";
import Redeem from "../pages/redeem";
import Profile from "../pages/profile";
// import User from "../pages/user";
// import UserEdit from "../pages/user/edit";
import SignUp from "../pages/signup";
import SignIn from "../pages/signin";
import ForgotPassword from "../pages/forgot-password";
import ResetPassword from "../pages/reset-password";
import FirstLogin from "../pages/new-ui/first-login";
import TwoFactorAuthentication from "../pages/tfa";
import ReloadEdit from "../pages/reload/edit";
import TransferEdit from "../pages/transfer/edit";
import ExchangeRate from "../pages/exchange-rate";
import ExchangeRateAdd from "../pages/exchange-rate/add";
import ExchangeRateEdit from "../pages/exchange-rate/edit";
import Currency from "../pages/currency";
import CurrencyAdd from "../pages/currency/add";
import CurrencyEdit from "../pages/currency/edit";
import Country from "../pages/country";
import CountryEdit from "../pages/country/edit";
import Bank from "../pages/bank";
import BankAccounts from "../pages/bank-accounts";
import BankAccountsAddAccount from "../pages/bank-accounts/add-account";
import BankAccountsEditAccount from "../pages/bank-accounts/edit-account";
import BanksByCountry from "../pages/banks-by-country";
import BanksByCountryAdd from "../pages/banks-by-country/add";
import BanksByCountryEdit from "../pages/banks-by-country/edit";
import BanksByCountryAddBranch from "../pages/banks-by-country/add-branch";
import BanksByCountryEditBranch from "../pages/banks-by-country/edit-branch";
import BankAdd from "../pages/bank/add";
import NewBankAdd from "../pages/new-ui/bank/add";
import NewBankEdit from "../pages/new-ui/bank/edit";
import ChangePassword from "../pages/new-ui/change-password";

import BankEdit from "../pages/bank/edit";
import DashboardUser from "../pages/dashboard-user";
import DashboardUserAdd from "../pages/dashboard-user/add";
import DashboardUserEdit from "../pages/dashboard-user/edit";
import GeneralSetting from "../pages/general-setting";
import DownlineTree from "../pages/user/downline-tree";
import BaseFixedRate from "../pages/base-fixed-rate";
import Transaction from "../pages/transaction";
// import AllTransaction from "../pages/transaction/updated-index";
import AllTransaction from "../pages/new-ui/transaction/index";

// New UI pages
import CostExchangeRate from "../pages/new-ui/cost-exchange-rate/index";

import ManualCostExchangeRate from "../pages/new-ui/cost-exchange-rate/manual/index";
import ManualCostExchangeRateEdit from "../pages/new-ui/cost-exchange-rate/manual/edit";
import ManualCostExchangeRateAdd from "../pages/new-ui/cost-exchange-rate/manual/add";

import BaseFlatRate from "../pages/new-ui/fee/index";
import BaseFlatRateAdd from "../pages/new-ui/fee/add";

import CustomerServiceUser from "../pages/new-ui/customer-service-user/index";
import CustomerServiceUserAdd from "../pages/new-ui/customer-service-user/add";
import CustomerServiceUserEdit from "../pages/new-ui/customer-service-user/edit";

// import Commission from "../pages/new-ui/commission/index";
import CommissionLog from "../pages/new-ui/commission-log/index";

//Kyc
import MemberKyc from "../pages/new-ui/kyc/index";

import Member from "../pages/new-ui/member/index";
import MemberEdit from "../pages/new-ui/member/edit";

import MobileOTP from "../pages/new-ui/mobile-otp/index";
import WalletBalance from "../pages/new-ui/wallet-balance/index";

import TopUpBankDeposit from "../pages/new-ui/top-up/bank";
import TopUpBankDepositEdit from "../pages/new-ui/top-up/bank/edit";
import TopUpBankDepositPending from "../pages/new-ui/top-up/bank/pending";
import NewReloadEdit from "../pages/new-ui/top-up/bank/updated-edit";

import SendBankDeposit from "../pages/new-ui/send/bank/index";
import SendBankDepositPending from "../pages/new-ui/send/bank/pending";
import SendBankDepositEdit from "../pages/new-ui/send/bank/edit";

import SendTopkashMember from "../pages/new-ui/send/topkash-member/index";
import SendTopkashMemberEdit from "../pages/new-ui/send/topkash-member/edit";

import Conversion from "../pages/new-ui/conversion/index";
import ConversionEdit from "../pages/new-ui/conversion/edit";

import NewCountry from "../pages/new-ui/country/index";
import NewCountryEdit from "../pages/new-ui/country/edit";

// import NewReloadHistory from "../pages/reload/updated-history";

import TopUpGiftCard from "../pages/new-ui/top-up/gift-card";
import TopUpGiftCardEdit from "../pages/new-ui/top-up/gift-card/edit";

import SuperAdmin from "../pages/new-ui/super-admins";

import NewBank from "../pages/new-ui/bank";
import ExchangeRateMargin from "../pages/new-ui/margin/index";

import NewBankAccount from "../pages/new-ui/bank-accounts";
import NewBankAccountAdd from "../pages/new-ui/bank-accounts/add";
import NewBankAccountEdit from "../pages/new-ui/bank-accounts/edit";

import NewCurrency from "../pages/new-ui/currencies";
import NewCurrencyAdd from "../pages/new-ui/currencies/add";
import NewCurrencyEdit from "../pages/new-ui/currencies/edit";

import Settings from "../pages/new-ui/settings";
import NewProfile from "../pages/new-ui/profile";

import ExchangeRateMarginAdd from "../pages/new-ui/margin/add";

import Report from "../pages/new-ui/report";

import ActivityLog from "../pages/new-ui/activity-log";
import SendBankReport from "../pages/new-ui/report/send-bank";
import SendMemberReport from "../pages/new-ui/report/send-member";
import SendMemberSummaryReport from "../pages/new-ui/report/send-member-summary";
import ConversionReport from "../pages/new-ui/report/conversion";
import TopupBankDepositReport from "../pages/new-ui/report/topup-bank-deposit";
import TopupCryptoReport from "../pages/new-ui/report/topup-crypto";
import TopupGiftCardReport from "../pages/new-ui/report/topup-gift-card";
import AllTransactionsReport from "../pages/new-ui/report/all-transactions";
import AdjustmentReport from "../pages/new-ui/report/adjustment";

import User from "../pages/new-ui/users/index";
import UserAdd from "../pages/new-ui/users/add";
import UserEdit from "../pages/new-ui/users/edit";
import ErrorPage from "../pages/error";

import DeleteMemberRequest from "../pages/new-ui/delete-member-request/index";

import CommunicationChannel from "../pages/new-ui/communication-channel";
import CommunicationChannelAdd from "../pages/new-ui/communication-channel/add";
import CommunicationChannelEdit from "../pages/new-ui/communication-channel/edit";

import MoneyChanger from "../pages/new-ui/money-changer";
import EditMoneyChanger from "../pages/new-ui/money-changer/edit";
// import DeleteMemberRequestAdd from "../pages/new-ui/delete-member-request/add";
import Banner from "../pages/new-ui/banner/index";
import BannerAdd from "../pages/new-ui/banner/add";
import BannerEdit from "../pages/new-ui/banner/edit";
import Dashboard from "../pages/new-ui/dashboard/index";

import CryptoProvider from "../pages/new-ui/crypto-provider/index";
import CryptoProviderAdd from "../pages/new-ui/crypto-provider/add";
import CryptoProviderEdit from "../pages/new-ui/crypto-provider/edit";

import CryptoAccount from "../pages/new-ui/crypto-account";
import CryptoAccountAdd from "../pages/new-ui/crypto-account/add";
import CryptoAccountEdit from "../pages/new-ui/crypto-account/edit";

import TopUpCrypto from "../pages/new-ui/top-up/crypto";
import TopUpCryptoEdit from "../pages/new-ui/top-up/crypto/edit";
import TopUpCryptoPending from "../pages/new-ui/top-up/crypto/pending";

import Adjustments from "../pages/new-ui/adjustments";
import AdjustmentsAdd from "../pages/new-ui/adjustments/add";
import AdjustmentsView from "../pages/new-ui/adjustments/view";

import Faq from "../pages/new-ui/faq";
import FaqLayerAdd from "../pages/new-ui/faq/add-layer";
import FaqCategoryAdd from "../pages/new-ui/faq/add-category";
import FaqCategoryEdit from "../pages/new-ui/faq/edit-category";
import FaqLayerEdit from "../pages/new-ui/faq/edit-layer";

import Merchant from "../pages/new-ui/merchant/index";
import EditMerchant from "../pages/new-ui/merchant/edit";

import BusinessType from "../pages/new-ui/business-type/index";
import AddBusinessType from "../pages/new-ui/business-type/add";
import EditBusinessType from "../pages/new-ui/business-type/edit";

const routes = [
  // {
  //   path: "/",
  //   element: <Home />,
  // },

  // Temporary set pending top-ups page as the root path
  {
    path: "/",
    element: <TopUpBankDepositPending />,
  },
  {
    path: "transfer",
    element: <PendingTransfer />,
  },
  {
    path: "transfer-history",
    element: <TransferHistory />,
  },
  {
    path: "transfer/:id",
    element: <TransferEdit />,
  },
  {
    path: "transaction",
    element: <Transaction />,
  },
  {
    path: "transaction/all",
    element: <AllTransaction />,
  },
  {
    path: "reload",
    element: <PendingReload />,
  },
  {
    path: "reload-history",
    element: <ReloadHistory />,
  },
  {
    path: "reload/:id",
    element: <ReloadEdit />,
  },

  {
    path: "redeem",
    element: <Redeem />,
  },

  {
    path: "profile",
    element: <Profile />,
  },
  // {
  //   path: "user",
  //   element: <User />,
  // },
  // {
  //   path: "user/:id",
  //   element: <UserEdit />,
  // },
  {
    path: "exchange-rate",
    element: <ExchangeRate />,
  },

  {
    path: "exchange-rate/:id",
    element: <ExchangeRateEdit />,
  },
  {
    path: "exchange-rate/add",
    element: <ExchangeRateAdd />,
  },
  {
    path: "base-fixed-rate",
    element: <BaseFixedRate />,
  },

  {
    path: "currency",
    element: <Currency />,
  },
  {
    path: "currency/add",
    element: <CurrencyAdd />,
  },
  {
    path: "currency/:id",
    element: <CurrencyEdit />,
  },
  {
    path: "country",
    element: <Country />,
  },
  {
    path: "country/:id",
    element: <CountryEdit />,
  },
  {
    path: "bank",
    element: <Bank />,
  },
  {
    path: "bank-add",
    element: <BankAdd />,
  },
  {
    path: "bank/:id",
    element: <BankEdit />,
  },
  {
    path: "banks-by-country",
    element: <BanksByCountry />,
  },

  {
    path: "banks-by-country-add/:id",
    element: <BanksByCountryAdd />,
  },
  {
    path: "banks-by-country-edit/:id",
    element: <BanksByCountryEdit />,
  },
  {
    path: "banks-by-country-add-branch",
    element: <BanksByCountryAddBranch />,
  },
  {
    path: "banks-by-country-add-branch/:id",
    element: <BanksByCountryAddBranch />,
  },
  {
    path: "banks-by-country-edit-branch/:id",
    element: <BanksByCountryEditBranch />,
  },
  {
    path: "bank-accounts",
    element: <BankAccounts />,
  },
  {
    path: "bank-accounts-add-account",
    element: <BankAccountsAddAccount />,
  },
  {
    path: "bank-accounts-edit-account/:id",
    element: <BankAccountsEditAccount />,
  },
  {
    path: "dashboard-user",
    element: <DashboardUser />,
  },
  {
    path: "dashboard-user/:id",
    element: <DashboardUserEdit />,
  },
  {
    path: "dashboard-user/add",
    element: <DashboardUserAdd />,
  },

  {
    path: "general-setting",
    element: <GeneralSetting />,
  },
  {
    path: "*",
    element: <Navigate replace to="/" />,
  },
  {
    path: "downline-tree",
    element: <DownlineTree />,
  },

  //NEW UI paths

  {
    path: "settings/cost-exchange-rate",
    element: <CostExchangeRate />,
  },

  {
    path: "settings/manual-cost-exchange-rate",
    element: <ManualCostExchangeRate />,
  },

  {
    path: "settings/manual-cost-exchange-rate/add",
    element: <ManualCostExchangeRateAdd />,
  },

  {
    path: "settings/manual-cost-exchange-rate/:id",
    element: <ManualCostExchangeRateEdit />,
  },

  {
    path: "settings/fee",
    element: <BaseFlatRate />,
  },
  {
    path: "settings/fee/add",
    element: <BaseFlatRateAdd />,
  },

  {
    path: "settings/faq",
    element: <Faq />,
  },
  {
    path: "settings/faq-category/add",
    element: <FaqCategoryAdd />,
  },
  {
    path: "settings/faq-layer/add/:type/:id",
    element: <FaqLayerAdd />,
  },
  {
    path: "settings/faq-category/:id",
    element: <FaqCategoryEdit />,
  },
  {
    path: "settings/faq-layer/:type/:id",
    element: <FaqLayerEdit />,
  },

  {
    path: "member",
    element: <Member />,
  },
  {
    path: "member/:id",
    element: <MemberEdit />,
  },

  {
    path: "member/mobile-secure-code",
    element: <MobileOTP />,
  },

  {
    path: "member/wallet-balance",
    element: <WalletBalance />,
  },

  {
    path: "customer-service-user",
    element: <CustomerServiceUser />,
  },
  {
    path: "customer-service-user/add",
    element: <CustomerServiceUserAdd />,
  },
  {
    path: "customer-service-user/:id",
    element: <CustomerServiceUserEdit />,
  },
  // {
  //   path: "commission",
  //   element: <Commission />,
  // },
  {
    path: "commission-log",
    element: <CommissionLog />,
  },
  {
    path: "kyc",
    element: <MemberKyc />,
  },
  {
    path: "top-up/bank",
    element: <TopUpBankDeposit />,
  },
  {
    path: "top-up/bank/:id",
    element: <TopUpBankDepositEdit />,
  },
  {
    path: "top-up/bank/pending",
    element: <TopUpBankDepositPending />,
  },
  {
    path: "top-up/gift-card",
    element: <TopUpGiftCard />,
  },
  {
    path: "top-up/gift-card/:id",
    element: <TopUpGiftCardEdit />,
  },
  {
    path: "new-reload/:id",
    element: <NewReloadEdit />,
  },
  {
    path: "send/bank",
    element: <SendBankDeposit />,
  },
  {
    path: "send/bank/pending",
    element: <SendBankDepositPending />,
  },
  {
    path: "send/bank/:id",
    element: <SendBankDepositEdit />,
  },
  {
    path: "send/member",
    element: <SendTopkashMember />,
  },
  {
    path: "send/member/:id",
    element: <SendTopkashMemberEdit />,
  },
  {
    path: "super-admin",
    element: <SuperAdmin />,
  },
  {
    path: "conversion",
    element: <Conversion />,
  },
  {
    path: "conversion/:id",
    element: <ConversionEdit />,
  },
  {
    path: "settings/margin",
    element: <ExchangeRateMargin />,
  },

  {
    path: "settings/margin/add",
    element: <ExchangeRateMarginAdd />,
  },

  // {
  //   path: "conversion",
  //   element: <Conversion />,
  // },

  {
    path: "settings/bank-account",
    element: <NewBankAccount />,
  },
  {
    path: "settings/bank-account/add",
    element: <NewBankAccountAdd />,
  },
  {
    path: "settings/bank-account/:id",
    element: <NewBankAccountEdit />,
  },

  {
    path: "settings/crypto-account",
    element: <CryptoAccount />,
  },
  {
    path: "settings/crypto-account/add",
    element: <CryptoAccountAdd />,
  },
  {
    path: "settings/crypto-account/:id",
    element: <CryptoAccountEdit />,
  },

  {
    path: "settings/bank",
    element: <NewBank />,
  },
  {
    path: "settings/bank-add",
    element: <NewBankAdd />,
  },

  {
    path: "settings/bank/:id",
    element: <NewBankEdit />,
  },

  {
    path: "settings/crypto-provider",
    element: <CryptoProvider />,
  },
  {
    path: "settings/crypto-provider/add",
    element: <CryptoProviderAdd />,
  },

  {
    path: "settings/crypto-provider/:id",
    element: <CryptoProviderEdit />,
  },

  {
    path: "settings",
    element: <Settings />,
  },
  {
    path: "change-password",
    element: <ChangePassword />,
  },

  {
    path: "settings/staff",
    element: <User />,
  },

  {
    path: "settings/staff/add",
    element: <UserAdd />,
  },
  {
    path: "settings/staff/:id",
    element: <UserEdit />,
  },
  {
    path: "settings/communication-channel",
    element: <CommunicationChannel />,
  },
  {
    path: "settings/communication-channel/add",
    element: <CommunicationChannelAdd />,
  },
  {
    path: "settings/communication-channel/:id",
    element: <CommunicationChannelEdit />,
  },

  // {
  //   path: "new-reload-history",
  //   element: <NewReloadHistory />,
  // },
  // {
  //   path: "pending-reload",
  //   element: <NewPendingReload />,
  // },

  // {
  //   path: "pending-transfer",
  //   element: <NewPendingTransfer />,
  // },
  // {
  //   path: "new-transfer/:id",
  //   element: <NewTransferEdit />,
  // },

  //To be change later
  {
    path: "settings/country",
    element: <NewCountry />,
  },
  {
    path: "settings/country/:id",
    element: <NewCountryEdit />,
  },

  {
    path: "settings/currency",
    element: <NewCurrency />,
  },
  {
    path: "settings/currency/add",
    element: <NewCurrencyAdd />,
  },
  {
    path: "settings/currency/:id",
    element: <NewCurrencyEdit />,
  },

  {
    path: "new-profile",
    element: <NewProfile />,
  },

  {
    path: "report",
    element: <Report />,
  },
  {
    path: "report/topup/bank-deposit",
    element: <TopupBankDepositReport />,
  },
  {
    path: "report/topup/crypto",
    element: <TopupCryptoReport />,
  },
  {
    path: "report/topup/gift-card",
    element: <TopupGiftCardReport />,
  },
  {
    path: "report/conversion",
    element: <ConversionReport />,
  },
  {
    path: "report/send/bank",
    element: <SendBankReport />,
  },
  {
    path: "report/send/member",
    element: <SendMemberReport />,
  },
  {
    path: "report/send/member/summary",
    element: <SendMemberSummaryReport />,
  },

  {
    path: "report/all-transactions",
    element: <AllTransactionsReport />,
  },

  {
    path: "report/adjustment",
    element: <AdjustmentReport />,
  },
  {
    path: "activity-log",
    element: <ActivityLog />,
  },
  {
    path: "error",
    element: <ErrorPage />,
  },
  {
    path: "settings/delete-member-request",
    element: <DeleteMemberRequest />,
  },
  {
    path: "settings/banner",
    element: <Banner />,
  },
  {
    path: "settings/banner/add",
    element: <BannerAdd />,
  },
  {
    path: "settings/banner/:id",
    element: <BannerEdit />,
  },
  // {
  //   path: "settings/delete-member-request/add",
  //   element: <DeleteMemberRequestAdd />,
  // },
  {
    path: "settings/business-type",
    element: <BusinessType />,
  },
  {
    path: "settings/business-type/add",
    element: <AddBusinessType />,
  },
  {
    path: "settings/business-type/:id",
    element: <EditBusinessType />,
  },
  {
    path: "settings/money-changer",
    element: <MoneyChanger />,
  },

  {
    path: "settings/money-changer/add",
    element: <EditMoneyChanger />,
  },
  {
    path: "settings/money-changer/:id",
    element: <EditMoneyChanger />,
  },
  {
    path: "settings/merchant",
    element: <Merchant />,
  },

  {
    path: "settings/merchant/add",
    element: <EditMerchant />,
  },
  {
    path: "settings/merchant/:id",
    element: <EditMerchant />,
  },

  {
    path: "dashboard",
    element: <Dashboard />,
  },

  {
    path: "top-up/crypto",
    element: <TopUpCrypto />,
  },
  {
    path: "top-up/crypto/:id",
    element: <TopUpCryptoEdit />,
  },
  {
    path: "top-up/crypto/pending",
    element: <TopUpCryptoPending />,
  },
  {
    path: "adjustments",
    element: <Adjustments />,
  },
  {
    path: "adjustments/add",
    element: <AdjustmentsAdd />,
  },
  {
    path: "adjustments/:id",
    element: <AdjustmentsView />,
  },
];

const authRoutes = [
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "reset-password/:e",
    element: <ResetPassword />,
  },

  {
    path: "first-login/:e",
    element: <FirstLogin />,
  },

  {
    path: "tfa",
    element: <TwoFactorAuthentication />,
  },
];

export { authRoutes, routes };
