import React from "react";
import { Divider, Table, Row } from "antd";
import moment from "moment-timezone";
// ============

const HistoryTable = ({ data }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => {
        return (
          <div>
            {moment
              .utc(record.created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      }
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username"
    },
    {
      title: "User Type",
      dataIndex: "role_id",
      key: "role_id",
      render: (_, record) => {
        if (
          record.role_id == 1 ||
          record.role_id == 3 ||
          record.role_id == 4 ||
          record.role_id == 5
        ) {
          return <div>Staff</div>;
        } else {
          return <div>Member</div>;
        }
      }
    },
    {
      title: "Action",
      dataIndex: "action_type",
      key: "action_type"
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    }
  ];

  return (
    <div style={{ marginBottom: 20 }}>
      <Divider className="divider" />
      <Row>
        <div className="history_section_title">History</div>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        className="history_table"
        pagination={{
          defaultPageSize: 20
        }}
      />
    </div>
  );
};

export default HistoryTable;
