import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { map, uniq } from "lodash";
import { errorHandler } from "../configs";

const initialState = {
  notification: {
    message: [],
    totalUnread: 0,
    total: 0,
    isNotificationModalVisible: false,
    title: "",
    content: "",
  },
};

export const retrieveNotifications = createAsyncThunk(
  "notification/retrieveNotifications",
  async (_, { getState }) => {
    try {
      let current = getState().ui.notification.current;
      const { data } = await axios.get(`notification/list?page=${current}`);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const markAllAsRead = createAsyncThunk(
  "notification/markAllAsRead",
  async (_) => {
    try {
      const { data } = await axios.patch(`notification/mark-all-as-read`);
      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const markMessageAsRead = createAsyncThunk(
  "notification/markMessageAsRead",
  async ({ id }) => {
    try {
      let formData = new FormData();

      formData.append("id", id);

      const { data } = await axios.post("notification/mark-as-read", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        id,
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
// Then, handle actions in your reducers:
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotification: (state) => {
      return {
        ...initialState,
      };
    },
    toggleNotificationModal: (state, { payload }) => {
      state.notification.isNotificationModalVisible = payload;
    },
    setModalContent: (state, { payload }) => {
      state.notification.title = payload.title;
      state.notification.content = payload.content;
    },
    resetModalContent: (state) => {
      state.notification.title = "";
      state.notification.content = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(retrieveNotifications.fulfilled, (state, { payload }) => {
      if (payload.data) {
        // state.loading = false;
        const { data, total_unread, total } = payload.data;
        state.notification.message = uniq([
          ...state.notification.message,
          ...data,
        ]);
        state.notification.totalUnread = total_unread;
        state.notification.total = total;
      }
    });

    builder.addCase(markAllAsRead.fulfilled, (state, { payload }) => {
      // state.loading = false;
      state.notification.message = map(state.notification.message, (item) => {
        return {
          ...item,
          is_read: 1,
        };
      });
    });

    builder.addCase(markMessageAsRead.fulfilled, (state, { payload }) => {
      // state.loading = false;
      state.notification.message = map(state.notification.message, (item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            is_read: 1,
          };
        } else {
          return {
            ...item,
          };
        }
      });
    });
  },
});

export const {
  resetNotification,
  toggleNotificationModal,
  setModalContent,
  resetModalContent,
} = notificationSlice.actions;

export default notificationSlice.reducer;
