import React, { useRef, useState, useEffect } from "react";
import { Button, Row, Input, Form, message, Select, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, find, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { createUser } from "@store/user";
import { getAllRoles } from "@store/app";
const CustomerServiceAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Option } = Select;
  const formRef = useRef();
  // const [roleOption, setRoleOption] = useState([]);

  const onFinishAddUser = async (values) => {
    try {
      const { data } = await dispatch(createUser(values)).unwrap();
      const { message: responseMessage } = data;

      message.success(responseMessage);
      navigate("/settings/staff");
    } catch (error) {
      message.error(error.message);
    }
  };

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  const roleOption = [
    { value: "customer_service", label: "Customer Service" },
    { value: "payment_officer", label: "Payment Officer" },
    { value: "supervisor", label: "Supervisor" },
    { value: "superadmin", label: "Super Admin" }
  ];

  useEffect(() => {
    // set Status initial value
    formRef.current.setFieldsValue({
      status: statusOption[0].value
    });
    // fetchData();
  }, []);

  // const fetchData = async () => {
  //   const { data: rolesData } = await dispatch(getAllRoles()).unwrap();

  //   const rolesMap = map(rolesData, (item) => {
  //     const { name, code } = item;
  //     return {
  //       label: name,
  //       value: code
  //     };
  //   });
  //   setRoleOption(rolesMap);
  // };

  const phoneNumberOnChange = async ({ target }) => {
    const { value } = target;

    let text = value;

    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/gi, "");
    formRef.current.setFieldValue("new_phone_no", formattedPhoneNumber);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Staff</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={onFinishAddUser}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item name="status" label="Status">
            <Select name="status">
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="role"
            label="Staff Type"
            rules={[
              {
                required: true,
                message: "Please select a staff type"
              }
            ]}
          >
            <Select
              name="role"
              placeholder="Staff Type"
              // filterSort={(optionA, optionB) =>
              //   optionA.children
              //     .toLowerCase()
              //     .localeCompare(optionB.children.toLowerCase())
              // }
            >
              {map(roleOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            className="input"
            name="new_username"
            label="Username"
            extra={
              <div className="rules">
                Maximum 10 characters, lowercase, alphanumeric and underscore
                only.
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please enter the username"
              },
              {
                pattern: /^[a-z0-9]{1,10}$/,
                message:
                  "Username must be small letters, no caps and no symbols. The length should not exceeding 10 characters"
              }
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            label="Full Name"
            name="new_fullname"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the full name"
              }
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="new_email"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the email"
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Please enter a valid email"
              }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Mobile"
            name="new_phone_no"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the mobile number",

                validator: async (_, value) => {
                  let text = value;
                  if (text) {
                    if (typeof text === "number") {
                      text = value.toString();
                    }
                  } else {
                    throw new Error("Please enter Mobile number");
                  }
                }
              }
            ]}
          >
            <Input placeholder="Mobile" onChange={phoneNumberOnChange} />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save & Email Login Details To Staff
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CustomerServiceAdd;
