import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Row, Button, message } from "antd";
import { paramCase } from "change-case";
// ============
import styles from "./styles.module.less";
import { addFaqCategory } from "../../../store/faq";

const FaqCategoryAdd = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = async (values) => {
    if (values) {
      try {
        const { data } = await dispatch(addFaqCategory(values)).unwrap();

        message.success(data.message);
        navigate(-1);
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New FAQ Category</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="title"
            className={styles.input}
            label={<div className={styles.label}>Title</div>}
            rules={[
              {
                required: true,
                message: "Please enter your FAQ title",
              },
              {
                required: true,
                validator: async (_, value) => {
                  const regex = /^\s+|\s+$/g;

                  if (value) {
                    if (regex.test(value)) {
                      throw new Error(
                        "Title should not contain space before and after it"
                      );
                    }
                  }
                },
              },
            ]}
          >
            <div className={styles.password_input}>
              <Input
                placeholder="FAQ title"
                onChange={(e) => {
                  formRef.current.setFieldValue(
                    "slug",
                    paramCase(e.target.value)
                  );
                }}
              />
            </div>
          </Form.Item>

          <Form.Item name="slug" label="Slug" className="input">
            <Input disabled placeholder="Slug" />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default FaqCategoryAdd;
