import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
import { map } from "lodash";
// ============
import styles from "./styles.module.less";
import { updateBank } from "@store/bank";
import {
  retrieveAllCurrencies,
  getCurrencyById
} from "../../../store/currency";
import {
  getCryptoProviderById,
  updateCryptoProvider
} from "../../../store/crypto";
import HistoryTable from "../../../components/history-table";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
const { Option } = Select;

const CryptoProviderEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params } = useMatch("settings/crypto-provider/:id");
  const [currency, setCurrency] = useState([]);
  const currencies = useSelector((state) => state.currency.currencies);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const formRef = useRef();
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Retrieve crypto provider details
    const cryptoProviderDetails = await dispatch(
      getCryptoProviderById({
        id: parseInt(params.id)
      })
    ).unwrap();

    const { data: cryptoProviderInfo } = cryptoProviderDetails;

    formRef.current.setFieldsValue({
      provider_name: cryptoProviderInfo.name,
      currency_id: cryptoProviderInfo.currency_id
    });

    // Retrieve currencies
    const currency = await dispatch(retrieveAllCurrencies()).unwrap();
    const { data: currencyData } = currency;

    setCurrency(currencyData);

    // Retrieve history data
    const { data: historyData } = await dispatch(
      findActivityLogsByRefIdAndRefTable({
        reference_id: params.id,
        reference_table: "crypto_providers"
      })
    ).unwrap();

    setHistoryData(historyData);
  };

  const handleSubmit = async (values) => {
    values = {
      ...values,
      id: params.id
    };
    try {
      if (values) {
        console.log(values);
        const { data } = await dispatch(
          updateCryptoProvider({
            id: params.id,
            name: values.provider_name
          })
        ).unwrap();

        message.success(data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const currencyOnChange = async (id) => {
    const { data: currencyDetails } = await dispatch(
      getCurrencyById({
        id
      })
    ).unwrap();

    setSelectedCurrency(currencyDetails);
  };
  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Edit Crypto Provider</div>
        </Row>
        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="provider_name"
            className="input"
            label="Provider Name"
            rules={[
              {
                required: true,
                message: "Please enter provider name"
              }
            ]}
          >
            <Input placeholder="Provider name" />
          </Form.Item>

          {/* <Form.Item
            name="currency_id"
            // value={selectedCurrency}
             
            label="Currency"
            rules={[
              {
                required: true,
                message: "Currency cannot be empty",
              },
            ]}
          >
            <Select
              name="currency_id"
              // value={selectedCurrency}
              onChange={currencyOnChange}
              placeholder="Select Currency"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                onClick={() => navigate("/settings/crypto-provider")}
                className="default_btn"
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default CryptoProviderEdit;
