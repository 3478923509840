import React, { useRef, useState, useEffect } from "react";
import { Button, Row, Input, Form, message, Card, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { createExchangeRate } from "@store/exchange";
import { retrieveAllCurrencies } from "@store/currency";
import ConfirmationPopUp from "@components/confirmation-popup";
import { map } from "lodash";
const { Option } = Select;

const ManualCostExchangeRateAdd = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const [currencies, setCurrencies] = useState([]);
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [values, setValues] = useState({})

    const durations = [
        { value: 5, label: "5 Minutes" },
        { value: 120, label: "2 Hours" },
        { value: 240, label: "4 Hours" },
        { value: 360, label: "6 Hours" },
        { value: 480, label: "8 Hours" },
        { value: 600, label: "10 Hours" },
        { value: 720, label: "12 Hours" },
        { value: 1320, label: "22 Hours" },
        { value: 1440, label: "24 Hours" },
    ];

    useEffect(() => {
        initialise();
    }, []);

    const initialise = async () => {
        const { data: currencyData } = await dispatch(
            retrieveAllCurrencies()
        ).unwrap();

        setCurrencies(currencyData);
    };

    const onSaveExchangeRate = async ({ duration }) => {

        let payload = {
            ...values,
            duration
        }

        try {
            const { data } = await dispatch(
                createExchangeRate(payload)
            ).unwrap();

            message.success(data.message);
            navigate(-1);
        } catch (error) {
            message.error(error.message);
        }
    };

    return (
        <div className={styles.container}>
            <Card className={styles.card_container}>
                <Row>
                    <div className={styles.section_title}>New Manual Cost Exchange Rate</div>
                </Row>

                <Form
                    colon={false}
                    name="basic"
                    labelCol={{
                        span: 4
                    }}
                    wrapperCol={{
                        span: 20
                    }}
                    initialValues={{
                        remember: true
                    }}
                    onFinish={(values) => {
                        setShowScheduleModal(true)
                        setValues(values)
                    }}
                    autoComplete="off"
                    className="form-details"
                >
                    <Form.Item
                        name="from_currency"
                        label="From Currency"
                        rules={[
                            {
                                required: true,
                                message: "Please select from currency"
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="From Currency"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {map(currencies, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.iso_code}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="to_currency"
                        label="To Currency"
                        rules={[
                            {
                                required: true,
                                message: "Please select to currency"
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="To Currency"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {map(currencies, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.iso_code}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Rate"
                        name="rate"
                        className="input"
                        rules={[
                            {
                                required: true,
                                message: "Please enter the rate"
                            },

                            ({ }) => ({
                                validator(_, value) {
                                    if (value === null || value === undefined || value === "") {
                                        return Promise.resolve();
                                    } else if (isNaN(value) || isNaN(parseInt(value))) {
                                        return Promise.reject(new Error("Rate must be a number"));
                                    } else if (value < 0 || value === "-0") {
                                        return Promise.reject(
                                            new Error("Rate must not be lower than 0")
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })
                        ]}
                    >
                        <Input placeholder="Rate" />
                    </Form.Item>

                    <Row justify="end">
                        <div className={styles.btn_container}>
                            <Button onClick={() => navigate(-1)}>Cancel</Button>
                        </div>
                        <div className={styles.btn_container}>
                            <Button
                                type="default"
                                htmlType="submit"
                                className="ant-btn-green"
                            >
                                Save
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Card>
            <ConfirmationPopUp
                visible={showScheduleModal}
                title="Confirmation"
                handleOnOk={() => form.submit()}
                handleOnCancel={() => {
                    setShowScheduleModal(false)
                }}
            >
                <div style={{ textAlign: 'justify', marginBottom: 10 }}>
                    How long would you like to keep this rate enabled? Please indicate the time frame:
                </div>
                <Form
                    form={form}
                    style={{ width: "100%" }}
                    onFinish={onSaveExchangeRate}
                    labelCol={{
                        span: 9
                    }}
                    wrapperCol={{
                        span: 15
                    }}
                    className="modal-form"
                    labelAlign="left"
                >
                    <Form.Item
                        name="duration"
                        label="Duration"
                        className="label-details"
                        style={{ textAlign: "left" }}
                        rules={[{ required: true, message: "Please select duration" }]}
                    >
                        <Select placeholder="Duration">
                            {map(durations, (item) => {
                                return (
                                    <Option key={item.label} value={item.value}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </ConfirmationPopUp>
        </div>
    );
};

export default ManualCostExchangeRateAdd;
