import React, { useEffect, useState } from "react";
import { Table, Button, message, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "../../../store/ui";
import TableHeader from "../../../components/table-header";
import { retrieveFaqCategories } from "../../../store/faq";

const Faq = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);

  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(resetTablePagination());
  //   fetchData();
  // }, [filter]);

  const editOnClick = (item) => {
    navigate(`/settings/faq-category/${item.id}`);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(retrieveFaqCategories()).unwrap();

      const { data: faqsData, total } = data;

      setTableData(faqsData);
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());

    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 100
    },
    {
      title: "Category Title",
      dataIndex: "title",
      align: "left"
    },
    {
      title: "Slug",
      dataIndex: "slug",
      align: "left",
    },
    {
      title: "Sequence",
      dataIndex: "sequence",
      align: "left"
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment.utc(created_at).tz("Asia/Phnom_Penh").format("DD-MM-YYYY")}
          </div>
        );
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="FAQ"
        showCreateButton={true}
        createButtonText={"Create New FAQ Category"}
        createOnClick={() => navigate("/settings/faq-category/add")}
      ></TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Faq;
