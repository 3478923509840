import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
  Tooltip
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { map } from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findAllDashboardUsers, getAllRoles } from "@store/app";
import TableHeader from "@components/table-header";

const DashboardUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const [filter, setFilter] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const editOnClick = (item) => {
    navigate(`/settings/staff/${item.id}`);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        findAllDashboardUsers({ filter })
      ).unwrap();
      const { data: userData, total } = data;

      setTableData(userData);
      setTableTotalData(total);

      let { data: roleData } = await dispatch(getAllRoles()).unwrap();

      roleData = map(roleData, (item) => {
        return {
          label: item.name,
          value: item.id
        };
      });

      setRoleData(roleData);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const columns = [
    {
      title: "Staff ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 80
    },
    {
      title: "Staff Type",
      dataIndex: "role",
      align: "left",
      width: 100
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      width: 140
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      align: "left",
      width: 140
    },
    {
      title: "Mobile",
      dataIndex: "phone_no",
      align: "left",
      width: 140,
      render: (_, record) => {
        if (record.phone_no) {
          return <div>{record.phone_no}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },

    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      width: 200
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 140,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return <div>{moment(created_at).format("DD-MM-YYYY")}</div>;
      }
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        if (record.status === 1) {
          status = "Active";
          color = "#95de64";
        } else {
          status = "Inactive";
          color = "#FF0000";
        }
        return (
          <div align={"middle"} style={{ color }}>
            {status}
          </div>
        );
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Tooltip title="Edit">
                <Button
                  size={"small"}
                  className="ant-btn-action "
                  onClick={() => editOnClick(record)}
                >
                  Edit
                </Button>
              </Tooltip>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Staff"
        createButtonText="Create New Staff"
        showCreateButton={true}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        createOnClick={() => navigate("/settings/staff/add")}
        // resetOnClick={() => {
        //   dispatch(resetButtonOnClick());
        // }}
        resetOnClick={() => {
          setFilter({});
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"id"} initialValue={null}>
              <Input
                placeholder="Staff ID"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"role_id_selected"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Staff Type"
                options={roleData}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"username"} initialValue={null}>
              <Input
                placeholder="Username"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"fullname"} initialValue={null}>
              <Input
                placeholder="Full Name"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name={"status"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Status"
                options={[
                  { value: "1", label: "Active" },
                  { value: "2", label: "Inactive" }
                ]}
                showArrow
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default DashboardUser;
