import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Image,
  Space,
  Divider
} from "antd";
// ============
import styles from "./styles.module.less";
import { findTransferById } from "@store/transfer";
import { find, map, filter } from "lodash";
import moment from "moment-timezone";

const { Option } = Select;

const ConversionEdit = () => {
  const { params } = useMatch("conversion/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [conversionData, setConversionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const conversion = await dispatch(
          findTransferById({
            id: params.id
          })
        ).unwrap();

        const { data } = conversion;
        setConversionData(data);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const renderStatusColor = () => {
    return { color: "#339933" };
  };

  const renderStatus = () => {
    return "Successful";
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">View Conversion</div>
        </Row>
        <Form
          colon={false}
          // ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          className="form-details"
          requiredMark={false}
        >
          <Form.Item name="status" label="Status" className="label-details">
            <div style={renderStatusColor(conversionData.status)}>
              {renderStatus(conversionData.status)}
            </div>
          </Form.Item>
          <Form.Item name="id" label="ID" className="label-details">
            <div>{conversionData.doc_id}</div>
          </Form.Item>
          <Form.Item
            name="date"
            label="Submission Date"
            className="label-details"
          >
            <div>
              {moment
                .utc(conversionData.created_at)
                .tz("Asia/Phnom_Penh")
                .format("DD-MM-YYYY, hh:mm:ss A")}
            </div>
          </Form.Item>
          <Divider className="divider" />
          <Form.Item
            name="topkash_id"
            label="Topkash ID"
            className="label-details"
          >
            <div>
              {conversionData.user_topkash_id
                ? conversionData.user_topkash_id
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="username" label="Username" className="label-details">
            <div>
              {conversionData.user_username
                ? conversionData.user_username
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="name" label="Full Name" className="label-details">
            <div>
              {conversionData.user_fullname
                ? conversionData.user_fullname
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="mobile" label="Mobile" className="label-details">
            <div>
              {conversionData.user_phone_no
                ? conversionData.user_phone_no
                : "-"}
            </div>
          </Form.Item>
          <Form.Item name="email" label="Email" className="label-details">
            <div>
              {conversionData.user_email ? conversionData.user_email : "-"}
            </div>
          </Form.Item>

          <Divider className="divider" />

          <div className="section_subtitle">Conversion</div>
          {/* <Form.Item label="Conversion" className={styles.section_subtitle} /> */}

          <Form.Item
            name="from_currency"
            label="From Currency"
            className="label-details"
          >
            <div>
              {conversionData.from_currency_code} -{" "}
              {conversionData.from_currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="from_amount"
            label="From Amount"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(conversionData.amount)).format("0,0.00")}
            </div>
          </Form.Item>

          <Form.Item name="fee" label="Fee" className="label-details">
            <div>
              {parseFloat(conversionData.processing_fee) !== 0.0
                ? numeral(parseFloat(conversionData.processing_fee)).format(
                    "0,0.00"
                  )
                : "-"}
            </div>
          </Form.Item>

          <Form.Item
            name="amount_to_converted"
            label="Amount To Converted"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(conversionData.to_convert_amount)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="exchange_rate"
            label="Exchange Rate"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(conversionData.exchange_rate)).format(
                "0,0.000000"
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="to_currency"
            label="To Currency"
            className="label-details"
          >
            <div>
              {conversionData.to_currency_code} -{" "}
              {conversionData.to_currency_name}
            </div>
          </Form.Item>

          <Form.Item
            name="to_amount"
            label="To Amount"
            className="label-details"
          >
            <div>
              {numeral(parseFloat(conversionData.converted_amount)).format(
                "0,0.00"
              )}
            </div>
          </Form.Item>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ConversionEdit;
