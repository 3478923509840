import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Divider,
  Form,
  Switch,
  Modal,
  message,
  Select,
} from "antd";
import { FiEye, FiEyeOff } from "react-icons/fi";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useDispatch, useSelector } from "react-redux";
import { map, filter } from "lodash";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import {
  retrieveTfaQrCode,
  tfaVerification,
  retrieveTfaStatus,
  updateTfaEnableField,
  resetTfaStatus,
  changePassword,
  updateProfile,
  logout,
} from "@store/app";

const { Option } = Select;

const Profile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEnableTFA, setIsEnableTFA] = useState(null);
  const [isDisableTfa, setIsDisableTfa] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [isResetStatus, setIsResetStatus] = useState(false);
  const [isChangePwdModalVisible, setIsChangePwdModalVisible] = useState(false);
  const isUserTfaEnabled = useSelector((state) => state.app.user.isTfaEnabled);
  const isUserTfaSecretEnabled = useSelector(
    (state) => state.app.user.isTfaSecretEnabled
  );

  const qrcode = useSelector((state) => state.app.qrcode);
  const [isWrongTfaCode, setIsWrongTfaCode] = useState(false);
  const [isTfaEnabled, setIsTfaEnabled] = useState(false);
  const [handleDisableSwitch, setHandleDisableSwitch] = useState(false);
  const [isFirstTfa, setIsFirstTfa] = useState(false);

  const user = useSelector((state) => state.app.user);
  const countries = useSelector((state) =>
    filter(state.country.allCountries, (item) => item.is_enabled == 1)
  );

  const AuthInputRef = useRef(null);
  const updateProfileFormRef = useRef();
  const updateProfileFormPasswordRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = async (response) => {
    if (response.length === 6) {
      try {
        await dispatch(tfaVerification({ code: response })).unwrap();

        try {
          setIsModalVisible(false);
          await dispatch(retrieveTfaStatus()).unwrap();
          if (isResetStatus) {
            setIsResetStatus(false);
          }
        } catch (error) {
          message.error(error.message);
        }
      } catch (error) {
        setIsWrongTfaCode(true);

        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    const _retrieveTfaStatus = async () => {
      try {
        await dispatch(retrieveTfaStatus()).unwrap();
      } catch (error) {
        message.error(error.message);
      }
    };

    _retrieveTfaStatus();

    if (user) {
      updateProfileFormRef?.current?.setFieldsValue({
        username: user.username,
        fullname: user.fullname,
        email: user.email,
        phone_no: user.phoneNo,
        nationality_id: user.nationalityId ? user.nationalityId : null,
      });
    }
  }, []);

  const _retrieveTfaQrCode = async () => {
    try {
      if (isEnableTFA) {
        await dispatch(retrieveTfaQrCode()).unwrap();
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    _retrieveTfaQrCode();
  }, [isEnableTFA]);

  useEffect(() => {
    const _updateTfaEnableField = async () => {
      await dispatch(updateTfaEnableField()).unwrap();
    };

    try {
      if (isTfaEnabled) {
        if (!isUserTfaEnabled && !isUserTfaSecretEnabled) {
          setIsModalVisible(true);
          setIsFirstTfa(true);
        } else if (!isUserTfaEnabled && isUserTfaSecretEnabled) {
          _updateTfaEnableField();
          setIsFirstTfa(false);
        } else if (isUserTfaEnabled && isUserTfaSecretEnabled) {
          setHandleDisableSwitch(true);
          setIsFirstTfa(true);
        }
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [isTfaEnabled]);

  useEffect(() => {
    if (handleDisableSwitch && !isTfaEnabled) {
      handleDisable(true);
    }
  }, [handleDisableSwitch, isTfaEnabled]);

  const handleDisable = () => {
    if (isUserTfaEnabled && isUserTfaSecretEnabled) {
      setIsDisableTfa(true);
    }
  };

  useEffect(() => {
    setIsTfaEnabled(isUserTfaEnabled);
  }, [isUserTfaEnabled]);

  useEffect(() => {
    if (isModalVisible) {
      if (AuthInputRef && AuthInputRef.current) {
        _retrieveTfaQrCode();
        AuthInputRef.current.clear();
        setIsWrongTfaCode(false);
      }
    }
  }, [isModalVisible]);

  const cancelTFA = () => {
    setIsModalVisible(false);
    setIsTfaEnabled(false);
    setIsFirstTfa(false);

    AuthInputRef.current.clear();
  };

  const handleSwitch = (isTfaEnabled) => {
    setIsTfaEnabled(isTfaEnabled);
  };

  const confirmDisableTfa = (event) => {
    try {
      if (event) {
        dispatch(updateTfaEnableField()).unwrap();
        setIsDisableTfa(false);
        setIsFirstTfa(false);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const cancelDisableTfa = (event) => {
    setIsDisableTfa(false);
    setIsTfaEnabled(true);
  };

  const showResetModal = () => {
    setIsResetModalVisible(true);
  };

  const handleResetOk = async () => {
    try {
      await dispatch(resetTfaStatus()).unwrap();
      setIsTfaEnabled(true);
      setIsResetModalVisible(false);
      setIsResetStatus(true);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleResetCancel = () => {
    setIsResetModalVisible(false);
  };

  const onFinishChangePassword = async (values) => {
    try {
      const { data } = await dispatch(
        changePassword({
          old_password: values.old_password,
          new_password: values.new_password,
        })
      ).unwrap();
      message.success(data.message);
      updateProfileFormPasswordRef.current.resetFields();
      dispatch(logout()).unwrap();
      navigate({
        pathname: "/signin",
      });
    } catch (error) {
      // message.error(`Incorrect Old Password`);

      message.error(error.message);
    }
  };

  const onFinishUpdateProfile = async (values) => {
    try {
      const { data } = await dispatch(updateProfile(values)).unwrap();

      message.success(data.message);
    } catch (error) {
      message.error(error.message);
    }
  };

  const onCountryChange = (value) => {
    updateProfileFormRef?.current?.setFieldsValue({
      nationality_id: value,
    });
  };

  const renderCountryOptions = (item) => {
    return (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    );
  };

  const handleChangePasswordModal = () => {
    setIsChangePwdModalVisible(false);
  };

  return (
    <>
      <div className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>GENERAL INFORMATION</div>
        </Row>
        <Form
          ref={updateProfileFormRef}
          layout="vertical"
          className={styles.input_container}
          onFinish={onFinishUpdateProfile}
        >
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <div className={styles.input}>
                <Form.Item
                  label="Username"
                  name="username"
                  className={styles.input}
                >
                  <Input disabled={true} placeholder="Username" />
                </Form.Item>
              </div>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<div className={styles.label}>Full Name</div>}
                className={styles.input}
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: "Full name cannot be blank",
                  },
                ]}
              >
                <Input placeholder="Full Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="email"
                className={styles.input}
                label={<div className={styles.label}>Email</div>}
                rules={[
                  {
                    required: true,
                    message: "Email cannot be blank",
                  },
                  {
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                className={styles.input}
                name="phone_no"
                label={<div className={styles.label}>Phone No.</div>}
                rules={[
                  {
                    required: true,
                    message: "Phone no. cannot be blank",
                  },
                ]}
              >
                <Input placeholder="Phone No." disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nationality_id"
                label={<div className={styles.label}>Nationality</div>}
                rules={[
                  {
                    required: true,
                    message: "Country must be selected",
                  },
                ]}
              >
                <Select
                  placeholder="Please select a country"
                  className={styles.select_container}
                  bordered={false}
                  onChange={onCountryChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {map(countries, (item) => renderCountryOptions(item))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" style={{ marginTop: 30 }}>
            <div className={styles.secondary_btn}>
              <Button className="secondary_btn" htmlType="submit">
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </div>

      <div className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>CHANGE PASSWORD</div>
        </Row>
        <Form
          ref={updateProfileFormPasswordRef}
          layout="vertical"
          requiredMark="optional"
          onFinish={onFinishChangePassword}
          className={styles.input_container}
        >
          {/* <div className={styles.subTitleText}>Change Password</div> */}
          <Form.Item
            name="old_password"
            className={styles.input}
            label={<div className={styles.label}>Old Password</div>}
            rules={[
              {
                required: true,
                message: "Please enter your old password",
              },
            ]}
          >
            <div className={styles.password_input}>
              <Input.Password
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 18,
                    width: 18,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                    position: "absolute",
                    right: 8,
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="Old Password"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="new_password"
            label={<div className={styles.label}>New Password</div>}
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              {
                min: 8,
                message: "Password must have a minimum length of 8",
              },
              {
                pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$",
                message:
                  "Password must have at least one uppercase letter, one lowercase letter and one number",
              },
            ]}
          >
            <div className={styles.password_input}>
              <Input.Password
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 18,
                    width: 18,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                    position: "absolute",
                    right: 8,
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="New Password"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={<div className={styles.label}>Confirm Password</div>}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please confirm your password",
            //   },
            // ]}
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match"
                  );
                },
              }),
            ]}
          >
            <div className={styles.password_input}>
              <Input.Password
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 18,
                    width: 18,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                    position: "absolute",
                    right: 8,
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="Confirm Password"
              />
            </div>
          </Form.Item>
          <Row justify="end">
            <div className={styles.secondary_btn}>
              <Button htmlType="submit" className="secondary_btn">
                Save Changes
              </Button>
            </div>
          </Row>
          <Divider style={{ fontSize: 14, color: "#bfc9d4" }}>
            Two Factor Authentication (TFA)
          </Divider>
          <div className={styles.subTitleText}>
            Two Factor Authentication (TFA)
          </div>

          <div className={styles.label}>Enable TFA</div>
          <div className={styles.switch}>
            <Switch
              checked={isTfaEnabled}
              onChange={(event) => handleSwitch(event)}
            />
          </div>
          {isFirstTfa && (
            <div>
              <div className={styles.label}>
                Reset Two Factor Authentication
              </div>
              <div className={styles.reset_button}>
                <Button onClick={showResetModal} className="danger_btn">
                  Reset
                </Button>
              </div>
            </div>
          )}

          <br />
        </Form>
      </div>

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
        }}
        visible={isModalVisible}
        closable={false}
        onOk={() => setIsEnableTFA(true)}
        onCancel={cancelTFA}
        cancelButtonProps={{
          style: {
            display: isEnableTFA
              ? "none"
              : isResetStatus
              ? "none"
              : "inline-block",
            width: "30%",
          },
          className: "default_btn",
        }}
        okButtonProps={{
          style: {
            display: isEnableTFA
              ? "none"
              : isResetStatus
              ? "none"
              : "inline-block",
            width: "30%",
          },
          className: "secondary_btn",
        }}
      >
        {isResetStatus || (isEnableTFA && qrcode) ? (
          <Row justify="center" align="center">
            <Col>
              <div className={styles.leftContainer}>
                <div className={styles.bold}>
                  Set up via third party authenticator
                </div>
                Please use your authentication app (such as Duo or Google
                Authentication) to scan this QR code
              </div>
            </Col>
            <br />
            <br />
            <Col>
              <div className={styles.centerContainer}>
                <img style={{ width: 200, height: 200 }} src={qrcode} />
                <div>Set up on same device</div>
                <br />
                <div className={styles.leftContainer}>
                  <div className={styles.bold}>Enter verification code</div>
                  Please enter the verification code you see on your
                  authentication app
                </div>

                <AuthCode
                  allowedCharacters="numeric"
                  onChange={handleOnChange}
                  inputClassName={
                    isWrongTfaCode
                      ? styles.authWrongCodeStyle
                      : styles.authCodeStyle
                  }
                  containerClassName={styles.authCodeContainerStyle}
                  ref={AuthInputRef}
                />
              </div>
              {isWrongTfaCode && (
                <Row justify="center" align="center">
                  <div className={styles.unmatchCodeErrorText}>
                    Invalid code
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        ) : (
          <Row justify="center" align="center">
            <Col>
              <div className={styles.modal_title}>
                Do you confirm to enable Two Factor Authentication (TFA)?
              </div>
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
        }}
        visible={isDisableTfa}
        closable={false}
        onOk={confirmDisableTfa}
        onCancel={cancelDisableTfa}
      >
        <strong>
          Are you sure to disable Two Factor Authentication (TFA)?
        </strong>
      </Modal>
      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={isResetModalVisible}
        closable={false}
        onOk={handleResetOk}
        onCancel={handleResetCancel}
        okText="Reset"
        cancelButtonProps={{
          style: { display: "inline-block", width: "30%" },
          className: "default_btn",
        }}
        okButtonProps={{
          style: { display: "inline-block", width: "30%" },
          className: "secondary_btn",
        }}
      >
        <div className={styles.modal_title}>
          Reset Two Factor Authentication?
        </div>
      </Modal>

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={isChangePwdModalVisible}
        closable={false}
        onOk={handleChangePasswordModal}
        // onCancel={handleResetCancel}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        okButtonProps={{
          style: { display: "inline-block" },
        }}
      >
        <strong>Password Changed</strong>
      </Modal>
    </>
  );
};

export default Profile;
