import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
// ============
import styles from "./styles.module.less";
import { getAllBanks, updateBankBranch } from "../../store/bank";
import { getAllCountries } from "../../store/country";
import { map } from "lodash";

const { Option } = Select;

const BanksByCountryEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state: bankCountryData } = location;
  const [countries, setCountries] = useState([]);
  const [bank, setBank] = useState([]);

  const { params } = useMatch("banks-by-country-edit/:id");
  const formRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const countriesPayload = await dispatch(getAllCountries()).unwrap();
      const { data: countryData } = countriesPayload;

      setCountries(countryData);

      const bankPayload = await dispatch(getAllBanks()).unwrap();

      const { data: bankData } = bankPayload;

      setBank(bankData);
    };
    fetchData();
  }, []);
  useEffect(() => {
    formRef.current.setFieldsValue({
      branch_name: bankCountryData.branch_name,
      country: bankCountryData.bank_country_id,
      bank: bankCountryData.bank_id,
    });
  }, [bankCountryData]);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const { data } = await dispatch(
          updateBankBranch({
            bank_country_id: params.id,
            country_id: values.country,
            bank_id: values.bank,
            branch_name: values.branch_name,
          })
        ).unwrap();
        message.success(data.message);
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>BANK INFORMATION</div>
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <div className={styles.select_container}>
            <Form.Item
              name="bank"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Bank name cannot be empty",
                },
              ]}
            >
              <Select
                name="bank"
                // disabled
                // value={selectedBank}
                placeholder="Select Bank"
                // onChange={banksOnChange}
              >
                {map(bank, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="branch_name"
              label={<div className={styles.label}>Branch Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the branch name",
                },
              ]}
            >
              <Input placeholder="Branch Name" />
            </Form.Item>
          </div>

          <div className={styles.select_container}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty",
                },
              ]}
            >
              <Select
                name="country"
                // value={selectedCountry}
                placeholder="Select Country"
                // disabled
                // onChange={countriesOnChange}
                showSearch
                // disabled={params?.id}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(countries, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Save Changes
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BanksByCountryEdit;
