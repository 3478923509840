import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Row,
  Image,
  Input,
  Table,
  Menu,
  Dropdown,
  Select,
  Modal,
  Tag,
  message,
  Space,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { debounce, reject, find, findIndex } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAbility } from "@casl/react";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { assignCaseToUser, getDashboardUserLists } from "@store/app";
import { getReloadCases } from "../../store/reload";
import AssignUserPopUp from "../../components/assign-user-popup";
import { AbilityContext } from "@configs/can";
import { WebSocketContext } from "@configs";

const { Option } = Select;

const Reload = () => {
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [text, setText] = useState("");
  const [refId, setRefId] = useState("");
  const [selectedDashboardUser, setSelectedDashboardUser] = useState("");
  const [dashboardUser, setDashboardUser] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const webSocket = useContext(WebSocketContext);

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const addData = (data) => {
    setTableData((state) => {
      const loadedData = [...state];
      loadedData.unshift(data);
      return loadedData;
    });
  };

  const removeData = (data) => {
    setTableData((state) => {
      const loadedData = [...state];
      return reject(loadedData, ["id", data.id]);
    });
  };

  const updateData = (data) => {
    setTableData((state) => {
      const loadedData = [...state];

      const caseIndex = findIndex(loadedData, ["id", data.id]);
      if (caseIndex !== -1) {
        let caseFound = find(loadedData, ["id", data.id]);
        caseFound = {
          ...caseFound,
          ...data.data,
        };

        let newData = loadedData;
        newData[caseIndex] = caseFound;

        return newData;
      } else {
        return loadedData;
      }
    });
  };

  useEffect(() => {
    if (webSocket) {
      webSocket.on("add-reload", (arg) => {
        addData(arg);
      });

      webSocket.on("remove-reload", (arg) => {
        removeData(arg);
      });

      webSocket.on("update-reload", (arg) => {
        updateData(arg);
      });
    }

    return () => {
      if (webSocket) {
        webSocket.off("add-reload");
        webSocket.off("remove-reload");
        webSocket.off("update-reload");
      }
    };
  }, [webSocket]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
    fetchDashboardUserData();
  }, []);

  const fetchDashboardUserData = async () => {
    try {
      const { data } = await dispatch(getDashboardUserLists()).unwrap();
      setDashboardUser(data);
    } catch (error) {
      message.error(error.message);
    }
  };

  const editOnClick = async (item) => {
    try {
      const data = await dispatch(
        assignCaseToUser({
          ref_id: item.ref_id,
          type: "reloads",
          action: "assign",
        })
      );

      if (!data.error) {
        navigate(`/reload/${item.ref_id}`);
      } else {
        message.error(data.error.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const assignCaseOnClick = async (item) => {
    setIsModalVisible(true);
    setRefId(item.ref_id);
  };

  const assignCase = async () => {
    try {
      setIsLoading(true);

      const { data } = await dispatch(
        assignCaseToUser({
          ref_id: refId,
          type: "reloads",
          action: "move",
          assigned_user_id: selectedDashboardUser,
        })
      ).unwrap();

      setIsConfirmModalVisible(false);
      if (data) {
        setIsLoading(false);
        fetchData();
        message.success(data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      let reload = await dispatch(getReloadCases({})).unwrap();

      let { data, total } = reload.data;

      setTableData(data);
      setTableTotalData(total);
      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(
          setTableSearch({
            search: text,
          })
        );
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      width: "5%",
      key: "id",
      sorter: true,
      render: (_, record) => {
        return (
          <>
            <div style={{ textAlign: "center" }}>{record.ref_id}</div>
          </>
        );
      },
    },
    {
      title: "From",
      dataIndex: "from",
      width: "20%",
      render: (_, record) => {
        const { user_username, assigned_user, user_email, user_phone_no } =
          record;
        return (
          <div>
            <div>
              {user_username}{" "}
              {assigned_user ? (
                <Tag color="#2db7f5">{assigned_user.username}</Tag>
              ) : (
                <></>
              )}
            </div>
            <div style={{ color: "#888ea8", fontSize: 12 }}>{user_email}</div>
            <div style={{ color: "#888ea8", fontSize: 12 }}>
              {user_phone_no}
            </div>
          </div>
        );
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      align: "center",
      width: "10%",
      key: "currency",
      sorter: true,
      render: (_, record) => {
        const { reload_currency_iso_code } = record;

        return <div>{reload_currency_iso_code}</div>;
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "20%",
      render: (_, record) => {
        // const { formatted_amount } = record;
        const { amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {(Math.round(record.amount * 100) / 100).toFixed(2)}
          </div>
        );
      },
      sorter: true,
    },
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      width: "20%",
      align: "center",
      sorter: true,
      render: (_, record) => {
        const { bank_branch_name } = record;
        return <div>{bank_branch_name}</div>;
      },
    },
    {
      title: "Receipt Reference",
      dataIndex: "reference",
      width: "20%",
      align: "center",
      render: (_, record) => {
        const { reference_no } = record;

        return <div>{reference_no}</div>;
      },
    },
    {
      title: "Receipt Photo",
      dataIndex: "photo",
      align: "center",
      width: "15%",
      render: (_, record) => {
        const { file_url } = record;

        return (
          <div>
            <Image style={{ width: 50, height: 50 }} src={file_url} />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "Left",
      width: "10%",
      render: (_, record) => {
        return (
          <div>
            <Space size="middle">
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#4361ee" }}
                  onClick={() => editOnClick(record)}
                />
              </Tooltip>

              <Tooltip title="Assign Case">
                <UserAddOutlined
                  style={{ fontSize: "15px", color: "#4361ee" }}
                  onClick={() => assignCaseOnClick(record)}
                />
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  const renderOption = (item, index) => {
    return (
      <Option key={index} value={item.id}>
        {item.username}
      </Option>
    );
  };

  const handleAssignModalOnOk = () => {
    setIsModalVisible(false);
    setIsConfirmModalVisible(true);
  };

  const handleAssignModalOnCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let key = sorter.columnKey;
      let field = sorter.field;

      if (key === "currency") {
        field = "reload_currency_iso_code";
      } else if (key === "amount") {
        field = "amount";
      } else if (key === "bank") {
        field = "bank_name";
      }

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row align="middle">
          <div className="table_search_input">
            {/* <Input
              placeholder="Search..."
              suffix={<SearchOutlined className={styles.search_icon} />}
              allowClear
              onChange={(e) => {
                setText(e.target.value);
                onChangeText(e.target.value);
              }}
            /> */}
          </div>
        </Row>
      </div>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
        }}
      />

      <AssignUserPopUp
        visible={isModalVisible}
        data={dashboardUser}
        handleOnOk={handleAssignModalOnOk}
        handleOnCancel={handleAssignModalOnCancel}
        renderOption={renderOption}
        onChange={(item) => {
          setSelectedDashboardUser(item);
        }}
      />

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={isConfirmModalVisible}
        closable={false}
        onOk={assignCase}

        confirmLoading={isLoading}
        onCancel={() => {
          setIsConfirmModalVisible(false);
        }}
        okText="Confirm"
        cancelButtonProps={{
          style: { display: "inline-block" },
        }}
        okButtonProps={{
          style: { display: "inline-block" },
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 14, marginBottom: 18 }}>
          Do you confirm to assign case to this user?
        </div>
      </Modal>
    </div>
  );
};

export default Reload;
