import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

// ============
import app from "./app";
import country from "./country";
import ui from "./ui";
import bank from "./bank";
import currency from "./currency";
import transfer from "./transfer";
import reload from "./reload";
import notification from "./notification";
import exchange from "./exchange";
import transaction from "./transaction";
import faq from "./faq";
import user from "./user";

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_ENCRYPT_KEY,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const rootReducer = combineReducers({
  app,
  country,
  ui,
  bank,
  currency,
  transfer,
  reload,
  notification,
  exchange,
  transaction,
  faq,
  user,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
