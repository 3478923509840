import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler, queryBuilder } from "../configs";

const initialState = {
  selectedBank: {},
  selectedBankAccount: {},
  banks: []
};

export const retrieveBanks = createAsyncThunk(
  "bank/retrieveBanks",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("banks", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveBanksWithCurrency = createAsyncThunk(
  "bank/retrieveBanksWithCurrency",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "banks/bank-currency";

      if (paginate) {
        url = queryBuilder("banks/bank-currency", getState().ui.table, filter);
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getAllBanks = createAsyncThunk(
  "country/getAllBanks",
  async (_) => {
    try {
      const { data } = await axios.get("banks/list");
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveBankBranchesWithAccounts = createAsyncThunk(
  "bank/retrieveBankBranchesWithAccounts",
  async ({ country, currency }) => {
    try {
      const { data } = await axios.get(
        `cases/bank-branch-accounts?country=${country}&currency=${currency}`
      );

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveBankBranches = createAsyncThunk(
  "bank/retrieveBankBranches",
  async ({ country, currency }) => {
    try {
      const { data } = await axios.get(
        `cases/bank-branch?country=${country}&currency=${currency}`
      );

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveBanksByCountry = createAsyncThunk(
  "bank/retrieveBanksByCountry",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("banks/banks-by-country", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const retrieveBankAccountsByCountry = createAsyncThunk(
  "bank/retrieveBankAccountsByCountry",
  async (_, { getState }) => {
    try {
      const url = queryBuilder("banks/bank-accounts", getState().ui.table);

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const addBank = createAsyncThunk("bank/addBank", async (values) => {
  try {
    const { bank_name, currencies } = values;

    let dataTemp = JSON.stringify({
      name: bank_name.trim(),
      currencies: currencies
    });

    const { data } = await axios.post("banks/add", dataTemp, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });

    return {
      data
    };
  } catch (error) {
    throw errorHandler.generateErrorString(error);
  }
});

export const deleteBank = createAsyncThunk(
  "bank/deleteBank",
  async ({ id }) => {
    try {
      const { data } = await axios.delete(`banks/${id}`);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateBank = createAsyncThunk(
  "bank/updateBank",
  async (values) => {
    try {
      const { id, bank_name, currencies } = values;

      let dataTemp = JSON.stringify({
        name: bank_name.trim(),
        currencies: currencies
      });

      console.log(dataTemp);

      const { data } = await axios.put(`banks/${id}`, dataTemp, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllBankAccounts = createAsyncThunk(
  "bank/findAllBankAccounts",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "banks/all-bank-accounts";

      if (paginate) {
        url = queryBuilder(
          "banks/all-bank-accounts",
          getState().ui.table,
          filter
        );
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findAllSendBankAccounts = createAsyncThunk(
  "bank/findAllBankAccounts",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "banks/all-bank-accounts/sends";

      if (paginate) {
        url = queryBuilder(
          "banks/all-bank-accounts/sends",
          getState().ui.table,
          filter
        );
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findBankCountryByCountryId = createAsyncThunk(
  "bank/findBankCountryByCountryId",
  async ({ countries_id }) => {
    try {
      const { data } = await axios.get(
        `banks/bank-accounts-country?country_id=${countries_id}`
      );

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createBankAccount = createAsyncThunk(
  "bank/createBankAccount",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("acc_name", values.acc_name);
      formData.append("acc_no", values.acc_no);
      formData.append("bank_id", values.bank_id);
      formData.append("country_id", values.country_id);
      formData.append("currency_id", values.currency_id);
      formData.append("status", values.status);
      formData.append("minimum_amount", values.minimum_amount);
      formData.append("maximum_amount", values.maximum_amount);

      const { data } = await axios.post("banks/add-acc", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateBankAccount = createAsyncThunk(
  "bank/updateBankAccount",
  async (values) => {
    try {
      let payload = {
        acc_no: values.acc_no,
        acc_name: values.acc_name.trim(),
        bank_account_id: values.bank_account_id,
        currency_id: values.currency_id,
        bank_country_id: values.bank_country_id,
        status: values.status
      };

      const { data } = await axios.patch(`banks/update-acc`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateBankAccountById = createAsyncThunk(
  "bank/updateBankAccountById",
  async (values) => {
    try {
      let payload = {
        bank_account_id: values.bank_account_id,
        acc_no: values.acc_no,
        acc_name: values.acc_name.trim(),
        bank_id: values.bank_id,
        top_up_status: values.top_up_status,
        send_status: values.send_status,
        minimum_amount: values.minimum_amount,
        maximum_amount: values.maximum_amount
      };

      const { data } = await axios.patch(
        `banks/bank-accounts/update`,
        payload,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const addBankBranch = createAsyncThunk(
  "bank/addBankBranch",
  async (values) => {
    try {
      let formData = new FormData();

      formData.append("bank_id", values.bank_id);
      formData.append("branch_name", values.branch_name.trim());
      formData.append("country_id", values.country_id);

      const { data } = await axios.post("banks/add-branch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateBankBranch = createAsyncThunk(
  "bank/updateBankBranch",
  async (values) => {
    try {
      let payload = {
        bank_id: values.bank_id,
        branch_name: values.branch_name.trim(),
        country_id: values.country_id,
        bank_country_id: values.bank_country_id
      };

      const { data } = await axios.patch(`banks/update-branch`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const updateExchangeRateById = createAsyncThunk(
  "exchange/updateExchangeRateById",
  async ({ id, values }) => {
    try {
      let payload = {
        from_currency_id: values.from_currency,
        to_currency_id: values.to_currency,
        rate: values.from_currency,
        date: values.date
      };

      const { data } = await axios.put(`exchange-rates/${id}`, payload, {
        headers: {
          Accept: "application/json"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveCountriesByBanks = createAsyncThunk(
  "banks/countries",
  async (_) => {
    try {
      const { data } = await axios.get("banks/countries");
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getFlaggedBankAccounts = createAsyncThunk(
  "banks/getFlaggedBankAccounts",
  async (_, { getState }) => {
    try {
      const url = queryBuilder(
        "banks/flagged-bank-accounts",
        getState().ui.table
      );

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const getAvailableBankAccounts = createAsyncThunk(
  "banks/getFlaggedBankAccounts",
  async (_, { getState }) => {
    try {
      const url = queryBuilder(
        "banks/available-bank-accounts",
        getState().ui.table
      );

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setSelectedBank: (state, { payload }) => {
      state.selectedBank = payload.selectedBank;
    },
    setSelectedBankAccount: (state, { payload }) => {
      state.selectedBankAccount = payload.selectedBankAccount;
    },
    resetBank: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder.addCase(retrieveBanks.fulfilled, (state, { payload }) => {
      // state.loading = false;
      state.banks = payload.data.data;
    });
  }
});

export const { setSelectedBank, setSelectedBankAccount, resetBank } =
  bankSlice.actions;

export default bankSlice.reducer;
