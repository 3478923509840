import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  table: {
    current: 1,
    pageSize: 10,
    sort: null,
    search: null,
    from_date: null,
    to_date: null,
  },
  notification: {
    current: 1,
  },
  userHistory: {
    current: 1,
    pageSize: 20,
  },
  contentHeight: 0,
  contentWidth: 0,
  siderMenuSelectedKey: 1,
};

// Then, handle actions in your reducers:
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setContentHeight: (state, { payload }) => {
      state.contentHeight = payload.contentHeight;
    },
    setContentWidth: (state, { payload }) => {
      state.contentWidth = payload.contentWidth;
    },
    setTablePagination: (state, { payload }) => {
      state.table.current = payload.current;
      state.table.pageSize = payload.pageSize;
    },
    setTableSort: (state, { payload }) => {
      state.table.sort = payload.sort;
    },
    setTableSearch: (state, { payload }) => {
      state.table.search = payload.search;
    },
    setTableFilter: (state, { payload }) => {
      //All table related filtering can put here
      state.table = {
        ...state.table,
        from_date: payload.from_date,
        to_date: payload.to_date,
      };
    },
    resetTableSort: (state) => {
      state.table.sort = null;
    },
    resetTableSearch: (state) => {
      state.table.search = null;
    },
    resetTableFilter: (state) => {
      state.table = {
        ...state.table,
        from_date: null,
      };
    },
    resetTablePagination: (state) => {
      state.table.current = initialState.table.current;
      // state.table.pageSize = initialState.table.pageSize;
    },
    setNotificationCurrentPage: (state, { payload }) => {
      state.notification.current = payload.current;
    },
    resetNotificationPage: (state) => {
      state.notification.current = 1;
    },
    setUserHistoryCurrentPage: (state, { payload }) => {
      state.userHistory.current = payload.current;
    },
    resetUserHistoryPage: (state) => {
      state.userHistory.current = 1;
    },
    resetUi: () => {
      return {
        ...initialState,
      };
    },
    setSiderMenuSelectedKey: (state, { payload }) => {
      state.siderMenuSelectedKey = payload.siderMenuSelectedKey;
    },
  },
});

export const {
  setContentHeight,
  setContentWidth,
  setTablePagination,
  setTableSort,
  setTableSearch,
  setTableFilter,
  resetTableSort,
  resetTableSearch,
  resetTableFilter,
  resetTablePagination,
  setNotificationCurrentPage,
  setUserHistoryCurrentPage,
  resetNotificationPage,
  resetUserHistoryPage,
  resetUi,
  setSiderMenuSelectedKey,
} = uiSlice.actions;

export default uiSlice.reducer;
