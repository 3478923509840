import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Row,
  Input,
  Table,
  Button,
  Dropdown,
  Tooltip,
  Space,
  Menu,
  Modal,
  Select,
  Tag,
  Form,
  Col,
  message,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { debounce, reject, find, findIndex, map } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useAbility } from "@casl/react";
import numeral from "numeral";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "@store/ui";
import { assignCaseToUser, findAllDashboardUsers } from "@store/app";
import { findAllPendingTransfers } from "@store/transfer";
import AssignUserPopUp from "@components/assign-user-popup";
import TableHeader from "@components/table-header";
import { AbilityContext } from "@configs/can";
import { WebSocketContext } from "@configs";
import { findCurrencyFilterOptionsByType } from "@store/currency";
import { findCountryFilterOptionsByType } from "@store/country";
import { findAllCaseAssignee } from "@store/user";

const { Option } = Select;
const { RangePicker } = DatePicker;

const SendBankDepositPending = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const webSocket = useContext(WebSocketContext);
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (webSocket) {
  //     webSocket.on("add-transfer", (arg) => {
  //       addData(arg);
  //     });

  //     webSocket.on("remove-transfer", (arg) => {
  //       removeData(arg);
  //     });

  //     webSocket.on("update-transfer", (arg) => {
  //       updateData(arg);
  //     });
  //   }

  //   return () => {
  //     if (webSocket) {
  //       webSocket.off("add-transfer");
  //       webSocket.off("remove-transfer");
  //       webSocket.off("update-transfer");
  //     }
  //   };
  // }, [webSocket]);

  const location = useLocation();

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
    fetchDashboardUsers();
  }, []);

  const fetchDashboardUsers = async () => {
    try {
      const { data } = await dispatch(
        findAllCaseAssignee({ paginate: false })
      ).unwrap();
      const dashboardUser = map(data, (item) => {
        return {
          value: item.username,
          label: item.username,
        };
      });
      setDashboardUsers(dashboardUser);
    } catch (error) {
      message.error(error.message);
    }
  };

  const editOnClick = async (item) => {
    if (ability.can("update", "Transfer")) {
      if (item.status === 1) {
        navigate(`/send/bank/${item.ref_id}?mode=edit`);
        // if (ability.cannot("update", "Transfer")) {
        try {
          await dispatch(
            assignCaseToUser({
              ref_id: item.ref_id,
              type: "transfers",
              action: "assign",
            })
          ).unwrap();
        } catch (error) {
          message.error(error.message);
        }
        // }
      } else {
        navigate(`/send/bank/${item.ref_id}?mode=edit`);
      }
    } else {
      navigate(`/send/bank/${item.ref_id}?mode=view`);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // if (filter != null) {
      //   filter["localTimeOffset"] = localTimeOffset;
      // }
      const { data } = await dispatch(
        findAllPendingTransfers({ filter })
      ).unwrap();
      const { data: transferData, total } = data;

      let { data: countryFilterOptions } = await dispatch(
        findCountryFilterOptionsByType({ type: "transfers" })
      ).unwrap();

      countryFilterOptions = map(countryFilterOptions, (item) => {
        return {
          label: item.name,
          value: item.iso_code,
        };
      });

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "transfers" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code,
        };
      });

      setCountries(countryFilterOptions);
      setCurrencies(currencyFilterOptions);

      setTableData(transferData);
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const resetButtonOnClick = () => {
  //   dispatch(resetTablePagination());
  //   dispatch(resetTableFilter());
  //   setFilter({});
  //   fetchData();
  // };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "left",
      width: 110,
      render: (_, record) => {
        return (
          <>
            <div style={{ textAlign: "left" }}>{record.doc_id}</div>
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Submission</div>
            <div>Date (GMT +7)</div>
          </span>
        );
      },
      dataIndex: "created_at",
      key: "submission_date",
      width: 200,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;

        return (
          <>
            <div style={{ textAlign: "left" }}>
              {created_at != null
                ? moment
                    .utc(created_at)
                    .tz("Asia/Phnom_Penh")
                    .format("DD-MM-YYYY, hh:mm:ss A")
                : "-"}
            </div>
          </>
        );
      },
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Sender</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      align: "left",
      width: 110,
      dataIndex: "user_topkash_id",
      key: "topkash_id",
      render: (_, record) => {
        const { user_topkash_id } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {user_topkash_id ? user_topkash_id : "-"}
          </div>
        );
      },
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Sender</div>
            <div>Username</div>
          </span>
        );
      },
      align: "center",
      width: 120,
      dataIndex: "user_username",
      key: "username",
      render: (_, record) => {
        const { user_username } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {user_username ? user_username : "-"}
          </div>
        );
      },
    },

    {
      title: (
        <>
          <div>From</div>
          <div>Currency</div>
        </>
      ),
      align: "right",
      dataIndex: "to_currency_code",
      key: "currency",
      key: "bank",
      width: 80,

      render: (_, record) => {
        const { from_currency_code } = record;

        return (
          <div style={{ textAlign: "right" }}>
            {from_currency_code ? from_currency_code : "-"}
          </div>
        );
      },
    },

    {
      title: (
        <>
          <div>From</div>
          <div>Amount</div>
        </>
      ),
      align: "right",
      width: 150,
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        const { amount } = record;
        return (
          <>
            <div style={{ textAlign: "right" }}>
              {numeral(parseFloat(amount)).format("0,0.00")}
            </div>
          </>
        );
      },
      //
    },

    {
      title: "Fees",
      dataIndex: "processing_fee",
      align: "right",
      key: "processing_fee",
      width: 100,
      render: (_, record) => {
        const { processing_fee } = record;

        let fee = processing_fee;
        if (parseFloat(processing_fee) === 0.0) {
          fee = "-";
        } else {
          fee = processing_fee;
        }

        return (
          <>
            <div style={{ textAlign: "right" }}>
              {parseFloat(fee) > 0
                ? numeral(parseFloat(fee)).format("0,0.00")
                : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <span>
            <div>Amount </div>
            <div>To Convert</div>
          </span>
        );
      },
      dataIndex: "amount",
      align: "right",
      key: "amount",
      width: 100,
      render: (_, record) => {
        const { amount, processing_fee } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(amount - processing_fee) > 0
              ? numeral(parseFloat(amount - processing_fee)).format("0,0.00")
              : "-"}
          </div>
        );
      },
    },

    {
      title: () => {
        return (
          <span>
            <div>Exchange </div>
            <div>Rate</div>
          </span>
        );
      },
      width: 150,
      dataIndex: "exchange_rate",
      align: "right",
      key: "exchange_rate",
      render: (_, record) => {
        const { exchange_rate, from_currency_code, to_currency_code } = record;

        let rate = exchange_rate;
        if (from_currency_code === to_currency_code) {
          rate = "1.000000";
        }

        return (
          <div style={{ textAlign: "right" }}>
            {rate ? numeral(parseFloat(rate)).format("0,0.000000") : "-"}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <span>
            <div>To</div>
            <div>Currency</div>
          </span>
        );
      },
      dataIndex: "currency",
      align: "right",
      key: "currency",
      width: 100,
      render: (_, record) => {
        const { to_currency_code } = record;
        return <div style={{ textAlign: "right" }}>{to_currency_code}</div>;
      },
    },
    {
      title: () => {
        return (
          <span>
            <div>To</div>
            <div>Amount</div>
          </span>
        );
      },
      dataIndex: "to_amount",
      align: "right",
      key: "to_amount",
      width: 100,
      render: (_, record) => {
        const { converted_amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {numeral(parseFloat(converted_amount)).format("0,0.00")}
          </div>
        );
      },
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Country</div>
          </span>
        );
      },
      dataIndex: "country_name",
      align: "left",
      key: "country_name",
      width: 120,
      render: (_, record) => {
        return <div>{record.country_name ? record.country_name : "-"}</div>;
      },
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank</div>
          </span>
        );
      },
      dataIndex: "bank_name",
      align: "left",
      key: "bank_name",
      width: 120,
      render: (_, record) => {
        return <div>{record.bank_name ? record.bank_name : "-"}</div>;
      },
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Acc No</div>
          </span>
        );
      },
      dataIndex: "reference_bank_account_no",
      align: "left",
      key: "reference_bank_account_no",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record.reference_bank_account_no
              ? record.reference_bank_account_no
              : "-"}
          </div>
        );
      },
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Acc Name</div>
          </span>
        );
      },
      dataIndex: "reference_bank_account_name",
      align: "left",
      key: "reference_bank_account_name",
      width: 120,
      render: (_, record) => {
        return (
          <div>
            {record.reference_bank_account_name
              ? record.reference_bank_account_name
              : "-"}
          </div>
        );
      },
    },

    {
      title: "Assigned To",
      align: "left",
      width: 120,
      dataIndex: "assigned_user_username",
      key: "assigned_to",
      render: (_, record) => {
        const { assigned_user_username } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {assigned_user_username ? assigned_user_username : "-"}
          </div>
        );
      },
    },

    {
      title: "Status",
      align: "center",
      fixed: "right",
      width: 90,
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status;
        let color;
        let fontWeight;

        if (record.status === 3) {
          status = "Successful";
          color = "#339933";
        } else if (record.status === 5) {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.status === 2) {
          status = "Processing";
          color = "#4f8bc2";
        } else {
          status = "Pending";
          color = "#ff8000";
          fontWeight = 700;
        }

        return <div style={{ color, fontWeight }}>{status}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 90,
      fixed: "right",
      render: (_, record) => {
        if (ability.can("update", "Transfer")) {
          return (
            <div>
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => editOnClick(record)}
                  >
                    Edit
                  </Button>
                </Tooltip>
              </Space>
            </div>
          );
        } else {
          return (
            <div>
              <Space size="middle">
                <Tooltip title="View">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => editOnClick(record)}
                  >
                    View
                  </Button>
                </Tooltip>
              </Space>
            </div>
          );
        }
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      // let key = sorter.columnKey;
      // let field = sorter.field;

      // if (key === "country") {
      //   field = "transfer_currency_iso_code";
      // } else if (key === "amount") {
      //   field = "amount";
      // } else if (key === "bank") {
      //   field = "recipient_bank_name";
      // }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Pending Sends"
        showAdditionalNote={true}
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            {/* <Col span="24"> */}
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Sender Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"username"} initialValue={null}>
                  <Input
                    placeholder="Sender Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"from_currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="From Currency"
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"to_currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="To Currency"
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"country"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Recipient Bank Country"
                    options={
                      countries && countries.length > 0 ? countries : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8" offset={8}>
                <Form.Item
                  name={"assigned_to"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Assigned To"
                    options={
                      dashboardUsers && dashboardUsers.length > 0
                        ? dashboardUsers
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* </Col> */}
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default SendBankDepositPending;
