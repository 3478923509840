import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
import { find, map, isEmpty } from "lodash";

// ============
import styles from "./styles.module.less";
import { getAllCountries } from "../../../store/country";
import {
  getAllBanks,
  updateBankAccountById,
  retrieveBanksByCountry,
  findBankCountryByCountryId
} from "../../../store/bank";
import {
  retrieveAllCurrencies,
  getCurrencyById
} from "../../../store/currency";
import HistoryTable from "../../../components/history-table";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
import { unformat, formatNumber } from "accounting-js";
import numeral from "numeral";

const { Option } = Select;

const BankAccountsEditAccount = () => {
  const { params } = useMatch("settings/bank-account/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [branch, setBranch] = useState([]);
  const [bank, setBank] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [bankAccountDetail, setBankAccountDetail] = useState({});

  const formRef = useRef();

  const selectedBank = useSelector((state) => state.bank.selectedBankAccount);

  const [selectedCountry, setSelectedCountry] = useState({
    id: null,
    name: "",
    iso_code: ""
  });
  const [historyData, setHistoryData] = useState([]);

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  // const [selectedBranch, setSelectedBranch] = useState({
  //   id: null,
  //   branch_name: "",
  //   bank_id: null,
  // });
  useEffect(() => {
    formRef.current.setFieldsValue({
      bank_account_id: selectedBank.bank_acc_id,
      account_name: selectedBank.acc_name,
      account_no: selectedBank.acc_no,
      currency_id: selectedBank.currency_id,
      minimum_amount: formatNumber(selectedBank.minimum_amount, {
        precision: 2
      }),
      maximum_amount: formatNumber(selectedBank.maximum_amount, {
        precision: 2
      }),
      top_up_status:
        selectedBank.top_up_status === 1
          ? statusOption[0].value
          : statusOption[1].value,
      send_status:
        selectedBank.send_status === 1
          ? statusOption[0].value
          : statusOption[1].value
    });
  }, [selectedBank]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve countries
        const countriesPayload = await dispatch(getAllCountries()).unwrap();
        const { data: countryData } = countriesPayload;
        setCountries(countryData);

        // Retrieve banks
        const bankPayload = await dispatch(getAllBanks()).unwrap();
        const { data: bankData } = bankPayload;
        setBank(bankData);

        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;

        // Get selected currency
        const { data: currencyDetails } = await dispatch(
          getCurrencyById({
            id: selectedBank.currency_id
          })
        ).unwrap();

        setCurrency(currencyDetails);

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "bank_accounts"
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    countriesOnChange(parseInt(selectedBank.countries_id));
  }, [countries]);

  useEffect(() => {
    formRef.current.setFieldsValue({
      country: selectedCountry.id
    });
  }, [selectedCountry]);

  useEffect(() => {
    formRef.current.setFieldsValue({
      bank: selectedBank.banks_id
    });
  }, [bank]);

  const countriesOnChange = (value) => {
    const selectCountry = find(countries, (item) => item.id === value);
    if (selectCountry) {
      setSelectedCountry({
        id: selectCountry.id,
        name: selectCountry.name,
        iso_code: selectCountry.iso_code_2
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (values) {
        let minimum_amount =
          unformat(values.minimum_amount.replace(/(\.|\,)/gi, "")) / 100;

        let maximum_amount =
          unformat(values.maximum_amount.replace(/(\.|\,)/gi, "")) / 100;

        await dispatch(
          updateBankAccountById({
            bank_account_id: parseInt(selectedBank.bank_acc_id),
            acc_no: values.account_no,
            acc_name: values.account_name,
            // country_id: values.country,
            // currency_id: parseInt(values.currency_id),
            // bank_country_id: values.branch_id,
            bank_id: parseInt(values.bank),
            top_up_status: parseInt(values.top_up_status),
            send_status: parseInt(values.send_status),

            minimum_amount,
            maximum_amount
          })
        ).unwrap();

        message.success("You have successfully update bank account");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const minAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const minimum_amount = unformat(text.replace(/(\.|\,)/gi, "")) / 100;
    const formattedAmount = formatNumber(minimum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      minimum_amount: formattedAmount
    });
  });

  const maxAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const maximum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(maximum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      maximum_amount: formattedAmount
    });
  });

  const accountNumberOnChange = async ({ target }) => {
    const { value } = target;

    let text = value;

    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/gi, "");
    formRef.current.setFieldValue("account_no", formattedPhoneNumber);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Edit Bank Account</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          // requiredMark={false}
          className="form-details"
        >
          <Form.Item name="top_up_status" label="Top-Up Status">
            <Select
              name="status"
              options={statusOption}
              defaultValue={"Active"}
            ></Select>
          </Form.Item>

          <Form.Item name="send_status" label="Send Status">
            <Select
              name="status"
              options={statusOption}
              defaultValue={"Active"}
            ></Select>
          </Form.Item>

          <Form.Item
            name="bank_account_id"
            label="Bank Acc ID"
            className={styles.section_detail}
          >
            {selectedBank.bank_acc_id}
          </Form.Item>

          <Form.Item
            name="country"
            label="Country"
            className={styles.section_detail}
          >
            {selectedBank.country_name}
          </Form.Item>

          <Form.Item
            name="currency"
            label="Currency"
            className={styles.section_detail}
          >
            {selectedBank.currency_code}
          </Form.Item>

          {/* <Form.Item
            name="country"
            label={<div className="label">Country</div>}
             
            rules={[
              {
                required: true,
                message: "Country cannot be empty",
              },
            ]}
          >
            <Select
              name="country"
              value={selectedCountry}
              placeholder="Select Country"
              onChange={countriesOnChange}
              showSearch
              disabled={params?.id}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(countries, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="currency_id"
            value={selectedBranch}
            label={<div className="label">Currency</div>}
             
            rules={[
              {
                required: true,
                message: "Branch name cannot be empty",
              },
            ]}
          >
            <Select
              name="currency_id"
              value={selectedBranch}
              placeholder="Select Currency"
              disabled={params?.id}
              // onChange={currencyOnChange}
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.currency_iso}
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}

          <Form.Item
            name="bank"
            label="Bank"
            rules={[
              {
                required: true,
                message: "Please select a bank name"
              }
            ]}
          >
            <Select
              name="bank"
              placeholder="Select Bank"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(bank, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="account_no"
            label="Account No."
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the account no.",
                validator: async (_, value) => {
                  let text = value;
                  if (text) {
                    if (typeof text === "number") {
                      text = value.toString();
                    }
                  } else {
                    throw new Error("Please enter a valid account no.");
                  }
                }
              },
              {
                pattern: /^[0-9]{1,34}$/i,
                message: "Account no. should not more than 34 digits"
              }
            ]}
          >
            <Input placeholder="Account No." onChange={accountNumberOnChange} />
          </Form.Item>

          <Form.Item
            name="account_name"
            label="Account Name"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the account name"
              }
            ]}
          >
            <Input placeholder="Account Name" />
          </Form.Item>
          <Form.Item
            label="Min Amount"
            name="minimum_amount"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the min amount"
              },
              {
                required: true,
                validator: async (_, value) => {
                  value = value ? unformat(value.replace(/(\,)/g, "")) : "";

                  if (value === 0) {
                    throw new Error("Please enter the min amount");
                  } else if (
                    parseFloat(value) <
                    numeral(parseFloat(currency?.minimum_amount)).format("0.00")
                  ) {
                    throw new Error(
                      `Minimum amount is ${numeral(
                        parseFloat(currency?.minimum_amount)
                      ).format("0,0.00")} ${currency?.iso_code}`
                    );
                  }
                }
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={(e) => {
                minAmountOnChange(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Max Amount"
            name="maximum_amount"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the max amount"
              },
              {
                validator: async (_, value) => {
                  let minAmount =
                    formRef.current.getFieldValue("minimum_amount");
                  minAmount = unformat(minAmount?.replace(/(\,)/g, ""));

                  value = value ? unformat(value.replace(/(\,)/g, "")) : null;

                  if (value === 0) {
                    throw new Error("Please enter the max amount");
                  } else if (parseFloat(value) <= minAmount) {
                    throw new Error(
                      "Max Amount must be higher than Min Amount"
                    );
                  }
                }
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={(e) => {
                maxAmountOnChange(e.target.value);
              }}
            />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate("/settings/bank-account")}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default BankAccountsEditAccount;
