import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Row, Button, Alert, message, Select } from "antd";
// ============
import styles from "./styles.module.less";
import { addBank } from "@store/bank";
import { retrieveAllCurrencies } from "../../../store/currency";
import { map } from "lodash";

const BankAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data: currencies } = await dispatch(
        retrieveAllCurrencies()
      ).unwrap();

      setCurrencyList(currencies);
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmit = async (values) => {
    if (values) {
      console.log(values);
      try {
        const { data } = await dispatch(
          addBank({
            bank_name: values.bank_name,
            currencies: values.currency
          })
        ).unwrap();

        message.success(data.message);
        navigate("/settings/bank");
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Bank</div>
        </Row>
        <Form
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          {/* <Form.Item
              name="bank_country"
              label={<div className={styles.label}>Select Bank Country</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the bank name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "55%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={countryList.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
              />
            </Form.Item> */}
          {/* <Form.Item
              name="branch_name"
              label={<div className={styles.label}>Branch Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the Branch name",
                },
              ]}
            >
              <Input placeholder="Branch name" />
            </Form.Item> */}
          <Form.Item
            name="bank_name"
            className="input"
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Please enter the bank name"
              }
            ]}
          >
            <Input placeholder="Bank name" />
          </Form.Item>
          <Form.Item
            name="currency"
            label={<div className={styles.label}>Currencies</div>}
            rules={[
              {
                required: true,
                message: "Please select at least one currency"
              }
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Select Currency"
            >
              {map(currencyList, (item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BankAdd;
