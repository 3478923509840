import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Form,
  Input,
  Row,
  Select,
  Button,
  message,
  Space,
  Divider,
  Table
} from "antd";
import { map } from "lodash";
import styles from "./styles.module.less";
import HistoryTable from "../../../components/history-table";
import { findCountryById, updateCountryById } from "@store/country";

const { Option } = Select;

const CountryEdit = () => {
  const { params } = useMatch("new-country/:id");
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();

  const selectedCountry = useSelector((state) => state.country.selectedCountry);
  const currencies = useSelector((state) => state.currency.currencies);

  useEffect(() => {
    let currencyIds = map(selectedCountry.currency, (item) => item.id);

    formRef.current.setFieldsValue({
      currency: currencyIds
    });
  }, []);

  const updateCountry = async (values) => {
    try {
      await dispatch(
        updateCountryById({
          id: params.id,
          currency_ids: values.currency
        })
      ).unwrap();
      message.success("You have update the country information");
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">View Country</div>
        </Row>

        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          // style={{
          //   maxWidth: 1000,
          // }}
          initialValues={{
            remember: true
          }}
          onFinish={(values) => {
            updateCountry(values);
          }}
          className="form-details"
        >
          <Form.Item name="status" label="Status" className="label-details">
            {selectedCountry.is_enabled === 1 ? "Active" : "Disabled"}
          </Form.Item>

          <Form.Item name="country" label="Country" className="label-details">
            <div>{`${selectedCountry.country_name} (${selectedCountry.country_iso_code})`}</div>
          </Form.Item>

          <Form.Item name="currency" label="Currencies">
            <Select
              name="currency"
              mode="multiple"
              showArrow
              placeholder="Select Currencies"
              disabled
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.currency_iso} - {item.currency_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16
            }}
          >
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Space>
                  <Button onClick={() => navigate(-1)}>Close</Button>
                  {/* <Button
                    type="default"
                    htmlType="submit"
                    className="ant-btn-green"
                  >
                    Save
                  </Button> */}
                </Space>
              </div>
            </Row>
          </Form.Item>
        </Form>
        {/* <HistoryTable /> */}
      </Card>
    </div>
  );
};

export default CountryEdit;
