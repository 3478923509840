import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Form, Row, Button, message, Select, Input } from "antd";
import { map } from "lodash";
// ============
import styles from "./styles.module.less";
import { createCommunicationChannel } from "../../../store/communication-channel";
const { Option } = Select;

const CommunicationChannelAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isUrl, setIsUrl] = useState(true);
  const [isEmail, setIsEmail] = useState(false);

  const formRef = useRef();

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" }
  ];

  const typeOption = [
    {
      value: "website",
      label: "Website"
    },
    {
      value: "email",
      label: "Email"
    },

    // Live Chat or any action for navigation
    {
      value: "other",
      label: "Other"
    }
  ];

  useEffect(() => {
    // set type and is_enabled initial value
    formRef.current.setFieldsValue({
      type: typeOption[0].value,
      is_enabled: statusOption[0].value
    });
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        const { data: res } = await dispatch(
          createCommunicationChannel({
            name: values.name,
            description: values.description,
            type: values.type,
            url: values.url ? values.url : null,
            email: values.email ? values.email : null,
            is_enabled: values.is_enabled
          })
        ).unwrap();

        message.success(res.message);
        navigate(-1);
      } else {
        console.error("Some required values are missing.");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Communication Channel</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            label="Channel Name"
            name="name"
            className="input"
            rules={[
              {
                required: true,
                message: "Channel Name cannot be blank"
              }
            ]}
          >
            <Input placeholder="Channel Name" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            className="input"
            extra={
              <div className="rules">
                Maximum 30 characters, this will appear as a title in App
              </div>
            }
            rules={[
              {
                required: true,
                message: "Description cannot be blank"
              },
              {
                required: true,
                validator: async (_, value) => {
                  console.log(value);
                  if (value) {
                    if (value.length > 30) {
                      throw new Error(
                        "The length should not exceeding 30 characters"
                      );
                    }
                  }
                }
              },
              {
                pattern: /^[a-zA-Z,_. ]*$/,
                message: "Please insert valid description"
              }
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                validator: async (_, value) => {
                  if (value === "website") {
                    setIsUrl(true);
                    setIsEmail(false);
                    formRef.current.setFieldsValue({
                      email: null
                    });
                  } else if (value === "email") {
                    setIsUrl(false);
                    setIsEmail(true);
                    formRef.current.setFieldsValue({
                      url: null
                    });
                  } else {
                    setIsUrl(false);
                    setIsEmail(false);
                    formRef.current.setFieldsValue({
                      url: null,
                      email: null
                    });
                  }
                }
              }
            ]}
          >
            <Select name="type">
              {map(typeOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {!isUrl && !isEmail ? null : isUrl && !isEmail ? (
            <Form.Item
              label="URL Link"
              name="url"
              className="input"
              rules={[
                {
                  required: true,
                  message: "URL Link cannot be blank"
                }
              ]}
            >
              <Input placeholder="URL" />
            </Form.Item>
          ) : (
            <Form.Item
              label="Email Address"
              name="email"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Email Address cannot be blank"
                },
                {
                  pattern: /^([\w-\.]+)@((?:[\w]+\.)+)([a-zA-Z]{2,4})$/,
                  message: "Please insert valid email address"
                }
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
          )}

          <Form.Item name="is_enabled" label="Status">
            <Select name="is_enabled">
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Create
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CommunicationChannelAdd;
