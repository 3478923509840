import { flatten } from "flat";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
// ============
import App from "../../App";
import Malay from "../../lang/ms.json";
import English from "../../lang/en.json";
import Chinese from "../../lang/cn.json";
import Bangladesh from "../../lang/bd.json";

const IntlApp = () => {
  const language = useSelector((state) => state.app.language);
  const [messages, setMessages] = useState(English);

  useEffect(() => {
    if (language === "en") {
      setMessages(English);
    } else if (language === "ms") {
      setMessages(Malay);
    } else if (language === "cn") {
      setMessages(Chinese);
    } else if (language === "bd") {
      setMessages(Bangladesh);
    }
  }, [language]);

  return (
    <IntlProvider locale={language} messages={flatten(messages)}>
      <App />
    </IntlProvider>
  );
};

export default IntlApp;
