import React, { useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  Select,
  Form,
  Col,
  message,
  DatePicker,
  Space,
  Tooltip,
  Button
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { map } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";

import moment from "moment-timezone";
import numeral from "numeral";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";

import { findAllAdjustment } from "@store/transaction";

import TableHeader from "@components/table-header";

import { findCurrencyFilterOptionsByType } from "@store/currency";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Adjustments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const [currencies, setCurrencies] = useState([]);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({});

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(findAllAdjustment({ filter })).unwrap();

      const { data: adjustmentData, total } = data;

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "all" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      setCurrencies(currencyFilterOptions);
      setTableData(adjustmentData);
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const viewOnClick = (item) => {
    navigate(`/adjustments/${item.id}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "left",
      width: 110,
      render: (_, record) => {
        return <>{<div style={{ textAlign: "left" }}>{record.doc_id}</div>}</>;
      }
    },
    {
      title: (
        <>
          <div>Adjustment</div>
          <div>Date (GMT +7)</div>
        </>
      ),
      dataIndex: "reason",
      key: "reason",
      fixed: "left",
      align: "left",
      width: 240,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {created_at != null
              ? moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : "-"}
          </div>
        );
      }
    },
    {
      title: (
        <>
          <div>Member</div>
          <div>Topkash ID</div>
        </>
      ),
      align: "left",
      width: 150,
      dataIndex: "topkash_id",
      key: "topkash_id",
      render: (_, record) => {
        return (
          <>
            <div style={{ textAlign: "left" }}>{record.topkash_id}</div>
          </>
        );
      }
    },

    {
      title: (
        <>
          <div>Member</div>
          <div>Username</div>
        </>
      ),
      align: "left",
      width: 150,
      dataIndex: "username",
      key: "username",
      render: (_, record) => {
        return (
          <>
            <div style={{ textAlign: "left" }}>{record.username}</div>
          </>
        );
      }
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      fixed: "left",
      align: "right",
      width: 110,
      render: (_, record) => {
        return (
          <>
            <div style={{ textAlign: "right" }}>{record.currency_code}</div>
          </>
        );
      }
    },
    {
      title: (
        <>
          <div>Amount</div>
          <div>Added</div>
        </>
      ),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (_, record) => {
        const { amount, adjustment_type } = record;

        let added_amount = amount;

        if (adjustment_type === "add") {
          added_amount = amount;
        } else {
          added_amount = "-";
        }

        return (
          <>
            <div style={{ textAlign: "right" }}>
              {parseFloat(added_amount) > 0
                ? numeral(parseFloat(added_amount)).format("0,0.00")
                : "-"}
            </div>
          </>
        );
      }
    },

    {
      title: (
        <>
          <div>Amount</div>
          <div>Deducted</div>
        </>
      ),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (_, record) => {
        const { amount, adjustment_type } = record;

        let deducted_amount = amount;

        if (adjustment_type === "deduct") {
          deducted_amount = amount;
        } else {
          deducted_amount = "-";
        }

        return (
          <>
            <div style={{ textAlign: "right" }}>
              {parseFloat(deducted_amount) > 0
                ? numeral(parseFloat(deducted_amount)).format("0,0.00")
                : "-"}
            </div>
          </>
        );
      }
    },
    {
      title: "Assigned To",
      dataIndex: "created_by",
      align: "left",
      width: 100,
      render: (_, record) => {
        if (record.created_by) {
          return <div>{record.created_by}</div>;
        } else {
          return <div>-</div>;
        }
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Tooltip title="View">
                <Button
                  size={"small"}
                  className="ant-btn-action "
                  onClick={() => viewOnClick(record)}
                >
                  View
                </Button>
              </Tooltip>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Adjustments"
        createButtonText="Create New Adjustment"
        showCreateButton={true}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
        createOnClick={() => navigate("/adjustments/add")}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            {/* <Col span="24"> */}
            <Row gutter={16}>
              <Col span="8">
                <Form.Item name={"id"} initialValue={null}>
                  <Input
                    placeholder="ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Member Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"username"} initialValue={null}>
                  <Input
                    placeholder="Member Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8" offset={8}>
                <Form.Item
                  name={"currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Currency"
                    showArrow
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    // filterOption={(input, option) => {
                    //   return option.label
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase());
                    // }}
                    // filterSort={(optionA, optionB) =>
                    //   optionA.label
                    //     .toLowerCase()
                    //     .localeCompare(optionB.label.toLowerCase())
                    // }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* </Col> */}
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        rowKey={(row) => {
          return row.id;
        }}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Adjustments;
