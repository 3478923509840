import React, { useState } from "react";
import { Modal, Row, Button } from "antd";
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css"
// ============
import styles from "./styles.module.less";
import { useSelector } from "react-redux";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { downloadFromUrl } from "../../configs/utilities";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReportPreviewModal = ({
  file,
  visible,
  onCancel
}) => {
  const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
  };

  const { contentWidth } = useSelector(state => state.ui)
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const MODAL_WIDTH = contentWidth + 50

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const onExportInPdf = async () => {
    downloadFromUrl(file?.url, file?.name)
  }


  return (
    <Modal
      title="Report Preview"
      centered
      width={MODAL_WIDTH}
      bodyStyle={{
        overflowY: 'scroll',
        height: '70vh'
      }}
      open={visible}
      onCancel={onCancel}
      footer={
        <Row justify="space-between">
          <div className={styles.btn_container}>
            <Row className={styles.btn_pagination_container}>
              <Button
                type="default"
                className="default_btn"
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                style={{ marginRight: 5 }}
              >
                <LeftOutlined />
              </Button>

              <div>Page {pageNumber} of {numPages}</div>

              <Button
                type="default"
                className="default_btn"
                disabled={pageNumber === numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                style={{ marginLeft: 5 }}
              >
                <RightOutlined />
              </Button>
            </Row>
          </div>

          <div className={styles.btn_container}>
            <Button
              type="default"
              className="default_btn"
              onClick={onCancel}
            >
              Close
            </Button>

            <Button
              type="default"
              className="ant-btn-secondary"
              style={{ marginLeft: 10 }}
              onClick={() => onExportInPdf()}
            >
              Export Report in PDF
            </Button>
          </div>
        </Row>
      }
    >
      <div>
        <Document
          options={options}
          file={file?.url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div className={styles.loading}>Loading PDF ...</div>
          }
        >
          <Page
            renderMode="canvas"
            pageNumber={pageNumber}
            width={contentWidth}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        <iframe name="downloadIframe" id="downloadIframe" style={{ display: 'none' }}></iframe>
      </div>
    </Modal>
  );
};

export default ReportPreviewModal;
