import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Select,
  Button,
  Form,
  Col,
  DatePicker,
  Divider
} from "antd";
import { useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { chain, filter, find, isArray, map, size, some } from "lodash";
import { useSelector } from "react-redux";
import { createMongoAbility } from "@casl/ability";

const Reports = () => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.app.user);

  const [filteredAbilityReport, setFilteredAbilityReport] = useState([]);

  const reportItems = useMemo(() => {
    return [
      {
        label: (
          <div>
            <Button
              type="link"
              onClick={() => navigate("/report/topup/bank-deposit")}
            >
              Top-Up (Bank) Report
            </Button>
          </div>
        ),
        subject: "ReloadBankReport"
      },

      {
        label: (
          <div>
            <Button
              type="link"
              onClick={() => navigate("/report/topup/crypto")}
            >
              Top-Up (Crypto) Report
            </Button>
          </div>
        ),
        subject: "ReloadBankReport"
      },

      {
        label: (
          <div>
            <Button
              type="link"
              onClick={() => navigate("/report/topup/gift-card")}
            >
              Top-Up (Gift Card) Report
            </Button>
          </div>
        ),
        subject: "ReloadGiftCardReport"
      },
      {
        label: (
          <div>
            <Button type="link" onClick={() => navigate("/report/conversion")}>
              Conversion Report
            </Button>
          </div>
        ),
        subject: "ConversionReport"
      },
      {
        label: (
          <div>
            <Button type="link" onClick={() => navigate("/report/send/bank")}>
              Send (Bank) Report
            </Button>
          </div>
        ),
        subject: "TransferBankReport"
      },
      {
        label: (
          <div>
            <Button type="link" onClick={() => navigate("/report/send/member")}>
              Send (Member) Detailed Report
            </Button>
          </div>
        ),
        subject: "TransferMemberReport"
      },
      {
        label: (
          <div>
            <Button
              type="link"
              onClick={() => navigate("/report/send/member/summary")}
            >
              Send (Member) Summary Report
            </Button>
          </div>
        ),
        subject: "TransferMemberReport"
      },

      {
        label: (
          <div>
            <Button type="link" onClick={() => navigate("/report/adjustment")}>
              Adjustment Report
            </Button>
          </div>
        ),
        subject: "AdjustmentReport"
      },

      {
        label: (
          <div>
            <Button
              type="link"
              onClick={() => navigate("/report/all-transactions")}
            >
              All Transactions Report
            </Button>
          </div>
        ),
        subject: "AllTransactionsReport"
      }
    ];
  });

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      const findReport = find(permissions, { subject: "Report" });
      if (findReport) {
        const ability = createMongoAbility(findReport?.child);

        const filterAbility = chain(reportItems)
          .filter((item) => {
            const { subject } = item;

            if (isArray(subject)) {
              return some(subject, (item) => ability.can("read", item));
            }

            return ability.can("read", item.subject);
          })
          .map((item) => {
            const { children } = item;

            if (size(children)) {
              const filteredChildren = filter(children, (item) => {
                const { subject } = item;

                return ability.can("read", subject);
              });

              return {
                ...item,
                children: filteredChildren
              };
            }

            return item;
          })
          .value();
        setFilteredAbilityReport(filterAbility);
      }
    }
  }, []);

  return (
    <div className={styles.card_container}>
      <Row>
        <div className={styles.section_title}>Reports</div>
      </Row>
      <Row>
        <Col span={4}>
          {map(filteredAbilityReport, (item) => {
            return item.label;
          })}
          {/* <div>
            <Button
              type="link"
              onClick={() => navigate("/report/topup/bank-deposit")}
            >
              Top-Up (Bank) Report
            </Button>
          </div>
          <div>
            <Button
              type="link"
              onClick={() => navigate("/report/topup-gift-card")}
            >
              Top-Up (Gift Card) Report
            </Button>
          </div>
          <div>
            <Button type="link" onClick={() => navigate("/report/conversion")}>
              Conversion Report
            </Button>
          </div>
          <div>
            <Button type="link" onClick={() => navigate("/report/send/bank")}>
              Send (Bank) Report
            </Button>
          </div>
          <div>
            <Button type="link" onClick={() => navigate("/report/send/member")}>
              Send (Member) Report
            </Button>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
