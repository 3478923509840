import React, { useRef } from "react";
import { Button, Row, Input, Form, message, Card, Col } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePassword, logout } from "@store/app";
import { FiEye, FiEyeOff } from "react-icons/fi";

// ============
import styles from "./styles.module.less";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateProfileFormPasswordRef = useRef();

  const onFinishChangePassword = async (values) => {
    try {
      const { data } = await dispatch(
        changePassword({
          old_password: values.old_password,
          new_password: values.new_password,
        })
      ).unwrap();
      message.success(data.message);
      updateProfileFormPasswordRef.current.resetFields();
      dispatch(logout()).unwrap();
      navigate({
        pathname: "/signin",
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Change Password</div>
        </Row>

        <Form
          colon={false}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishChangePassword}
          autoComplete="off"
          ref={updateProfileFormPasswordRef}
          className="form-details"
        >
          <Form.Item
            label="Current Password"
            name="old_password"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => {
                let iconStyle = {
                  height: 18,
                  width: 18,
                  color: "#888ea8",
                  fill: "rgba(0, 23, 55, 0.08)",
                  position: "absolute",
                  right: 8,
                };

                return visible ? (
                  <FiEye style={iconStyle} />
                ) : (
                  <FiEyeOff style={iconStyle} />
                );
              }}
              placeholder="Current Password"
            />
          </Form.Item>

          <Form.Item
            name="new_password"
            className="input"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long",
              },
              {
                pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$",
                message:
                  "Password must have at least one uppercase letter, one lowercase letter and one number",
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => {
                let iconStyle = {
                  height: 18,
                  width: 18,
                  color: "#888ea8",
                  fill: "rgba(0, 23, 55, 0.08)",
                  position: "absolute",
                  right: 8,
                };

                return visible ? (
                  <FiEye style={iconStyle} />
                ) : (
                  <FiEyeOff style={iconStyle} />
                );
              }}
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            className="input"
            label="Confirm New Password"
            extra={
              <div className="rules">
                Password must be at least 8 characters long.
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              iconRender={(visible) => {
                let iconStyle = {
                  height: 18,
                  width: 18,
                  color: "#888ea8",
                  fill: "rgba(0, 23, 55, 0.08)",
                  position: "absolute",
                  right: 8,
                };

                return visible ? (
                  <FiEye style={iconStyle} />
                ) : (
                  <FiEyeOff style={iconStyle} />
                );
              }}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate("/")} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;
