import React, { useEffect, useState } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Select,
  Button,
  Form,
  Col,
  DatePicker
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment-timezone";
import numeral from "numeral";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import TableHeader from "@components/table-header";
import { findAllTransactionRecords } from "../../../store/transaction";
import { findAllStatuses, assignCaseToUser } from "../../../store/app";
import {
  retrieveCryptoNetworks,
  retrieveCryptoProviders
} from "../../../store/crypto";
import { findCurrencyFilterOptionsByType } from "@store/currency";
import { findCountryFilterOptionsByType } from "@store/country";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@configs/can";
import { findAllCaseAssignee } from "../../../store/user";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Transaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const ability = useAbility(AbilityContext);

  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const [transactionRecords, setTransactionRecords] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState([]);
  const [network, setNetwork] = useState([]);

  const transactionOptions = [
    {
      value: "1",
      label: "Top-Up (Bank)"
    },
    {
      value: "2",
      label: "Send (Bank)"
    },
    {
      value: "3",
      label: "Conversion"
    },
    {
      value: "4",
      label: "Send (Member)"
    },
    {
      value: "5",
      label: "Top-Up (Gift Card)"
    },
    {
      value: "6",
      label: "Top-Up (Crypto)"
    },
    {
      value: "8",
      label: "Adjustment"
    }
  ];

  let usernameFilter = searchParams.get("username");

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSort());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());
    fetchData();
    initialize();
  }, []);

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const initialize = async () => {
    try {
      const statusPayload = await dispatch(findAllStatuses()).unwrap();
      const { data: statusData } = statusPayload;

      const filteredStatusData = statusData.filter((item) =>
        ["Pending", "Processing", "Successful", "Unsuccessful"].includes(
          item.name
        )
      );

      setStatuses(filteredStatusData);

      const dashboardUserPayload = await dispatch(
        // findAllDashboardUsers({ paginate: false })
        findAllCaseAssignee({ paginate: false })
      ).unwrap();
      const { data: dashboardUserData } = dashboardUserPayload;

      const dashboardUser = map(dashboardUserData, (item) => {
        return {
          value: item.username,
          label: item.username
        };
      });
      setDashboardUsers(dashboardUser);

      // if usernameFilter exists, initialize table with filtered data
      if (usernameFilter != null) {
        let values = {
          transaction_type: [],
          from_currency: [],
          to_currency: [],
          country: [],
          customer_topkash_id: null,
          customer_username: usernameFilter,
          assigned_to: [],
          status: [],
          created_at: null
        };
        setFilter(values);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // // if usernameFilter exists, initialize table with filtered data
      // if (usernameFilter != null) {
      //   let values = {
      //     transaction_type: [],
      //     from_currency: [],
      //     to_currency: [],
      //     country: [],
      //     customer_topkash_id: null,
      //     customer_username: usernameFilter,
      //     assigned_to: [],
      //     status: [],
      //     created_at: null,
      //   };
      //   setFilter(values);
      // }

      const transactions = await dispatch(
        findAllTransactionRecords({ filter })
      ).unwrap();

      let { data, total } = transactions.data;
      console.log(data);

      let { data: countryFilterOptions } = await dispatch(
        findCountryFilterOptionsByType({ type: "all" })
      ).unwrap();

      countryFilterOptions = map(countryFilterOptions, (item) => {
        return {
          label: item.name,
          value: item.iso_code
        };
      });

      let { data: currencyFilterOptions } = await dispatch(
        findCurrencyFilterOptionsByType({ type: "all" })
      ).unwrap();

      currencyFilterOptions = map(currencyFilterOptions, (item) => {
        return {
          label: item.iso_code,
          value: item.iso_code
        };
      });

      setCountries(countryFilterOptions);
      setCurrencies(currencyFilterOptions);
      setTransactionRecords(data);

      // Retrieve providers
      const provider = await dispatch(retrieveCryptoProviders()).unwrap();
      const { data: providersData } = provider;
      setProvider(providersData);

      // Retrieve networks
      const network = await dispatch(retrieveCryptoNetworks()).unwrap();
      const { data: networksData } = network;
      setNetwork(networksData);

      setTableData(data);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const resetButtonOnClick = () => {
  //   dispatch(resetTablePagination());
  //   dispatch(resetTableFilter());
  //   setFilter({});
  //   fetchData();
  // };

  const actionBtnOnClick = async (item) => {
    const { status, reference_id, transaction_type, user_bank_account_type } =
      item;

    if (status === "1") {
      if (transaction_type === "SEND") {
        if (ability.can("update", "Transfer")) {
          navigate(`/send/bank/${reference_id}?mode=edit`);
          await dispatch(
            assignCaseToUser({
              ref_id: reference_id,
              type: "transfers",
              action: "assign"
            })
          ).unwrap();
        } else {
          navigate(`/send/bank/${reference_id}?mode=view`);
        }
      } else if (transaction_type === "RELOAD") {
        if (ability.can("update", "Reload")) {
          navigate(`/top-up/bank/${reference_id}?mode=edit`);

          await dispatch(
            assignCaseToUser({
              ref_id: reference_id,
              type: "reloads",
              action: "assign"
            })
          ).unwrap();
        } else {
          navigate(`/top-up/bank/${reference_id}?mode=view`);
        }
      } else if (transaction_type === "CRYPTO") {
        if (ability.can("update", "Reload")) {
          navigate(`/top-up/crypto/${reference_id}?mode=edit`);

          await dispatch(
            assignCaseToUser({
              ref_id: reference_id,
              type: "reloads",
              action: "assign"
            })
          ).unwrap();
        } else {
          navigate(`/top-up/crypto/${reference_id}?mode=view`);
        }
      } else {
        return;
      }
    } else if (status === "2") {
      if (transaction_type === "SEND") {
        if (ability.can("update", "Transfer")) {
          navigate(`/send/bank/${reference_id}?mode=edit`);
        } else {
          navigate(`/send/bank/${reference_id}?mode=view`);
        }
      } else if (transaction_type === "RELOAD") {
        if (ability.can("update", "Reload")) {
          navigate(`/top-up/bank/${reference_id}?mode=edit`);
        } else {
          navigate(`/top-up/bank/${reference_id}?mode=view`);
        }
      } else if (transaction_type === "CRYPTO") {
        if (ability.can("update", "Reload")) {
          navigate(`/top-up/crypto/${reference_id}?mode=edit`);
        } else {
          navigate(`/top-up/crypto/${reference_id}?mode=view`);
        }
      } else {
        return;
      }
    } else {
      if (transaction_type === "SEND") {
        if (user_bank_account_type === "TOPKASH") {
          navigate(`/send/member/${reference_id}?mode=view`);
        } else {
          navigate(`/send/bank/${reference_id}?mode=view`);
        }
      } else if (transaction_type === "RELOAD") {
        navigate(`/top-up/bank/${reference_id}?mode=view`);
      } else if (transaction_type === "CRYPTO") {
        navigate(`/top-up/crypto/${reference_id}?mode=view`);
      } else if (transaction_type === "CONVERT") {
        navigate(`/conversion/${reference_id}`);
      } else if (transaction_type === "REDEEM") {
        navigate(`/top-up/gift-card/${reference_id}`);
      } else if (transaction_type === "ADJUSTMENT") {
        navigate(`/adjustments/${reference_id}`);
      } else {
        return;
      }
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "doc_id",
      align: "left",
      key: "doc_id",
      fixed: "left",
      width: 120
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Submission</div>
            <div>Date (GMT +7)</div>
          </span>
        );
      },
      dataIndex: "submission_date",
      width: 200,
      sorter: true,
      key: "submission_date",

      render: (_, record) => {
        const { created_at } = record;
        return (
          <div style={{ textAlign: "left" }}>
            {created_at != null
              ? moment
                  .utc(created_at)
                  .tz("Asia/Phnom_Penh")
                  .format("DD-MM-YYYY, hh:mm:ss A")
              : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} right`}>
            <div>Sender</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      dataIndex: "topkash_id",
      align: "left",
      width: 120,
      key: "topkash_id",
      render: (_, record) => {
        const { customer_topkash_id } = record;
        return <div>{customer_topkash_id ? customer_topkash_id : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_right`}>
            <div>Sender</div>
            <div>Username</div>
          </span>
        );
      },
      dataIndex: "username",
      align: "left",
      width: 100,
      key: "username",
      render: (_, record) => {
        const { customer_username } = record;
        return <div>{customer_username ? customer_username : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>From</div>
            <div>Currency</div>
          </span>
        );
      },
      dataIndex: "from_currency_code",
      align: "right",
      key: "from_currency_code",
      width: 100,
      render: (_, record) => {
        const { from_currency_code, amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(amount) < 0 ? from_currency_code : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>From</div>
            <div>Amount</div>
          </span>
        );
      },
      dataIndex: "amount",
      align: "right",
      key: "amount",
      width: 100,
      render: (_, record) => {
        const { amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(amount) < 0
              ? numeral(Math.abs(parseFloat(amount))).format("0,0.00")
              : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Fees</div>
          </span>
        );
      },
      dataIndex: "processing_fee",
      align: "right",
      key: "processing_fee",
      width: 100,
      render: (_, record) => {
        const { processing_fee } = record;

        let fee = processing_fee;
        if (parseFloat(processing_fee) === 0.0) {
          fee = "-";
        } else {
          fee = processing_fee;
        }

        return (
          <>
            <div style={{ textAlign: "right" }}>
              {parseFloat(fee) > 0
                ? numeral(parseFloat(fee)).format("0,0.00")
                : "-"}
            </div>
          </>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Amount </div>
            <div>To Convert</div>
          </span>
        );
      },
      dataIndex: "amount",
      align: "right",
      key: "amount",
      width: 100,
      render: (_, record) => {
        const { amount_to_convert } = record;

        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(amount_to_convert) > 0
              ? numeral(parseFloat(amount_to_convert)).format("0,0.00")
              : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Exchange </div>
            <div>Rate</div>
          </span>
        );
      },
      width: 150,
      dataIndex: "exchange_rate",
      align: "right",
      key: "exchange_rate",
      render: (_, record) => {
        const {
          exchange_rate,
          from_currency_code,
          to_currency_code,
          reference_table
        } = record;

        let rate = exchange_rate;

        if (reference_table == "transfers") {
          if (from_currency_code === to_currency_code) {
            rate = "1.000000";
          }
        }

        return (
          <div style={{ textAlign: "right" }}>
            {rate ? numeral(parseFloat(rate)).format("0,0.000000") : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>To</div>
            <div>Currency</div>
          </span>
        );
      },
      dataIndex: "currency",
      align: "right",
      key: "currency",
      width: 100,
      render: (_, record) => {
        const { to_currency_code, to_amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(to_amount) > 0 ? to_currency_code : "-"}
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>To</div>
            <div>Amount</div>
          </span>
        );
      },
      dataIndex: "to_amount",
      align: "right",
      key: "to_amount",
      width: 100,
      render: (_, record) => {
        const { to_amount } = record;
        return (
          <div style={{ textAlign: "right" }}>
            {parseFloat(to_amount) > 0
              ? numeral(parseFloat(to_amount)).format("0,0.00")
              : "-"}
          </div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Topkash ID</div>
          </span>
        );
      },
      align: "left",
      dataIndex: "reference_topkash_id",
      width: 120,
      render: (_, record) => {
        const {
          reference_topkash_id,
          customer_topkash_id,
          user_bank_account_type,
          amount
        } = record;

        let referenceTopkashId = reference_topkash_id;

        if (reference_topkash_id === customer_topkash_id) {
          referenceTopkashId = "-";
        }

        if (user_bank_account_type === "TOPKASH" && parseFloat(amount) > 0) {
          referenceTopkashId = "-";
        }

        return <div>{referenceTopkashId}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Username</div>
          </span>
        );
      },

      align: "left",
      dataIndex: "reference_username",
      width: 120,
      render: (_, record) => {
        const {
          reference_username,
          customer_username,
          user_bank_account_type,
          amount
        } = record;

        let referenceUsername = reference_username;

        if (reference_username === customer_username) {
          referenceUsername = "-";
        }

        if (user_bank_account_type === "TOPKASH" && parseFloat(amount) > 0) {
          referenceUsername = "-";
        }

        return <div>{referenceUsername}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Country</div>
          </span>
        );
      },
      dataIndex: "recipient_country_name",
      align: "left",
      key: "recipient_country_name",
      width: 120,
      render: (_, record) => {
        const {
          transaction_type,
          transfer_bank_country_name,
          reload_bank_country_name
        } = record;

        let country_name = null;
        if (transaction_type === "SEND") {
          country_name = transfer_bank_country_name;
        }

        return <div>{country_name ? country_name : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank</div>
          </span>
        );
      },
      dataIndex: "recipient_bank",
      align: "left",
      key: "recipient_bank",
      width: 120,
      render: (_, record) => {
        const { transaction_type, transfer_bank } = record;
        let recipient_bank = null;

        if (transaction_type === "SEND") {
          recipient_bank = transfer_bank;
        }

        return <div>{recipient_bank ? recipient_bank : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Acc No</div>
          </span>
        );
      },
      dataIndex: "recipient_bank_acc_no",
      align: "left",
      key: "recipient_bank_acc_no",
      width: 120,
      render: (_, record) => {
        const { transaction_type, user_bank_account_no } = record;
        let recipient_bank_acc_no = null;

        if (transaction_type === "SEND") {
          recipient_bank_acc_no = user_bank_account_no;
        }

        return <div>{recipient_bank_acc_no ? recipient_bank_acc_no : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Recipient</div>
            <div>Bank Acc Name</div>
          </span>
        );
      },
      dataIndex: "recipient_bank_acc_name",
      align: "left",
      key: "recipient_bank_acc_name",
      width: 120,
      render: (_, record) => {
        const { transaction_type, user_bank_account_name } = record;
        let recipient_bank_acc_name = null;

        if (transaction_type === "SEND") {
          recipient_bank_acc_name = user_bank_account_name;
        }

        return (
          <div>{recipient_bank_acc_name ? recipient_bank_acc_name : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Top-Up</div>
            <div>Reference Code</div>
          </span>
        );
      },
      dataIndex: "receipt_reference_no",
      align: "left",
      width: 120,
      render: (_, record) => {
        const { receipt_reference_no } = record;

        return <div>{receipt_reference_no ? receipt_reference_no : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Crypto</div>
            <div>Provider</div>
          </span>
        );
      },
      dataIndex: "crypto_account_provider",
      align: "left",
      width: 120,
      render: (_, record) => {
        const { crypto_account_provider } = record;

        return (
          <div>{crypto_account_provider ? crypto_account_provider : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Crypto</div>
            <div>Network</div>
          </span>
        );
      },
      dataIndex: "crypto_account_network",
      align: "left",
      width: 120,
      render: (_, record) => {
        const { crypto_account_network } = record;

        return (
          <div>{crypto_account_network ? crypto_account_network : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Crypto</div>
            <div>Address</div>
          </span>
        );
      },
      dataIndex: "crypto_account_address",
      align: "left",
      width: 180,
      render: (_, record) => {
        const { crypto_account_address } = record;

        return (
          <div>{crypto_account_address ? crypto_account_address : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Source</div>
            <div>Bank</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "source_bank_name",
      render: (_, record) => {
        const { source_bank_name } = record;

        return <div>{source_bank_name ? source_bank_name : "-"}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Source</div>
            <div>Bank Acc No</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "source_bank_account_no",
      render: (_, record) => {
        const { source_bank_account_no } = record;

        return (
          <div>{source_bank_account_no ? source_bank_account_no : "-"}</div>
        );
      }
    },

    {
      title: () => {
        return (
          <span className={`${styles.table_column_title} align_left`}>
            <div>Source</div>
            <div>Bank Acc Name</div>
          </span>
        );
      },
      align: "left",
      width: 120,
      dataIndex: "source_bank_account_name",
      render: (_, record) => {
        const { source_bank_account_name } = record;

        return (
          <div>{source_bank_account_name ? source_bank_account_name : "-"}</div>
        );
      }
    },

    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      align: "left",
      // width: "10%",
      width: 100,
      key: "assigned_to",
      render: (_, record) => {
        const { assigned_to_username } = record;
        return <div>{assigned_to_username ? assigned_to_username : "-"}</div>;
      }
    },
    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Proccessed</div>
            <div>Date (GMT +7)</div>
          </span>
        );
      },
      dataIndex: "processed_date",
      width: 200,
      sorter: true,
      key: "processed_date",
      render: (_, record) => {
        const { processed_date } = record;
        return (
          <>
            <div style={{ textAlign: "left" }}>
              {processed_date
                ? moment
                    .utc(processed_date)
                    .tz("Asia/Phnom_Penh")
                    .format("DD-MM-YYYY, hh:mm:ss A")
                : "-"}
            </div>
          </>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      key: "status",
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        let fontWeight;

        if (record.status === "3") {
          status = "Successful";
          color = "#339933";
        } else if (record.status === "5") {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.status === "2") {
          status = "Processing";
          color = "#4f8bc2";
        } else if (record.status === "1") {
          status = "Pending";
          color = "#ff8000";
          fontWeight = 700;
        }

        return <div style={{ color, fontWeight }}>{status}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>Action</div>
          </span>
        );
      },
      dataIndex: "action",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        if (
          record.transaction_type === "SEND" ||
          record.transaction_type === "CONVERT"
        ) {
          if (ability.can("update", "Transfer")) {
            const { status } = record;

            let ctaText;

            if (status === 3 || status === 5) {
              ctaText = "View";
            } else {
              ctaText = "Edit";
            }

            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    {ctaText}
                  </Button>
                </Space>
              </div>
            );
          } else {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        } else if (
          record.transaction_type === "RELOAD" ||
          record.transaction_type === "CRYPTO"
        ) {
          if (ability.can("update", "Reload")) {
            const { status } = record;

            let ctaText;

            if (status === 3 || status === 5) {
              ctaText = "View";
            } else {
              ctaText = "Edit";
            }

            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    {ctaText}
                  </Button>
                </Space>
              </div>
            );
          } else {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        } else if (record.transaction_type === "REDEEM") {
          if (ability.can("update", "Redeem")) {
            const { status } = record;

            let ctaText;

            if (status === 3 || status === 5) {
              ctaText = "View";
            } else {
              ctaText = "Edit";
            }

            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    {ctaText}
                  </Button>
                </Space>
              </div>
            );
          } else {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        } else if (record.transaction_type === "ADJUSTMENT") {
          {
            const { status } = record;
            return (
              <div align={"middle"}>
                <Space size="middle">
                  <Button
                    size={"small"}
                    className="ant-btn-action "
                    onClick={() => actionBtnOnClick(record)}
                  >
                    View
                  </Button>
                </Space>
              </div>
            );
          }
        } else {
          return;
        }
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="All Transactions"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          // dispatch(resetButtonOnClick());
          setFilter({});
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="18" align="right">
            <Row gutter={16}>
              <Col span="8">
                <Form.Item
                  name={"transaction_type"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Transaction Type"
                    showArrow
                    options={transactionOptions}
                    // filterOption={(input, option) => {
                    //   return option.label
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase());
                    // }}
                    // filterSort={(optionA, optionB) =>
                    //   optionA.label
                    //     .toLowerCase()
                    //     .localeCompare(optionB.label.toLowerCase())
                    // }
                  >
                    {map(transactionOptions, (item, index) => {
                      return (
                        <Option key={index} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item name={"customer_topkash_id"} initialValue={null}>
                  <Input
                    placeholder="Sender Topkash ID"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"customer_username"}
                  initialValue={usernameFilter}
                >
                  <Input
                    placeholder="Sender Username"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"from_currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="From Currency"
                    showArrow
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    // filterOption={(input, option) => {
                    //   return option.label
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase());
                    // }}
                    // filterSort={(optionA, optionB) =>
                    //   optionA.label
                    //     .toLowerCase()
                    //     .localeCompare(optionB.label.toLowerCase())
                    // }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"to_currency"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="To Currency"
                    showArrow
                    options={
                      currencies && currencies.length > 0 ? currencies : null
                    }
                    // filterOption={(input, option) => {
                    //   return option.label
                    //     .toLowerCase()
                    //     .includes(input.toLowerCase());
                    // }}
                    // filterSort={(optionA, optionB) =>
                    //   optionA.label
                    //     .toLowerCase()
                    //     .localeCompare(optionB.label.toLowerCase())
                    // }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"country"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Recipient Bank Country"
                    options={
                      countries && countries.length > 0 ? countries : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"crypto_provider"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Crypto Provider"
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {map(provider, (item) => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"crypto_network"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Crypto Network"
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {map(network, (item) => {
                      return (
                        <Option key={item.id} value={item.name}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item name={"crypto_address"} initialValue={null}>
                  <Input
                    placeholder="Crypto Address"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span="8">
                <Form.Item
                  name={"assigned_to"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Assigned To"
                    options={
                      dashboardUsers && dashboardUsers.length > 0
                        ? dashboardUsers
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        .toLowerCase()
                        .localeCompare(optionB.label.toLowerCase())
                    }
                  ></Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  name={"status"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Status"
                    showArrow
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {map(statuses, (item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <div className={styles.filter_date_label}>Submission Date:</div>
                <Form.Item name="created_at" initialValue={null}>
                  <RangePicker
                    style={{ width: "100%" }}
                    name="selected_date"
                    className="date-range-picker"
                    allowClear
                    format="DD-MM-YYYY"
                    disabledDate={(date) => moment().add(0, "days") <= date}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        dataSource={transactionRecords}
        rowKey={(row) => {
          return row.id;
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Transaction;
