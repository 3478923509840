import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Row,
  Button,
  message,
  Select,
  Upload,
  Space,
  InputNumber,
  Spin
} from "antd";
// ============
import styles from "./styles.module.less";
import { addBusinessType } from "../../../store/business-type";
import { map } from "lodash";

const BusinessTypeAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (values) {
        const titleArray = [];

        titleArray.push({
          language: "en",
          content: values.title || ""
        });

        const languages = ["km", "zh", "th"];

        languages.forEach((language) => {
          if (values[`${language}Title`]) {
            titleArray.push({
              language,
              content:
                values[`${language}Title`] === null
                  ? ""
                  : values[`${language}Title`]
            });
          } else {
            titleArray.push({
              language,
              content: ""
            });
          }
        });

        await dispatch(
          addBusinessType({
            name: values.name,
            content: titleArray
          })
        ).unwrap();

        message.success("You have successfully added the business type");

        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <Card className={styles.card_container}>
          <Row>
            <div className={styles.section_title}>New Business Type</div>
          </Row>

          <Form
            ref={formRef}
            colon={false}
            name="basic"
            labelCol={{
              span: 4
            }}
            wrapperCol={{
              span: 20
            }}
            initialValues={{
              remember: true
            }}
            onFinish={handleSubmit}
            autoComplete="off"
            className="form-details"
          >
            <Form.Item
              name="name"
              label="Name"
              className="input"
              rules={[
                {
                  required: true,
                  message: "Please enter the name"
                }
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              label="English Label"
              name="title"
              className="input"
              extra={
                <div className="rules">
                  Maximum 30 characters, this will appear in App
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the title"
                },
                {
                  required: true,
                  validator: async (_, value) => {
                    if (value) {
                      if (value && value.length > 30) {
                        throw new Error(
                          "The length should not exceeding 30 characters"
                        );
                      }
                    }
                  }
                },
                {
                  pattern: /^[a-zA-Z,&_. ]*$/,
                  message: "Please insert valid title",
                },
              ]}
            >
              <Input placeholder="English Label" />
            </Form.Item>
            <Form.Item label="Khmer Label" name="kmTitle" className="input">
              <Input placeholder="Khmer Label" />
            </Form.Item>
            <Form.Item label="Chinese Label" name="zhTitle" className="input">
              <Input placeholder="Chinese Label" />
            </Form.Item>
            <Form.Item label="Thai Label" name="thTitle" className="input">
              <Input placeholder="Thai Label" />
            </Form.Item>

            <Row justify="end">
              <div className={styles.btn_container}>
                <Button
                  type="default"
                  className="default_btn"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
              <div className={styles.btn_container}>
                <Button
                  type="default"
                  htmlType="submit"
                  className="ant-btn-green"
                >
                  Save
                </Button>
              </div>
            </Row>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default BusinessTypeAdd;
