import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Row,
  Input,
  Table,
  message,
  Space,
  Select,
  Button,
  Col,
  DatePicker,
  Divider,
  Card,
  Form,
} from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { map } from "lodash";
// ============
import styles from "./styles.module.less";
import { retrieveAllCurrencies } from "@store/currency";
import { generateTopUpGiftCardReport } from "@store/report";
import { useDispatch, useSelector } from "react-redux";
import { findAllCustomers } from "@store/user";
import ReportPreviewModal from "../../../components/report-preview-modal";
import { downloadFromUrl } from "../../../configs/utilities";

const { RangePicker } = DatePicker;
const { Option } = Select;

const TopupGiftCardReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const mode = useRef(null)

  const [currencies, setCurrencies] = useState([]);
  const [members, setMembers] = useState([]);
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [file, setFile] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    initialise();

    //Set default date value
    formRef.current.setFieldsValue({
      redemption_date: [moment(), moment()],
    });
  }, []);

  const initialise = async () => {
    const { data: currencyData } = await dispatch(
      retrieveAllCurrencies()
    ).unwrap();

    const { data: memberData } = await dispatch(
      findAllCustomers({ paginate: false })
    ).unwrap();

    setMembers(memberData);
    setCurrencies(currencyData);
  };

  const exportReport = async () => {
    mode.current = 'download'

    formRef.current.submit()
  };

  const onFinish = async (values) => {
    if (values.redemption_date) {
      let start = values.redemption_date[0].format("YYYYMMDD");
      let end = values.redemption_date[1].format("YYYYMMDD");

      values = {
        ...values,
        redemption_date: [start, end],
      };
    }

    setLoading(true)

    try {
      const { url, file_name } = await dispatch(generateTopUpGiftCardReport({ filter: values, mode: mode.current })).unwrap();

      if (mode.current === 'preview') {
        setFile({ url, name: file_name })
        setOpenPreviewModal(true)
      }
      else {
        downloadFromUrl(url, file_name)
      }
    } catch (error) {
      message.error(error.message);
    }
    finally {
      setLoading(false)
    }
  };

  const previewReport = () => {
    mode.current = 'preview'

    formRef.current.submit()
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">Top-Up (Gift Card) Report</div>
        </Row>
        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          className="form-details"
          requiredMark={false}
          onFinish={onFinish}
        >
          <Form.Item
            name="redemption_date"
            label="Redemption Date"
            rules={[
              {
                required: true,
                message: "Please enter the redemption date",
              },
            ]}
          >
            <RangePicker
              name="selected_date"
              placeholder={["Start Date", "End Date"]}
              format="DD-MM-YYYY"
              disabledDate={(date) => moment().add(0, "days") <= date}
            />
          </Form.Item>
          <Form.Item name="currency" label="Currency">
            <Select
              allowClear
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(currencies, (item) => {
                return (
                  <Option key={item.id} value={item.iso_code}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="member" label="Member">
            <Select
              allowClear
              placeholder="All"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {map(members, (item) => {
                return (
                  <Option key={item.id} value={item.username}>
                    {item.username && item.fullname
                      ? item.username + " - " + item.fullname
                      : item.username}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row justify="space-between">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>

            <div className={styles.btn_container}>
              <Button
                type="default"
                // htmlType="submit"
                className="ant-btn-secondary"
                onClick={() => exportReport()}
                loading={mode.current === 'download' && loading}
              >
                Export Report
              </Button>

              <Button
                type="default"
                className="ant-btn-secondary"
                style={{ marginLeft: 10 }}
                onClick={() => previewReport()}
                loading={mode.current === 'preview' && loading}
              >
                Preview Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
      <ReportPreviewModal
        file={file}
        visible={openPreviewModal}
        onCancel={() => setOpenPreviewModal(false)}
      />
    </div>
  );
};

export default TopupGiftCardReport;
