import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form, Input, Button, Modal, message } from "antd";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useMatch } from "react-router-dom";

//====
import styles from "./styles.module.less";
import { createNewPassword, checkNewPasswordLink } from "@store/app";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const path = useLocation();
  const navigate = useNavigate();
  const { params } = useMatch("first-login/:e");

  const { isTempPassword } = useSelector((state) => state.app.user);
  const [counter, setCounter] = useState(5);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewPwdLinkExpired, setIsNewPwdLinkExpired] = useState(null);
  const [newUserUsername, setNewUserUsername] = useState([null]);
  const [userDetail, setUserDetail] = useState({});
  const [userDetailError, setUserDetailError] = useState({});

  const formRef = useRef();

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await dispatch(
        checkNewPasswordLink({ encryptedLink: params.e })
      ).unwrap();
      if (data) {
        formRef.current.setFieldsValue({
          username: data.username,
        });
        setUserDetail(data);
      }
    } catch (error) {
      error = JSON.parse(error?.message);
      setUserDetail({
        ...userDetail,
        username: error?.data?.username,
      });
      message.error(error?.message);
      setIsNewPwdLinkExpired(true);
    }
  };

  const onFinish = async (values) => {
    try {
      const { data } = await dispatch(
        createNewPassword({
          username: values.username,
          password: values.new_password,
          e: params.e,
        })
      ).unwrap();

      message.success(data.message);
      navigate({
        pathname: "/signin",
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleOkButton = () => {
    setIsModalVisible(false);
    navigate("/signin");

    // if (isTempPassword == true) {
    //   dispatch(setIsLoggedIn(true));
    // } else {
    //   setTimeout(() => {
    //     navigate("/signin");
    //   }, 500);
    // }
  };

  return (
    <>
      <Row className={styles.reset_password_container}>
        <Card className={styles.reset_password_card}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className={styles.reset_password_title}>
                First Time Login?
              </div>
            </Col>
            <Col span={24}>
              <div className={styles.reset_password_desc}>
                Please enter your username and new password
              </div>
            </Col>
          </Row>
          <br />
          <Form
            ref={formRef}
            name="control-hooks"
            layout="vertical"
            requiredMark="optional"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              label="USERNAME"
              className={styles.reset_password_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your username",
                },
              ]}
            >
              <div>{userDetail.username ?? "-"}</div>
              {/* <Input prefix disabled /> */}
            </Form.Item>

            <Form.Item
              name="new_password"
              label="NEW PASSWORD"
              className={styles.reset_password_form_item}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  min: 8,
                  message: "Password must have a minimum length of 8",
                },
                {
                  pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$",
                  message:
                    "Password must have at least one uppercase letter, one lowercase letter and one number",
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 17,
                    width: 17,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                    position: "absolute",
                    right: 10,
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="New Password"
              />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              label="CONFIRM PASSWORD"
              className={styles.reset_password_form_item}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                iconRender={(visible) => {
                  let iconStyle = {
                    height: 17,
                    width: 17,
                    color: "#888ea8",
                    fill: "rgba(0, 23, 55, 0.08)",
                    position: "absolute",
                    right: 10,
                  };

                  return visible ? (
                    <FiEye style={iconStyle} />
                  ) : (
                    <FiEyeOff style={iconStyle} />
                  );
                }}
                placeholder="Confirm Password"
              />
            </Form.Item>

            <br />

            {isNewPwdLinkExpired === true ? (
              <>
                <Button
                  type="default"
                  htmlType="submit"
                  className="save-auth-form"
                  disabled={isNewPwdLinkExpired}
                >
                  Save Changes
                </Button>
              </>
            ) : (
              <Button
                type="default"
                htmlType="submit"
                className="save-auth-form"
              >
                Save Changes
              </Button>
            )}

            {/* <Button
              type="default"
              htmlType="submit"
              className="save-auth-form"
              disabled={isNewPwdLinkExpired}
            >
              Save Changes
            </Button> */}

            <Button
              htmlType="cancel"
              className="cancel-auth-form"
              onClick={() => navigate("/signin")}
            >
              Cancel
            </Button>
          </Form>
        </Card>
      </Row>

      <Modal
        className={styles.modal_container}
        bodyStyle={{
          paddingTop: 24,
          paddingBottom: 12,
          textAlign: "center",
        }}
        visible={isModalVisible}
        closable={false}
        onOk={handleOkButton}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        okButtonProps={{
          style: { display: "inline-block" },
        }}
      >
        <div className={styles.reset_password_title}>
          You have successfully reset your password
        </div>
      </Modal>
    </>
  );
};

export default ResetPassword;
