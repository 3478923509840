import React, { useEffect, useState } from "react";
import { Row, Table, Card, message, Form, Layout, Col, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import numeral from "numeral";
import { useNavigate, useLocation } from "react-router-dom";
import { map } from "lodash";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTablePagination,
  resetTableSort,
  resetTableSearch,
  resetTableFilter
} from "@store/ui";

import {
  getAvailableBankAccounts,
  getFlaggedBankAccounts
} from "../../../store/bank";
const { Content } = Layout;

const Dashboard = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { contentHeight, table } = useSelector((state) => state.ui);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [flaggedTableData, setFlaggedTableData] = useState([]);
  const [availableTableData, setAvailableTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      //Retrieve flagged bank accounts
      const { data: flaggedBankAcounts } = await dispatch(
        getFlaggedBankAccounts()
      ).unwrap();
      setFlaggedTableData(
        map(flaggedBankAcounts, (item, index) => {
          return {
            ...item,
            key: index
          };
        })
      );

      //Retrieve available bank accounts
      const { data: availableBankAccounts } = await dispatch(
        getAvailableBankAccounts()
      ).unwrap();
      setAvailableTableData(
        map(availableBankAccounts, (item, index) => {
          return {
            ...item,
            key: index
          };
        })
      );
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Currency",
      dataIndex: "currency_name",
      key: "currency_name",
      align: "left",
      render: (_, record) => {
        return <div>{record.currency_name}</div>;
      }
    }
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Bank Acc ID",
        dataIndex: "id",
        key: "id",
        align: "left",
        width: 100,
        render: (_, record) => {
          return <div>{record.id}</div>;
        }
      },

      {
        title: () => {
          return (
            <span className={`${styles.table_column_title} left`}>
              <div>Bank</div>
              <div>Acc No</div>
            </span>
          );
        },
        dataIndex: "acc_no",
        key: "acc_no",
        align: "left",
        width: 150,
        render: (_, record) => {
          return <div>{record.acc_no}</div>;
        }
      },

      {
        title: () => {
          return (
            <span className={`${styles.table_column_title} left`}>
              <div>Bank</div>
              <div>Acc Name</div>
            </span>
          );
        },
        dataIndex: "acc_name",
        key: "acc_name",
        align: "left",
        width: 170,
        render: (_, record) => {
          return <div>{record.acc_name}</div>;
        }
      },

      {
        title: () => {
          return (
            <span className={`${styles.table_column_title} left`}>
              <div>Bank</div>
              <div>Name</div>
            </span>
          );
        },
        dataIndex: "bank_name",
        key: "bank_name",
        align: "left",
        width: 170,
        render: (_, record) => {
          return <div>{record.bank_name}</div>;
        }
      },

      {
        title: <div style={{ textAlign: "right" }}>Frequency</div>,
        dataIndex: "frequency",
        key: "frequency",
        align: "left",
        width: 90,
        render: (_, record) => {
          return <div style={{ textAlign: "right" }}>{record.frequency}</div>;
        }
      },

      {
        title: <div style={{ textAlign: "right" }}>Amount</div>,
        dataIndex: "transaction_amount",
        key: "transaction_amount",
        align: "left",
        width: 180,
        render: (_, record) => {
          if (record.transaction_amount) {
            return (
              <div style={{ textAlign: "right" }}>
                {numeral(parseFloat(record.transaction_amount)).format(
                  "0,0.00"
                )}
              </div>
            );
          } else {
            return <div style={{ textAlign: "right" }}>-</div>;
          }
        }
      },

      {
        title: <div style={{ textAlign: "right" }}>Score</div>,
        dataIndex: "score",
        key: "score",
        align: "left",
        fixed: "right",
        width: 180,
        render: (_, record) => {
          if (record.score) {
            return (
              <div style={{ textAlign: "right" }}>
                {numeral(parseFloat(record.score)).format("0,0.00")}
              </div>
            );
          } else {
            return <div style={{ textAlign: "right" }}>-</div>;
          }
        }
      }
    ];

    return (
      <Table columns={columns} dataSource={record.items} pagination={false} />
    );
  };

  return (
    <>
      <div className={styles.container}>
        <Row align="middle">
          <div className={styles.section_title}>Dashboard</div>
        </Row>
      </div>

      <Content className={styles.dashboard_container}>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Row gutter={16}>
            <Col span={24}>
              <Card className={styles.dashboard_card}>
                <Row align="middle">
                  <div className={styles.section_title}>
                    Top 3 Available Bank Accounts Grouped By Currencies
                  </div>
                </Row>
                <Table
                  loading={isLoading}
                  rowkey={"key"}
                  className={styles.pagination_item}
                  columns={columns}
                  dataSource={availableTableData}
                  size="small"
                  scroll={{
                    y: contentHeight - 90 - 40 - 56 - 70
                  }}
                  pagination={false}
                  expandable={{
                    expandedRowRender,

                    defaultExpandedRowKeys: [0]
                  }}
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Card className={styles.dashboard_card}>
                <Row align="middle">
                  <div className={styles.section_title}>
                    Top 5 Flagged Bank Accounts Grouped By Currencies
                  </div>
                </Row>
                <Table
                  loading={isLoading}
                  rowkey={"key"}
                  className={styles.pagination_item}
                  columns={columns}
                  dataSource={flaggedTableData}
                  size="small"
                  scroll={{
                    y: contentHeight - 90 - 40 - 56 - 70
                  }}
                  pagination={false}
                  expandable={{
                    expandedRowRender,

                    defaultExpandedRowKeys: [0]
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Space>
      </Content>
    </>
  );
};

export default Dashboard;
