import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { queryBuilder, errorHandler } from "../configs";
import fileDownload from "js-file-download";

import { isEmpty } from "lodash";

const initialState = {};

export const createMerchant = createAsyncThunk(
  "merchant/createMerchant",
  async (values) => {
    const {
      address_1,
      address_2,
      city,
      country,
      currency_available,
      email,
      emergency_phone,
      emergency_pic,
      name,
      operations,
      // operation_days,
      // operation_hours,
      content,
      longitude,
      latitude,
      waze_map,
      google_map,
      phone,
      pic,
      postcode,
      state,
      is_active,
      assign_users,
      media_id,
      images,
      business_type
    } = values;
    try {
      let formData = new FormData();
      formData.append("address_1", address_1);
      formData.append("address_2", address_2);
      formData.append("city", city);
      formData.append("country_id", country);
      formData.append("currency_available", currency_available);
      formData.append("content", content);
      formData.append("emergency_phone", emergency_phone);
      formData.append("emergency_pic", emergency_pic);
      formData.append("name", name.trim());
      formData.append("media_id", media_id);
      for (var i = 0; i < operations.length; i++) {
        formData.append("operations[]", JSON.stringify(operations[i]));
      }

      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
      for (let i = 0; i < business_type.length; i++) {
        formData.append("business_type[]", business_type[i]);
      }

      if (longitude) {
        formData.append("longitude", longitude);
      }

      if (latitude) {
        formData.append("latitude", latitude);
      }

      if (waze_map) {
        formData.append("waze_map", waze_map);
      }

      if (google_map) {
        formData.append("google_map", google_map);
      }

      // formData.append("operations", operations);
      // formData.append("operation_days", operation_days);
      // formData.append("operation_hours", operation_hours);
      formData.append("phone", phone);
      formData.append("pic", pic);
      formData.append("postcode", postcode);
      formData.append("state", state);
      formData.append("email", email);
      formData.append("is_active", is_active);
      // if (assign_users != null) {
      formData.append("assign_users", assign_users);
      // }

      const { data } = await axios.post("merchant/add", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data"
        }
      });

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const retrieveMerchant = createAsyncThunk(
  "merchant/retrieveMerchant",
  async ({ paginate = true, filter }, { getState }) => {
    try {
      let url = "merchant";

      if (paginate) {
        url = queryBuilder("merchant", getState().ui.table, filter);
      }

      const { data } = await axios.get(url);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const findMerchantById = createAsyncThunk(
  "merchant/findMerchantById",
  async ({ id }) => {
    try {
      const { data } = await axios.get(`merchant/${id}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
export const updateMerchantById = createAsyncThunk(
  "merchant/updateMerchantrById",
  async ({ id, values }) => {
    try {
      const { data } = await axios.patch(`merchant/${id}`, values);

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const retrieveAllBusinessType = createAsyncThunk(
  "merchant/retrieveAllBusinessType",
  async (_) => {
    try {
      const { data } = await axios.get("business-type/all");

      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const sendOnboardingEmail = createAsyncThunk(
  "merchant/sendOnboardingEmail",
  async ({ id }) => {
    try {
      const { data } = await axios.post(`merchant/send-onboarding-email/${id}`);
      return {
        data
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const generateMerchantSticker = createAsyncThunk(
  "merchant/generateMerchantSticker",
  async ({ id }) => {
    try {
      const fileName = `Topkash_Merchant_Sticker.pdf`;

      const { data } = await axios.get(`merchant/sticker/${id}`, {
        responseType: "blob"
      });

      const blobFile = new Blob([data]);
      fileDownload(blobFile, fileName);
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

// Then, handle actions in your reducers:
const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    resetExchange: () => {
      return {
        ...initialState
      };
    }
  }
});

export default merchantSlice.reducer;
