import axios from "axios";
import fileDownload from "js-file-download";

export const downloadFromUrl = async (url, fileName) => {
    const { data } = await axios.get(url, {
        responseType: "blob"
    })

    const blobFile = new Blob([data]);
    fileDownload(blobFile, fileName);
};

export default { downloadFromUrl }