import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { map } from "lodash";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter
} from "@store/ui";
import { findAllStatuses } from "@store/app";
import { findAllCustomers } from "@store/user";
import TableHeader from "@components/table-header";
import { useAbility } from "@casl/react";
import { AbilityContext, Can } from "@configs/can";
const { Option } = Select;

const Customer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const [tableData, setTableData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const mlmOptions = [
    { value: "upline", label: "Uplines" },
    { value: "downline", label: "Downlines" }
  ];
  // const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  useEffect(() => {
    console.log("filter");
    console.log(filter);
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const actionBtnOnClick = (item) => {
    if (ability.can("update", "Member")) {
      navigate(`/member/${item.id}?mode=edit`);
    } else {
      navigate(`/member/${item.id}?mode=view`);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // if (filter != null) {
      // filter["localTimeOffset"] = localTimeOffset;
      // }
      const { data } = await dispatch(findAllCustomers({ filter })).unwrap();
      // console.log("data");
      // console.log(data);
      const { data: userData, total } = data;

      const statusPayload = await dispatch(findAllStatuses()).unwrap();
      const { data: statusData } = statusPayload;

      const filteredStatusData = statusData.filter((item) =>
        ["Pending", "Processing", "Successful", "Unsuccessful"].includes(
          item.name
        )
      );

      setStatuses(filteredStatusData);
      setTableData(userData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Refresh the page when clicking the navigation menu
  useEffect(() => {
    fetchData();
    setFilter({});
    dispatch(resetTableFilter());
  }, [location]);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const columns = [
    {
      title: "Topkash ID",
      dataIndex: "topkash_id",
      align: "left",
      fixed: "left",
      width: 80
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      width: 100
    },
    // {
    //   title: "Full Name",
    //   dataIndex: "fullname",
    //   align: "left",
    //   width: 140,
    //   render: (_, record) => {
    //     if (record.fullname) {
    //       return <div>{record.fullname}</div>;
    //     } else {
    //       return <div>-</div>;
    //     }
    //   },
    // },
    {
      title: "Mobile",
      dataIndex: "phone_no",
      align: "left",
      width: 140
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      width: 200
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 140,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment(created_at)
              // .add(-localTimeOffset, "minutes")
              .format("DD-MM-YYYY")}
          </div>
        );
      }
    },

    {
      title: "Testing Acc",
      dataIndex: "is_testing_user",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        let is_testing_user;
        if (record.is_testing_user === 1) {
          is_testing_user = "Yes";
        } else {
          is_testing_user = "No";
        }
        return <div align={"middle"}>{is_testing_user}</div>;
      }
    },

    {
      title: () => {
        return (
          <span className={styles.table_column_title}>
            <div>ID Verification</div>
            <div>Status</div>
          </span>
        );
      },
      dataIndex: "kyc_verify_status",
      align: "center",
      key: "kyc_verify_status",
      width: 90,
      render: (_, record) => {
        let status;
        let color;
        let fontWeight;

        if (record.kyc_verify_status === 3) {
          status = "Successful";
          color = "#339933";
        } else if (record.kyc_verify_status === 5) {
          status = "Unsuccessful";
          color = "#FF0000";
        } else if (record.kyc_verify_status === 2) {
          status = "Processing";
          color = "#4f8bc2";
        } else if (record.kyc_verify_status === 1) {
          status = "Pending";
          color = "#ff8000";
          fontWeight = 700;
        } else if (record.kyc_verify_status === 8) {
          status = "Pending Member's Action";
          color = "#4f8bc2";
        }

        return (
          <div align={"middle"} style={{ color, fontWeight }}>
            {status ? status : "-"}
          </div>
        );
      }
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 80,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        if (record.status === 1) {
          status = "Active";
          color = "#339933";
        } else {
          status = "Inactive";
          color = "#FF0000";
        }
        return (
          <div align={"middle"} style={{ color }}>
            {status}
          </div>
        );
      }
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 50,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => actionBtnOnClick(record)}
              >
                {/* {perm.action === "manage"? "Edit" : "View"} */}
                {ability.can("update", "Member") ? "Edit" : "View"}
              </Button>
            </Space>
          </div>
        );
      }
    }
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Members"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          console.log("values");
          console.log(values);
          setFilter(values);
        }}
        createOnClick={() => navigate("/customer/add")}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"topkash_id"} initialValue={null}>
              <Input
                placeholder="Topkash ID"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"username"} initialValue={null}>
              <Input
                placeholder="Username"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* <Col span="6">
            <Form.Item name={"fullname"} initialValue={null}>
              <Input
                placeholder="Full Name"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col> */}
          <Col span="6">
            <Form.Item name={"phone_no"} initialValue={null}>
              <Input
                placeholder="Mobile"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6" offset={6}>
            <Form.Item name={"mlm_level"}>
              <Select
                className="search-select"
                placeholder="MLM Level"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(mlmOptions, (item) => {
                  return (
                    <Option key={item.label} value={item.value}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item name={"is_testing_user"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Testing Account"
                showSearch
                options={[
                  { value: "1", label: "Yes" },
                  { value: "0", label: "No" }
                ]}
                showArrow
              />
            </Form.Item>
          </Col>

          <Col span="6">
            <Form.Item
              name={"id_verification_status"}
              initialValue={[]}
              className={styles.multiple_select}
            >
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                showSearch
                className="table-search-select"
                placeholder="ID Verification Status"
                showArrow
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(statuses, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"status"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Status"
                showSearch
                options={[
                  { value: "1", label: "Active" },
                  { value: "2", label: "Inactive" }
                ]}
                showArrow
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default Customer;
