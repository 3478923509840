import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  Layout,
  Card,
  Row,
  Col,
  Table,
  message,
  Dropdown,
  Menu,
  Tag,
  Space,
  Select,
  Tooltip,
  Empty,
} from "antd";

import {
  SwapOutlined,
  SwapRightOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { AiOutlineReload, AiOutlineMoneyCollect } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { debounce, startCase, find, isEmpty, map } from "lodash";
import CountUp from "react-countup";

import moment from "moment";
//====
import styles from "./styles.module.less";
import PieChart from "./charts/pie-chart";
import LineChart from "./charts/line-chart";
import BarChart from "./charts/bar-chart";

import {
  metricsInitialise,
  metricsReload,
  metricsTransfer,
} from "@store/metric";

const { Content } = Layout;

const Home = () => {
  const dispatch = useDispatch();
  const S3_BUCKET_URL =
    "https://money-exchange-bucket.s3.ap-southeast-1.amazonaws.com/public/flags/";

  const currencies = useSelector((state) => state.currency?.currencies);

  const [totalPending, setTotalPending] = useState(0);
  const [totalReloadPending, setTotalReloadPending] = useState(0);
  const [totalTransferPending, setTotalTransferPending] = useState(0);
  const [reloadPending, setReloadPending] = useState([]);
  const [transferPending, setTransferPending] = useState([]);

  const [pairTransfer, setPairTransfer] = useState([]);

  const [dateType, setDateType] = useState("week");
  const [fromCurrency, setFromCurrency] = useState(1);
  const [toCurrency, setToCurrency] = useState(4);
  const [barchartReloadTransaction, setBarchartReloadTransaction] = useState();
  const [barchartTransferTransaction, setBarchartTransferTransaction] =
    useState();

  const contentWidth = useSelector((state) => state.ui.contentWidth);
  const chartWidth = contentWidth - 38 - 20;
  // const [optionCurrency, setOptionCurrency] = useState([]);
  const [optionFromCurrency, setOptionFromCurrency] = useState([]);
  const [optionToCurrency, setOptionToCurrency] = useState([]);

  useEffect(() => {
    initialise();
  }, []);

  useEffect(() => {
    // Auto refresh when window resize is detected
    const handleWindowResize = debounce(function handleResize() {
      window.location.reload(false);
    }, 1000);

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    currencyList();
  }, [currencies]);
  useEffect(() => {
    reloadTransactionByDate(dateType);
    transferTransactionByDate(dateType);
  }, [dateType]);

  useEffect(() => {}, [barchartTransferTransaction]);

  const initialise = async () => {
    try {
      const { data } = await dispatch(metricsInitialise()).unwrap();
      const {
        pending_transaction,
        reload_pending,
        transfer_pending,
        pair_transfer,
      } = data;
      // setTotalUser(total_user);
      console.log(reload_pending);
      setTotalPending(pending_transaction.total);
      setTotalReloadPending(pending_transaction.reload);
      setTotalTransferPending(pending_transaction.transfer);

      let pieChartReloadPendingData = [
        {
          value: reload_pending.pending,
          name: "Pending",
        },
        {
          value: reload_pending.success,
          name: "Success",
        },
      ];
      let pieChartTransferPendingData = [
        {
          value: transfer_pending.pending,
          name: "Pending",
        },
        {
          value: transfer_pending.success,
          name: "Success",
        },
      ];
      setReloadPending(pieChartReloadPendingData);
      setTransferPending(pieChartTransferPendingData);

      setPairTransfer(pair_transfer);
    } catch (error) {
      message.error(error.message);
    }
  };

  const reloadTransactionByDate = async (type) => {
    try {
      const { data } = await dispatch(
        metricsReload({
          date_type: type,
        })
      ).unwrap();
      setBarchartReloadTransaction(data);
    } catch (error) {
      message.error(error.message);
    }
  };

  const transferTransactionByDate = async (type) => {
    try {
      const { data } = await dispatch(
        metricsTransfer({
          date_type: type,
        })
      ).unwrap();
      setBarchartTransferTransaction(data);
    } catch (error) {
      message.error(error.message);
    }
  };
  const grandTotalIconTheme = (color, background) => {
    return {
      color,
      background,
    };
  };

  const currencyList = () => {
    let option = map(currencies, (item) => {
      return {
        label: (
          <div>
            <img
              width="15px"
              src={S3_BUCKET_URL + item.currency_iso + ".png"}
            />{" "}
            {item.currency_iso}
          </div>
        ),
        value: item.id,
      };
    });

    setOptionFromCurrency(option);

    let optionTo = remappedCurrencyList(fromCurrency);
    setOptionToCurrency(optionTo);
  };

  const remappedCurrencyList = (value) => {
    let option = map(currencies, (item) => {
      if (item.id === value) {
        return {
          label: (
            <div>
              <img
                width="15px"
                src={S3_BUCKET_URL + item.currency_iso + ".png"}
              />{" "}
              {item.currency_iso}
            </div>
          ),
          value: item.id,
          disabled: true,
        };
      } else {
        return {
          label: (
            <div>
              <img
                width="15px"
                src={S3_BUCKET_URL + item.currency_iso + ".png"}
              />{" "}
              {item.currency_iso}
            </div>
          ),
          value: item.id,
        };
      }
    });

    return option;
  };

  const handleToCurrency = (value) => {
    setToCurrency(value);
    let option = remappedCurrencyList(value);
    setOptionFromCurrency(option);
  };
  const handleFromCurrency = (value) => {
    setFromCurrency(value);
    let option = remappedCurrencyList(value);
    setOptionToCurrency(option);
  };
  const handleDateType = (value) => {
    // reloadTransactionByDate();
    setDateType(value);
  };

  return (
    <>
      <Content className={styles.dashboard_container}>
        <div style={{ marginBottom: 20 }}>
          <Space wrap>
            <Select
              defaultValue={fromCurrency}
              style={{ width: 120 }}
              options={optionFromCurrency}
              onChange={handleFromCurrency}
            />
            <SwapOutlined />
            <Select
              defaultValue={toCurrency}
              style={{ width: 120 }}
              options={optionToCurrency}
              onChange={handleToCurrency}
            />
          </Space>
          <iframe
            className={styles.iframe_currency}
            src={`https://chart.holiaodev.com/?from_currency_id=${fromCurrency}&to_currency_id=${toCurrency}&width=100%&height=300`}
            width="100%"
            height="300"
          ></iframe>
        </div>

        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card className={styles.dashboard_card}>
                <Tooltip title="Total for all pending request from Reload and Transfer">
                  <InfoCircleOutlined className={styles.dashboard_card_info} />
                </Tooltip>
                <div className={styles.dashboard_total_card_title}>
                  Total Pending
                </div>

                <div className={styles.dashboard_total_card_subtitle}>
                  <CountUp end={totalPending} />
                </div>

                <div
                  className={styles.dashboard_total_card_icon_wrapper}
                  style={grandTotalIconTheme("#e7515a", "#fff5f5")}
                >
                  <AiOutlineMoneyCollect className={styles.dashboard_icon} />
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className={styles.dashboard_card}>
                <Tooltip title="Total for all pending request from Reload">
                  <InfoCircleOutlined className={styles.dashboard_card_info} />
                </Tooltip>
                <div className={styles.dashboard_total_card_title}>
                  Reloads's Pending
                </div>
                <div className={styles.dashboard_total_card_subtitle}>
                  <CountUp end={totalReloadPending} />
                </div>

                <div
                  className={styles.dashboard_total_card_icon_wrapper}
                  style={grandTotalIconTheme("#4361ee", "#eaf1ff")}
                >
                  <AiOutlineReload className={styles.dashboard_icon} />
                </div>
              </Card>
            </Col>

            <Col span={8}>
              <Card className={styles.dashboard_card}>
                <Tooltip title="Total for all pending request Transfer">
                  <InfoCircleOutlined className={styles.dashboard_card_info} />
                </Tooltip>
                <div className={styles.dashboard_total_card_title}>
                  Transfers's Pending
                </div>
                <div className={styles.dashboard_total_card_subtitle}>
                  <CountUp end={totalTransferPending} />
                </div>

                <div
                  className={styles.dashboard_total_card_icon_wrapper}
                  style={grandTotalIconTheme("#009688", "#ddf5f0")}
                >
                  <BiTransfer className={styles.dashboard_icon} />
                </div>
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Card className={styles.dashboard_card}>
                <PieChart
                  title="Today Reload's Pending Request"
                  data={reloadPending}
                  style={{ width: chartWidth / 2, height: 300 }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card className={styles.dashboard_card}>
                <PieChart
                  title="Today Transfer's Pending Request"
                  data={transferPending}
                  style={{ width: chartWidth / 2, height: 300 }}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Card className={styles.dashboard_card}>
                <div style={{ fontWeight: 600, fontSize: 18 }}>
                  Today's Currency Pairs trade
                </div>
                <Space
                  direction="horizontol"
                  size="middle"
                  className={styles.card_pair_container}
                >
                  {map(pairTransfer, (item, index) => {
                    if (item?.from_currency_code) {
                      return (
                        <>
                          <div
                            key={index}
                            className={styles.card_pair_transfer}
                          >
                            <div className={styles.card_pair_title_row}>
                              <span>
                                <img
                                  width="35px"
                                  src={
                                    S3_BUCKET_URL +
                                    item.from_currency_code +
                                    ".png"
                                  }
                                />
                                <div className={styles.card_pair_title}>
                                  {item.from_currency_code}
                                </div>
                              </span>

                              <SwapRightOutlined
                                // className={styles.card_pair_title}
                                style={{
                                  fontSize: 20,
                                  color: "#b5b5c3",
                                }}
                              />

                              <span>
                                <img
                                  width="35px"
                                  src={
                                    S3_BUCKET_URL +
                                    item.to_currency_code +
                                    ".png"
                                  }
                                />
                                <div className={styles.card_pair_title}>
                                  {item.to_currency_code}
                                </div>
                              </span>
                            </div>

                            <div className={styles.card_pair_count}>
                              <CountUp end={item.count} />
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return <Empty />;
                    }
                  })}
                </Space>
              </Card>
            </Col>
          </Row>

          <div>
            <Select
              defaultValue="week"
              style={{ width: 120 }}
              onChange={handleDateType}
              options={[
                { value: "day", label: "Days" },
                { value: "week", label: "Weeks" },
                { value: "month", label: "Months" },
                { value: "year", label: "Years" },
              ]}
            />
          </div>
          {/* <Space direction="vertical" size="large"> */}
          <div style={{ fontWeight: 600, fontSize: 18 }}>
            Reload's Transaction by{" "}
            <span style={{ textTransform: "capitalize" }}>{dateType}s</span>
          </div>
          <Row gutter={[16, 16]}>
            {map(barchartReloadTransaction, (item, index) => {
              let resArray = map(item, (value) => {
                return [value.date_type, value.total];
              });
              return (
                <Col flex="auto">
                  <Card className={styles.chart_card}>
                    <LineChart
                      style={{
                        width: "100%",
                        height: 150,
                      }}
                      title={`${index}`}
                      data={resArray}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>

          <div style={{ fontWeight: 600, fontSize: 18 }}>
            Transfer's Transaction by{" "}
            <span style={{ textTransform: "capitalize" }}>{dateType}s</span>
          </div>
          <Row gutter={[16, 16]}>
            {map(barchartTransferTransaction, (item, index) => {
              let resArray = map(item, (value) => {
                return [value.date_type, value.total];
              });
              return (
                <Col flex="auto">
                  <Card className={styles.chart_card}>
                    <LineChart
                      style={{
                        width: "100%",
                        height: 150,
                      }}
                      title={`${index}`}
                      data={resArray}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>

          <div style={{ fontWeight: 600, fontSize: 18 }}>
            Profit by{" "}
            <span style={{ textTransform: "capitalize" }}>{dateType}s</span>
          </div>
          <Row gutter={[16, 16]}>
            {map(barchartTransferTransaction, (item, index) => {
              let resArray = map(item, (value) => {
                return [value.date_type, value.earn];
              });
              return (
                <Col flex="auto">
                  <Card className={styles.chart_card}>
                    <LineChart
                      style={{
                        width: "100%",
                        height: 150,
                      }}
                      title={`${index}`}
                      data={resArray}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Space>
      </Content>
    </>
  );
};

export default Home;
