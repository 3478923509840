import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select } from "antd";
import { find, map, isEmpty } from "lodash";

// ============
import styles from "./styles.module.less";
import { getAllCountries } from "../../store/country";
import { retrieveBanks, addBankBranch, getAllBanks } from "../../store/bank";

const { Option } = Select;

const BanksByCountryAddBranch = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [banks, setBanks] = useState([]);
  // const { params } = useMatch("banks-by-country-add-branch/:id");
  const formRef = useRef();

  const [selectedCountry, setSelectedCountry] = useState({
    id: null,
    name: "",
    iso_code: "",
  });

  const [selectedBank, setSelectedBank] = useState({
    id: null,
    name: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve countries

        const countriesPayload = await dispatch(getAllCountries()).unwrap();
        const { data: countryData } = countriesPayload;

        setCountries(countryData);

        // Retrieve banks

        const bankPayload = await dispatch(getAllBanks()).unwrap();
        const { data } = bankPayload;

        setBanks(data);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isEmpty(params)) {
      countriesOnChange(parseInt(params.id));
    }
  }, [countries]);

  useEffect(() => {
    formRef.current.setFieldsValue({
      country: selectedCountry.id,
    });
  }, [selectedCountry]);

  const countriesOnChange = (value) => {
    const selectCountry = find(countries, (item) => item.id === value);
    if (selectCountry) {
      setSelectedCountry({
        id: selectCountry.id,
        name: selectCountry.name,
        iso_code: selectCountry.iso_code_2,
      });
    }
  };

  const banksOnChange = (value) => {
    const selectBank = find(banks, (item) => item.id === value);

    if (selectBank) {
      setSelectedBank({
        id: selectBank.id,
        name: selectBank.name,
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (values) {
        await dispatch(
          addBankBranch({
            bank_id: selectedBank.id,
            branch_name: values.branch_name,
            country_id: selectedCountry.id,
          })
        ).unwrap();

        message.success("You have successfully added new bank account");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>ADD NEW BRANCH</div>
        </Row>
        <br />
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <div className={styles.select_container}>
            <Form.Item
              name="bank"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Bank name cannot be empty",
                },
              ]}
            >
              <Select
                name="bank"
                value={selectedBank}
                placeholder="Select Bank"
                onChange={banksOnChange}
              >
                {map(banks, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.input}>
            <Form.Item
              name="branch_name"
              label={<div className={styles.label}>Branch Name</div>}
              rules={[
                {
                  required: true,
                  message: "Please enter the branch name",
                },
              ]}
            >
              <Input placeholder="Branch Name" />
            </Form.Item>
          </div>
          <div className={styles.select_container}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Country cannot be empty",
                },
              ]}
            >
              <Select
                name="country"
                value={selectedCountry}
                placeholder="Select Country"
                onChange={countriesOnChange}
                showSearch
                disabled={params?.id}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {map(countries, (item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="secondary_btn"
              >
                Add
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BanksByCountryAddBranch;
