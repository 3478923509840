import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "@configs";
import { errorHandler, queryBuilder } from "../configs";
import { values } from "lodash";

export const findAllDeleteMemberRequests = createAsyncThunk(
  "delete-member-request/findAllDeleteMemberRequests",
  async ({ filter }, { getState }) => {
    try {
      const url = queryBuilder(
        "delete-member-request",
        getState().ui.table,
        filter
      );
      const { data } = await axios.get(url);

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const createDeleteMemberRequest = createAsyncThunk(
  "delete-member-request/createDeleteMemberRequest",
  async (values) => {
    try {
      let formData = new FormData();

      console.log(values);

      formData.append("username", values.username);
      formData.append("topkash_id", values.topkash_id);
      formData.append("phone_no", values.phone_no);
      formData.append("password", values.password);

      const { data } = await axios.post("delete-member-request", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);

export const updateDeleteMemberRequest = createAsyncThunk(
  "delete-member-request/updateDeleteMemberRequest",
  async ({ status, notes, id }) => {
    try {
      const formData = {
        status,
        notes,
      };

      const { data } = await axios.patch(
        `delete-member-request/${id}`,
        formData
      );

      return {
        data,
      };
    } catch (error) {
      throw errorHandler.generateErrorString(error);
    }
  }
);
