import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Input,
  Table,
  Menu,
  Dropdown,
  Switch,
  message,
  Space,
  Tooltip,
} from "antd";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableSearch,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { retrieveCountries } from "../../store/country";
import {
  setSelectedCountry,
  updateCountryStatus,
  updateCountryForTransacts,
} from "../../store/country";

const Country = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [isEnable, setIsEnable] = useState(false);
  const [isForTransactsEnable, setIsForTransactsEnable] = useState(false);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      align: "center",
      key: "id",
      sorter: true,
      render: (_, record) => {
        return <div>{record.id}</div>;
      },
    },

    {
      title: "Country Name",
      dataIndex: "country_name",
      align: "center",
      width: "35%",
      key: "country_name",
      sorter: true,
      render: (_, record) => {
        return <div>{record.name}</div>;
      },
    },

    {
      title: "Country Iso Code",
      dataIndex: "iso_code",
      width: "30%",
      align: "center",
      key: "iso_code",
      render: (_, record) => {
        return <div>{record.iso_code_2}</div>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      width: "20%",
      align: "center",
      key: "status",
      render: (_, record) => {
        return (
          <div className={styles.switch}>
            <Switch
              checked={record.is_enabled == 1 ? true : false}
              onChange={() => handleSwitch(record)}
            />
          </div>
        );
      },
    },
    {
      title: "For transactions",
      dataIndex: "for_transact",
      width: "20%",
      align: "center",
      key: "for_transact",
      render: (_, record) => {
        return (
          <div className={styles.switch}>
            <Switch
              checked={record.for_transacts == 1 ? true : false}
              onChange={() => handleForTransactsSwitch(record)}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "left",
      width: "20%",
      key: "action",
      render: (_, record) => {
        // const actions = [
        //   {
        //     label: "Edit",
        //     key: "edit",
        //   },
        // ];

        return (
          <div>
            <Space size="middle">
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ fontSize: "15px", color: "#4361ee" }}
                  onClick={() => editOnClick(record)}
                />
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(resetTablePagination());
    dispatch(resetTableSearch());
    dispatch(resetTableFilter());
    dispatch(resetTableSort());
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [isEnable, isForTransactsEnable]);

  useEffect(() => {
    if (tableData != []) {
      setIsEnable(true);
      setIsForTransactsEnable(true);
    } else {
      setIsEnable(false);
      setIsForTransactsEnable(false);
    }
  }, [tableData]);

  const handleSwitch = async (item) => {
    setIsEnable(!isEnable);
    try {
      const { data } = await dispatch(
        updateCountryStatus({
          id: item.id,
        })
      ).unwrap();
      if (data.message == "Enabled") {
        message.success("You have enabled the country");
      } else if (data.message == "Disabled") {
        message.warning("You have disabled the country");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleForTransactsSwitch = async (item) => {
    setIsForTransactsEnable(!isForTransactsEnable);
    try {
      const { data } = await dispatch(
        updateCountryForTransacts({
          id: item.id,
        })
      ).unwrap();
      if (data.message == "Enabled for transacts") {
        message.success("You have enabled the country for transacts");
      } else if (data.message == "Disabled for transacts") {
        message.warning("You have disabled the country for transacts");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const editOnClick = (item) => {
    dispatch(
      setSelectedCountry({
        selectedCountry: item,
      })
    );
    navigate(`/country/${item.id}`);
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatch(retrieveCountries()).unwrap();
      const { data: countryData, total } = data;

      setTableData(countryData);
      setTableTotalData(total);

      // dispatch(resetTableSearch());
      // dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    }
  };

  const onChangeText = useCallback(
    debounce((text) => {
      if (text) {
        dispatch(
          setTableSearch({
            search: text,
          })
        );
        dispatch(resetTablePagination());
      } else {
        dispatch(resetTableSearch());
      }

      fetchData();
    }, 500),
    []
  );

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let key = sorter.columnKey;
      let field = sorter.field;

      if (key == "country_name") {
        field = "name";
      }

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
  };

  return (
    <div className={styles.container}>
      <div style={{ marginBottom: 40 }}>
        <Row align="middle">
          <div className="table_search_input">
            <Input
              placeholder="Search..."
              suffix={<SearchOutlined className={styles.search_icon} />}
              allowClear
              onChange={(e) => {
                onChangeText(e.target.value);
              }}
            />
          </div>
        </Row>
      </div>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
        }}
      />
    </div>
  );
};

export default Country;
