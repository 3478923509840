import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "@store/ui";
import { findAllSuperAdmins } from "@store/user";
import TableHeader from "@components/table-header";

const SuperAdminList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { RangePicker } = DatePicker;
  const [filter, setFilter] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table)
  // const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  // const editOnClick = (item) => {
  //   navigate(`/super-admin/${item.id}`);
  // };

  const fetchData = async () => {
    try {
      // if (filter != null) {
      //   filter["localTimeOffset"] = localTimeOffset;
      // }
      const { data } = await dispatch(findAllSuperAdmins({ filter })).unwrap();
      console.log(data);
      const { data: userData, total } = data;

      setTableData(userData);
      setTableTotalData(total);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());

    fetchData();
  }, []);

  const resetButtonOnClick = () => {
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    setFilter({});
    fetchData();
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      align: "left",
      fixed: "left",
      width: 80,
    },
    {
      title: "User Type",
      dataIndex: "role",
      align: "left",
      width: 100,
    },
    {
      title: "Username",
      dataIndex: "username",
      align: "left",
      width: 140,
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      align: "left",
      width: 140,
      render: (_, record) => {
        if (record.fullname) {
          return <div>{record.fullname}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: "Mobile",
      dataIndex: "phone_no",
      align: "left",
      width: 140,
      render: (_, record) => {
        if (record.phone_no) {
          return <div>{record.phone_no}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      width: 200,
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 140,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment(created_at)
              // .add(-localTimeOffset, "minutes")
              .format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 100,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        if (record.status === 1) {
          status = "Active";
          color = "#95de64";
        } else {
          status = "Blocked";
          color = "#FF0000";
        }
        return (
          <div align={"middle"} style={{ color }}>
            {status}
          </div>
        );
      },
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   align: "center",
    //   width: 80,
    //   fixed: "right",
    //   render: (_, record) => {
    //     return (
    //       <div align={"middle"}>
    //         <Space size="middle">
    //           <Button
    //             size={"small"}
    //             className="action_btn "
    //             onClick={() => editOnClick(record)}
    //           >
    //             Edit
    //           </Button>
    //         </Space>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Super Admins"
        showCreateButton={false}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          dispatch(resetButtonOnClick());
          // dispatch(resetTablePagination());
          // dispatch(resetTableFilter());
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"id"} initialValue={null}>
              <Input
                placeholder="User ID"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"username"} initialValue={null}>
              <Input
                placeholder="Username"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"fullname"} initialValue={null}>
              <Input
                placeholder="Full Name"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name={"status"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Status"
                options={[
                  { value: "1", label: "Active" },
                  { value: "2", label: "Blocked" },
                ]}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage
        }}
      />
    </div>
  );
};

export default SuperAdminList;
