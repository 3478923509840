import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Form,
  Input,
  Row,
  Select,
  Button,
  message,
  Space,
  Divider,
  Table
} from "antd";

import styles from "./styles.module.less";
import {
  getAllRoles,
  findUserById,
  updateDashboardUser,
  findActivityLogsByRefIdAndRefTable,
  resendEmailForPasswordLink
} from "@store/app";
import { getAllCountries } from "@store/country";
import HistoryTable from "../../../components/history-table";

const CustomerServiceEdit = () => {
  const { params } = useMatch("settings/staff/:id");
  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();

  const [selectedRole, setSelectedRole] = useState({
    id: null,
    name: ""
  });
  const [countries, setCountries] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [isResendLink, setIsResendLink] = useState(false);

  const updateProfile = async (values) => {
    try {
      const { data } = await dispatch(
        updateDashboardUser({
          fullname: values.fullname,
          email: values.email,
          phone_no: values.phone_no,
          status: parseInt(values.status),
          role_id: selectedRole.id,
          dashboard_user_id: params.id
        })
      ).unwrap();

      message.success(data.message);
      navigate(-1);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(getAllRoles());
    const fetchData = async () => {
      // Retrieve countries
      try {
        const countriesPayload = await dispatch(getAllCountries()).unwrap();
        const { data: countryData } = countriesPayload;
        setCountries(countryData);

        // Retrieve dashbord user

        const userPayload = await dispatch(
          findUserById({
            id: params.id
          })
        ).unwrap();

        const { data: userData } = userPayload;

        if (userData) {
          formRef.current.setFieldsValue({
            role: userData.role,
            role_id: userData.role_id,
            username: userData.username,
            fullname: userData.fullname,
            email: userData.email,
            phone_no: userData.phone_no,
            status:
              userData.status === 1
                ? statusOption[0].value
                : statusOption[1].value,
            created_at: moment(userData.created_at).format(
              "YYYY-MM-DD, hh:mm:ss A"
            )
          });

          console.log(userData.resend_link);
          setUserDetail(userData);
          setIsResendLink(userData.resend_link);
        }

        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "users"
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchData();
  }, []);

  const goBack = () => {
    window.history.go(-1);
  };

  const phoneNumberOnChange = async ({ target }) => {
    const { value } = target;

    let text = value;

    if (typeof text === "number") {
      text = value.toString();
    }

    const formattedPhoneNumber = text.replace(/[^0-9]/gi, "");
    formRef.current.setFieldValue("phone_no", formattedPhoneNumber);
  };

  const resendOnClick = async () => {
    try {
      const { data } = await dispatch(
        resendEmailForPasswordLink({
          id: params.id
        })
      ).unwrap();

      message.success(data.message);

      // dispatch(resendEmailForPasswordLink({ user_id: params.id })).unwrap();
    } catch (error) {
      message.error(error.message);
    }
  };

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className="page-title">Edit Staff</div>
        </Row>
        <Form
          colon={false}
          ref={formRef}
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          // style={{
          //   maxWidth: 1000,
          // }}
          initialValues={{
            remember: true
          }}
          onFinish={(values) => {
            updateProfile(values);
          }}
          className="form-details"
        >
          <Form.Item name="status" label="Status">
            <Select
              name="status"
              options={statusOption}
              defaultValue={"Active"}
            ></Select>
          </Form.Item>

          <Form.Item name="id" label="Staff ID" className="label-details">
            <div>{userDetail.id}</div>
          </Form.Item>

          <Form.Item name="role" label="Staff Type" className="label-details">
            <div>{userDetail.role?.name}</div>
          </Form.Item>

          <Divider className="divider" />

          <Form.Item name="username" label="Username" className="input">
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="fullname"
            label="Full Name"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the full name"
              }
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the email"
              },
              {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Please enter a valid email"
              }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="phone_no"
            label="Mobile"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the mobile number",

                validator: async (_, value) => {
                  let text = value;
                  if (text) {
                    if (typeof text === "number") {
                      text = value.toString();
                    }
                  } else {
                    throw new Error("Please enter Mobile number");
                  }
                }
              }
            ]}
          >
            <Input placeholder="Mobile" onChange={phoneNumberOnChange} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16
            }}
          >
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <Space>
                  <Button onClick={goBack}>Cancel</Button>
                  <Button
                    type="default"
                    htmlType="submit"
                    className="ant-btn-green"
                  >
                    Save
                  </Button>

                  {userDetail.hasOwnProperty("resend_link") &&
                    userDetail.resend_link === true && (
                      <Button
                        onClick={() => resendOnClick()}
                        className="ant-btn-green"
                      >
                        Resend Email Invitation
                      </Button>
                    )}
                </Space>
              </div>
            </Row>
          </Form.Item>
        </Form>

        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default CustomerServiceEdit;
