import React, { useEffect, useState } from "react";
import {
  Table,
  Dropdown,
  Menu,
  Row,
  Button,
  Empty,
  message,
  Collapse,
  Tooltip,
  Space,
} from "antd";
import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import styles from "./styles.module.less";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { map } from "lodash";

import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../store/ui";
import { retrieveBankAccountsByCountry } from "../../store/bank";

const BanksAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const contentHeight = useSelector((state) => state.ui.contentHeight);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    dispatch(resetTableFilter());
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await dispatch(retrieveBankAccountsByCountry()).unwrap();

      setTableTotalData(data.length);
      setTableData(
        map(data, (item) => {
          return {
            ...item,
            key: item.id,
          };
        })
      );
    } catch (error) {
      message.error(error.message);
    }
  };

  const parentMenuOnClick = async ({ key }, item) => {
    if (key === "add") {
      navigate(`/bank-accounts-add-account/`);
    }
  };

  // const addBankAccount = (item) => {
  //   navigate(`/bank-accounts-add-account/${item.id}`);
  // };

  const editOnClick = async (item) => {
    navigate(`/bank-accounts-edit-account/${item.id}`, {
      state: {
        bank_accounts: item,
      },
    });
  };

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let key = sorter.columnKey;
      let field = sorter.field;

      if (key == "id") {
        field = "bank_country_id";
      }

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  const expandedRowRender = (record) => {
    let accounts = record.bank_accounts;
    const columns = [
      // {
      //   title: <div style={{ fontWeight: 700 }}>Account ID</div>,
      //   dataIndex: "id",
      //   key: "id",
      //   align: "center",
      // },
      {
        title: <div style={{ fontWeight: 700 }}>Account Name</div>,
        dataIndex: "acc_name",
        key: "acc_name",
        align: "center",
      },
      {
        title: <div style={{ fontWeight: 700 }}>Account No.</div>,
        dataIndex: "acc_no",
        key: "acc_no",
        align: "center",
      },
      {
        title: <div style={{ fontWeight: 700 }}>Bank Name</div>,
        dataIndex: ["bank_country", "bank_name"],
        key: "bank_name",
        align: "center",
      },
      {
        title: <div style={{ fontWeight: 700 }}>Action</div>,
        dataIndex: "action",
        align: "center",
        width: "10%",
        key: "action",
        render: (_, record) => {
          const actions = [
            {
              label: "Edit",
              key: "edit",
            },
          ];

          return (
            <div>
              {record.id && record.acc_name && (
                <Space size="middle">
                  <Tooltip title="Edit">
                    <EditOutlined
                      style={{ fontSize: "15px", color: "#4361ee" }}
                      onClick={() => editOnClick(record)}
                    />
                  </Tooltip>
                </Space>
              )}
            </div>
          );
        },
      },
    ];
    if (record.bank_accounts.length > 0) {
      return (
        <Table columns={columns} dataSource={accounts} pagination={false} />
      );
    } else {
      return null;
    }
  };

  const rowExpandable = (record) => {
    if (record.bank_accounts.length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "10%",
      align: "center",
      key: "id",
      sorter: true,
      render: (_, record) => {
        return (
          <div style={{ color: "blue", fontWeight: "700" }}>{record.id}</div>
        );
      },
    },

    {
      title: "Country Name",
      dataIndex: "name",
      align: "center",
      width: "30%",
      key: "name",
      sorter: true,
    },
    {
      title: "Country Code",
      dataIndex: "iso_code_3",
      align: "center",
      width: "30%",
      key: "iso_code_3",
      sorter: true,
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   align: "center",
    //   width: "15%",
    //   key: "action",
    //   render: (_, record) => {
    //     return (
    //       <div>
    //         <Space size="middle">
    //           <Tooltip title="Edit">
    //             <EditOutlined
    //               style={{ fontSize: "15px", color: "#4361ee" }}
    //               onClick={() => editOnClick(record)}
    //             />
    //           </Tooltip>
    //         </Space>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className={styles.container}>
        {/* <div style={{ marginBottom: 40 }}>
          <Row justify="end">
            <div className={styles.secondary_btn}>
              <Button
                className="secondary_btn"
                onClick={() => navigate("/banks-by-country-add-branch")}
              >
                + New Account
              </Button>
            </div>
          </Row>
        </div> */}

        <div style={{ marginBottom: 40 }}>
          <Row justify="space-between" align="middle">
            <Row align="middle"></Row>
            <Row justify="end">
              <div className={styles.secondary_btn}>
                <Button
                  className="secondary_btn"
                  onClick={() => navigate("/bank-accounts-add-account")}
                >
                  + Create Bank Account
                </Button>
              </div>
            </Row>
          </Row>
        </div>
        <Table
          className={styles.pagination_item}
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
          size="small"
          scroll={{
            y: contentHeight - 90 - 40 - 56 - 70,
          }}
          expandable={{
            expandedRowRender,
            rowExpandable,
            defaultExpandedRowKeys: [],
          }}
          pagination={{
            total: tableTotalData,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
          }}
        />
      </div>
    </>
  );
};

export default BanksAccount;
