import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Input,
  Table,
  Button,
  message,
  Space,
  DatePicker,
  Select,
  Form,
  Row,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  resetTableSort,
  resetTableSearch,
  resetTablePagination,
  resetTableFilter,
} from "../../../store/ui";
import { findAllDashboardUsers } from "../../../store/app";
import TableHeader from "../../../components/table-header";
import {
  retrieveBanksWithCurrency,
  setSelectedBank,
} from "../../../store/bank";
import { map } from "lodash";

const NewBank = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);

  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);

  const currencies = useSelector((state) => state.currency.currencies);
  const { contentHeight } = useSelector((state) => state.ui.contentHeight);
  const { current: currentPage } = useSelector((state) => state.ui.table);
  const [isLoading, setIsLoading] = useState(false);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  useEffect(() => {
    dispatch(resetTableFilter());
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());
    fetchData();
  }, []);

  const editOnClick = (item) => {
    const mappedCurrencyId = map(item.currency_data, (item) => {
      return item.currency_id;
    });

    navigate(`/settings/bank/${item.currency_data[0].bank_id}`);

    dispatch(
      setSelectedBank({
        selectedBank: {
          bank_name: item.bank_name,
          currency: mappedCurrencyId,
        },
      })
    );
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatch(
        retrieveBanksWithCurrency({ filter })
      ).unwrap();
      const { data: banksData, total } = data;

      setTableData(banksData);
      setTableTotalData(total);

      dispatch(resetTableSort());
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTablePagination());

    fetchData();
  }, []);

  const columns = [
    {
      title: "Bank",
      dataIndex: "bank_name",
      align: "left",
      fixed: "left",
      width: 100,
      // sorter: true,
    },
    {
      title: "Currencies",
      dataIndex: "currency",
      align: "left",
      width: 140,
      render: (_, record) => {
        const { currency_data } = record;

        if (currency_data.length > 0) {
          return map(currency_data, (item) => (
            <div className={styles.table_column_title}>
              {item.currency_iso_code} - {item.currency_name}
            </div>
          ));
        } else {
          return <div>-</div>;
        }
      },
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 80,
      sorter: true,
      render: (_, record) => {
        const { currency_data } = record;
        return (
          <div>
            {moment
              .utc(currency_data[0].created_at)
              .tz("Asia/Phnom_Penh")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 40,
      fixed: "right",
      render: (_, record) => {
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Button
                size={"small"}
                className="ant-btn-action "
                onClick={() => editOnClick(record)}
              >
                Edit
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      dispatch(
        setTableSort({
          sort: `${sorter.field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Banks"
        showCreateButton={true}
        createButtonText={"Create New Bank"}
        showResetButton={true}
        showFilterButton={true}
        createOnClick={() => navigate("/settings/bank-add")}
        filterFormOnFinish={(values) => {
          setFilter(values);
        }}
        resetOnClick={() => {
          setFilter({});
        }}
      >
        <>
          <Col span="6">
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col align="right">
            <Row gutter={16}>
              <Col span="12">
                <Form.Item name={"bank_name"} initialValue={null}>
                  <Input
                    placeholder="Bank Name"
                    className="filter-search"
                    suffix={<SearchOutlined className={styles.search_icon} />}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  name={"currency_code"}
                  initialValue={[]}
                  className={styles.multiple_select}
                >
                  <Select
                    name="select-multiple"
                    mode="multiple"
                    allowClear
                    className="table-search-select"
                    placeholder="Currency"
                    options={
                      currencies && currencies.length > 0
                        ? map(currencies, (item) => {
                            return {
                              value: item.iso_code,
                              label: item.iso_code,
                            };
                          })
                        : null
                    }
                    showArrow
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      </TableHeader>
      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default NewBank;
