import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card, Form, Input, Row, Button, message, Select, Space } from "antd";
import { find, map, isEmpty } from "lodash";

// ============
import styles from "./styles.module.less";
import { countryWithCurrencies } from "@store/country";
import { retrieveAllCurrencies } from "@store/currency";
import { createProcessingFeeBaseFixedRate } from "@store/base-fixed-rates";
import moment from "moment";
import { unformat, formatNumber } from "accounting-js";

const { Option } = Select;

const BankAccountsAddAccount = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState([]);
  const formRef = useRef();

  const [selectedCountry, setSelectedCountry] = useState({
    id: null,
    name: "",
    iso_code: ""
  });

  const [selectedCurrency, setSelectedCurrency] = useState({
    id: null,
    name: "",
    iso_code_2: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve currencies
        const currency = await dispatch(retrieveAllCurrencies()).unwrap();
        const { data: currencyData } = currency;
        setCurrency(currencyData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!isEmpty(params)) {
      countriesOnChange(parseInt(params.id));
    }
  }, [countries]);

  const countriesOnChange = (value) => {
    const selectCountry = find(countries, (item) => item.id === value);
    if (selectCountry) {
      setSelectedCountry({
        id: selectCountry.id,
        name: selectCountry.name,
        iso_code: selectCountry.iso_code_2
      });
    }
  };

  const handleSubmit = async (values) => {
    const currentDate = moment().format("YYYY-MM-DD");
    try {
      if (values) {
        await dispatch(
          createProcessingFeeBaseFixedRate({
            from_currency_id: values.from_currency_id,
            to_currency_id: values.to_currency_id,
            amount: values.amount,
            date: currentDate
          })
        ).unwrap();

        message.success("You have successfully added new fee");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const keyboardOnChange = async ({ target }) => {
    const { value } = target;
    let text = value;

    if (typeof text === "number") {
      text = text.toString();
    }

    const amount = unformat(text.replace(/(\.|\,)/gi, "")) / 100;
    const formattedAmount = formatNumber(amount, { precision: 2 });

    formRef.current.setFieldsValue({
      amount: formattedAmount
    });
  };

  const currencyOnChange = async (id) => {
    // Retrieve countries

    const countriesPayload = await dispatch(
      countryWithCurrencies({ currencyId: id })
    ).unwrap();
    const { data: countryData } = countriesPayload;

    setCountries(countryData);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>New Fee</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item
            name="from_currency_id"
            // value={selectedCurrency}

            label="From Currency"
            rules={[
              {
                required: true,
                message: "Please select a currency"
              }
            ]}
          >
            <Select
              name="from_currency_id"
              // value={selectedCurrency}
              placeholder="Select Currency"
              onChange={currencyOnChange}
            >
              {map(currency, (item) => {
                console.log(item);
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="to_currency_id"
            // value={selectedCurrency}

            label="To Currency"
            rules={[
              {
                required: true,
                message: "Please select a currency"
              }
            ]}
          >
            <Select
              name="to_currency_id"
              // value={selectedCurrency}
              placeholder="Select Currency"
              onChange={currencyOnChange}
            >
              {map(currency, (item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.iso_code}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            className="input"
            rules={[
              {
                pattern: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
                message: "Please enter minimum amount"
              },
              {
                required: true,
                message: "Please input the amount"
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={keyboardOnChange}
            />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button onClick={() => navigate(-1)} className="default_btn">
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default BankAccountsAddAccount;
