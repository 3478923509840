import React, { useEffect, useState } from "react";
import {
  Table,
  DatePicker,
  message,
  Form,
  Select,
  Col,
  Row,
  Tooltip,
  Button,
  Input,
  Space,
  Image,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { map, chain, isEmpty, find } from "lodash";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";

// ============
import styles from "./styles.module.less";
import {
  setTablePagination,
  setTableSort,
  setTableFilter,
  resetTablePagination,
  resetTableSort,
  resetTableSearch,
  resetTableFilter,
} from "@store/ui";

import { retrieveMoneyChanger } from "@store/money-changer";

import TableHeader from "@components/table-header";
import { toNumber } from "lodash";
import { SearchOutlined } from "@ant-design/icons";

const MoneyChanger = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contentHeight, table } = useSelector((state) => state.ui);
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState(0);
  const [optionCurrency, setOptionCurrency] = useState(0);

  const [dateRange, setDateRange] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const localTimeOffset = useSelector((state) => state.app.localTimeOffset);

  const { RangePicker } = DatePicker;

  const currencies = useSelector((state) => state.currency?.currencies);
  const { current: currentPage } = useSelector((state) => state.ui.table);

  useEffect(() => {
    dispatch(resetTableSearch());
    dispatch(resetTableSort());
    dispatch(resetTableFilter());
    dispatch(resetTablePagination());

    // dispatch(setTableFilter({ from_date: moment().format("YYYY-MM-DD") }));
  }, []);

  useEffect(() => {
    dispatch(resetTablePagination());
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (filter != null) {
        filter["localTimeOffset"] = localTimeOffset;
      }

      const { data: moneyChanger } = await dispatch(
        retrieveMoneyChanger({
          filter,
        })
      ).unwrap();

      setTableData(moneyChanger.data);
      setTableTotalData(moneyChanger.total);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleChange = (range) => {
  //   setDateRange(range);
  // };

  useEffect(() => {
    currencyList();
  }, [currencies]);

  const currencyList = () => {
    let option = map(currencies, (item) => {
      return {
        label: item.iso_code,
        value: item.iso_code,
      };
    });

    setOptionCurrency(option);
  };
  const remappedCurrencyList = (values) => {
    let option = map(currencies, (item) => {
      if (values.includes(item.iso_code)) {
        return {
          label: item.iso_code,
          value: item.iso_code,
          disabled: true,
        };
      } else {
        return {
          label: item.iso_code,
          value: item.iso_code,
        };
      }
    });

    return option;
  };

  const columns = [
    {
      title: "Business Name",
      width: 200,
      dataIndex: "name",
      key: "date",
      align: "left",
      render: (_, record) => {
        const { name } = record;
        return <div>{name}</div>;
      },
    },

    {
      title: "Person In Charge",
      width: 200,
      dataIndex: "pic",
      key: "pic",
      align: "left",
      render: (_, record) => {
        const { pic } = record;

        return <div>{pic}</div>;
      },
    },

    {
      title: "Phone",
      width: 200,
      dataIndex: "phone",
      key: "phone",
      align: "left",
      render: (_, record) => {
        const { phone } = record;

        return <div>{phone}</div>;
      },
    },

    // {
    //   title: "Email",
    //   width: 200,
    //   dataIndex: "email",
    //   key: "email",
    //   align: "left",
    //   render: (_, record) => {
    //     const { email } = record;

    //     return <div>{email}</div>;
    //   }
    // },
    {
      title: "Currency Available",
      width: 240,
      dataIndex: "currency_available",
      key: "currency_available",
      align: "left",
      render: (_, record) => {
        const { currency_available } = record;
        const currencyArr = currency_available.split(",");
        let currencyMap = map(currencyArr, (item, index) => {
          let findCurrency = find(currencies, {
            id: toNumber(item),
          });
          return findCurrency?.iso_code;
        });

        return <div>{currencyMap.join(", ")}</div>;
      },
    },

    {
      title: "Logo",
      dataIndex: "file_url",
      align: "left",
      width: 200,
      render: (_, record) => {
        if (record.file_url) {
          return (
            <Image
              style={{
                height: "64px",
                width: "64px",
                border: "1px solid #dcdcdc",
                borderRadius: "5px",
              }}
              src={record.file_url}
            />
          );
        } else {
          return <div>-</div>;
        }
      },
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      align: "left",
      width: 220,
      sorter: true,
      render: (_, record) => {
        const { created_at } = record;
        return (
          <div>
            {moment(created_at)
              .add(-localTimeOffset, "minutes")
              .format("DD-MM-YYYY, hh:mm:ss A")}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 160,
      fixed: "right",
      render: (_, record) => {
        let status;
        let color;
        if (record.is_active === 1) {
          status = "Active";
          color = "#95de64";
        } else {
          status = "Inactive";
          color = "#FF0000";
        }
        return (
          <div align={"middle"} style={{ color }}>
            {status}
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      width: 140,
      render: (_, record) => {
        const { id } = record;
        return (
          <div align={"middle"}>
            <Space size="middle">
              <Tooltip title="Edit">
                <Button
                  size={"small"}
                  className="ant-btn-action "
                  onClick={() => {
                    navigate(`/settings/money-changer/${id}`);
                  }}
                >
                  Edit
                </Button>
              </Tooltip>
            </Space>
          </div>
        );
      },
    },
  ];

  const onChange = (pagination, _, sorter) => {
    dispatch(
      setTablePagination({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    );

    if (sorter.order) {
      // Set order
      let order = sorter.order;
      if (order.match(/^ascend$/gi)) {
        order = "asc";
      } else {
        order = "desc";
      }

      let field = sorter.field;

      dispatch(
        setTableSort({
          sort: `${field}_${order}`,
        })
      );
    } else {
      // Reset order
      dispatch(resetTableSort());
    }

    fetchData();
  };

  return (
    <div className={styles.container}>
      <TableHeader
        title="Money Changers"
        showCreateButton={true}
        createButtonText={"Create New Money Changer"}
        showResetButton={true}
        showFilterButton={true}
        filterFormOnFinish={(values) => {
          console.log(values);
          setFilter(values);
        }}
        createOnClick={() => navigate("/settings/money-changer/add")}
        resetOnClick={() => {
          console.log("values");

          setFilter({});
        }}
      >
        <>
          <Col>
            <div className={styles.section_filter_title}>Filters:</div>
          </Col>
          <Col span="6">
            <Form.Item name={"name"} initialValue={[]}>
              <Input
                placeholder="Business Name"
                className="filter-search"
                suffix={<SearchOutlined className={styles.search_icon} />}
                allowClear
              />
            </Form.Item>
          </Col>
          {/* <Col span="6">
            <Form.Item name={"currency"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Currency"
                options={optionCurrency}
                // onChange={(values) => handleCurrency(values)}
                showArrow
              />
            </Form.Item>
          </Col> */}
          <Col span="6">
            <Form.Item name={"is_active"} initialValue={[]}>
              <Select
                name="select-multiple"
                mode="multiple"
                allowClear
                className="table-search-select"
                placeholder="Status"
                options={[
                  { value: "1", label: "Active" },
                  { value: "0", label: "Inactive" },
                ]}
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span="6">
            <Form.Item name="created_at" initialValue={null}>
              <RangePicker
                style={{ width: "100%" }}
                name="selected_date"
                className="date-range-picker"
                allowClear
                format="DD-MM-YYYY"
                disabledDate={(date) => moment().add(0, "days") <= date}
              />
            </Form.Item>
          </Col>
        </>
      </TableHeader>

      <Table
        className={styles.pagination_item}
        columns={columns}
        dataSource={tableData}
        onChange={onChange}
        loading={isLoading}
        size="small"
        scroll={{
          y: contentHeight - 90 - 40 - 56 - 70,
        }}
        pagination={{
          total: tableTotalData,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          current: currentPage,
        }}
      />
    </div>
  );
};

export default MoneyChanger;
