import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Tag,
  Button,
  InputNumber,
  message,
  Image,
  Divider,
} from "antd";
// ============
import styles from "./styles.module.less";
import { findAllStatuses } from "@store/app";
import reload, { findReloadById, updateReloadById } from "@store/reload";
import { find, map, filter } from "lodash";
import moment from "moment";

const { Option } = Select;

const NewReloadEdit = () => {
  const { params } = useMatch("new-reload/:id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [reloadInfo, setReloadInfo] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [allowEditing, setAllowEditing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Processing");
  const [filerUrl, setFilerUrl] = useState(null);
  const userId = useSelector((state) => state.app.user.id);
  const isLoading = useSelector((state) => state.reload.isLoading);

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve statuses
      try {
        const statusPayload = await dispatch(findAllStatuses()).unwrap();
        const { data: statusData } = statusPayload;
        const filteredStatusData = filter(statusData, (item) =>
          ["Processing", "Successful", "Unsuccessful"].includes(item.name)
        );
        setStatuses(filteredStatusData);

        const reloadPayload = await dispatch(
          findReloadById({
            id: params.id,
          })
        ).unwrap();

        const { data: reloadData } = reloadPayload;

        if (reloadData) {
          if (reloadData.status == 1 || reloadData.status == 2) {
            setReloadInfo(reloadData);

            if (reloadData.assigned_user_id) {
              let assignedUserId = reloadData.assigned_user_id;

              if (assignedUserId == userId) {
                setAllowEditing(true);
              }
            }

            formRef.current.setFieldsValue({
              status: reloadData.status,
              topup_id: reloadData.ref_id,
              topup_type: "Bank",
              submission_date: moment(reloadData.created_at).format(
                "YYYY-MM-DD, hh:mm:ss A"
              ),
              topkash_id: reloadData.user_id,
              username: reloadData.user_username, //without KYC
              fullname: reloadData.user_fullname,
              mobile: reloadData.user_phone_no,
              email: reloadData.user_email,
              requested_currency:
                reloadData.reload_currency_iso_code +
                " - " +
                reloadData.reload_currency_name,
              amount: numeral(parseFloat(reloadData.amount)).format("0.00"),
              bank: reloadData.bank_branch_name,
              acc_no: reloadData.bank_account_no,
              acc_name: reloadData.bank_account_name,
            });

            console.log(reloadData);
            setFilerUrl(reloadData.file_url);
          } else {
            message.error("This reload record is not available for editing");
            navigate("/reload");
          }
        }
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      if (values) {
        await dispatch(
          updateReloadById({
            id: params.id,
            // bank_acc_id: values.source_account,
            // amount: values.amount,
            status: values.status,
            reject_reason: values.reject_reason ? values.reject_reason : null,
          })
        ).unwrap();

        message.success("You have successfully edited the reload info");
        navigate(-1);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row justify="space-between" align="middle">
          <div className={styles.section_title}>Edit Top-Up</div>
        </Row>

        <Form
          ref={formRef}
          // layout="vertical"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 24,
          }}
          className={styles.form_details}
          colon={false}
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Row align="left">
            <Col span={16}>
              <Form.Item
                name="status"
                label="Status"
                className={styles.section_detail}
              >
                <div style={{ color: "#4f8bc2" }}>
                  {reloadInfo.status == 2
                    ? "Pending"
                    : reloadInfo.status == 3
                    ? "Successful"
                    : reloadInfo.status == 5
                    ? "Unsuccessful"
                    : "N/A"}
                </div>
              </Form.Item>

              <Form.Item
                name="change_status"
                label="Click to change status"
                className={styles.section_detail}
              >
                <div>
                  <Button
                    style={{
                      color: "black",
                      borderColor: "black",
                      background: "white",
                      padding: 4,
                      marginRight: 8,
                    }}
                  >
                    {"Cancelled"}
                  </Button>
                  <Button
                    style={{
                      color: "green",
                      borderColor: "green",
                      background: "white",
                      padding: 4,
                    }}
                  >
                    {"Completed"}
                  </Button>
                </div>
              </Form.Item>

              <Form.Item
                name="topup_id"
                label="Top-Up ID"
                className={styles.section_detail}
              >
                <div>{reloadInfo.ref_id}</div>
              </Form.Item>

              <Form.Item
                name="topup_type"
                label="Top-Up Type"
                className={styles.section_detail}
              >
                <div>{"Bank"}</div>
              </Form.Item>

              <Form.Item
                name="submission_date"
                label="Submission Date"
                className={styles.section_detail}
              >
                <div>
                  {moment(reloadInfo.created_at).format(
                    "YYYY-MM-DD, hh:mm:ss A"
                  )}
                </div>
              </Form.Item>

              <Divider className={styles.divider_reload} />

              <Form.Item
                name="topkash_id"
                label="Topkash ID"
                className={styles.section_detail}
              >
                <div>{reloadInfo.user_id}</div>
              </Form.Item>

              <Form.Item
                name="username"
                label="Username"
                className={styles.section_detail}
              >
                <div>{reloadInfo.user_username}</div>
              </Form.Item>

              <Form.Item
                name="fullname"
                label="Full Name"
                className={styles.section_detail}
              >
                <div>{reloadInfo.user_fullname}</div>
              </Form.Item>

              <Form.Item
                name="mobile"
                label="Mobile"
                className={styles.section_detail}
              >
                <div>{reloadInfo.user_phone_no}</div>
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                className={styles.section_detail}
              >
                <div>{reloadInfo.user_email}</div>
              </Form.Item>

              <Divider className={styles.divider_reload} />

              <Form.Item
                name="requested_currency"
                label="Currency"
                className={styles.section_detail}
              >
                <div>
                  {reloadInfo.reload_currency_iso_code +
                    " - " +
                    reloadInfo.reload_currency_name}
                </div>
              </Form.Item>

              <Form.Item
                name="amount"
                label="Amount"
                className={styles.section_detail}
              >
                <div>
                  {numeral(parseFloat(reloadInfo.amount)).format("0.00")}
                </div>
              </Form.Item>

              <Divider className={styles.divider_reload} />

              <Form.Item
                name="country"
                label="Country"
                className={styles.section_detail}
              >
                <div>{reloadInfo.country_name}</div>
              </Form.Item>

              <Form.Item
                name="bank"
                label="Bank"
                className={styles.section_detail}
              >
                <div>{reloadInfo.bank_branch_name}</div>
              </Form.Item>

              <Form.Item
                name="acc_no"
                label="Acc No"
                className={styles.section_detail}
              >
                <div>{reloadInfo.bank_account_no}</div>
              </Form.Item>

              <Form.Item
                name="acc_name"
                label="Acc Name"
                className={styles.section_detail}
              >
                <div>{reloadInfo.bank_account_name}</div>
              </Form.Item>

              <Divider className={styles.divider_reload} />

              <Form.Item
                name="assigned_to"
                label="Assigned To"
                className={styles.section_detail}
              >
                <div>{reloadInfo.assigned_user_fullname}</div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Image
                style={{
                  height: 600,
                  border: "1px solid #dcdcdc",
                  borderRadius: "5px",
                }}
                src={filerUrl}
              />
              <Row justify="end">
                <div className={styles.btn_container}>
                  <Button
                    type="default"
                    className="default_btn"
                    onClick={() => navigate(-1)}
                  >
                    Close
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
          {/* <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                type="default"
                className="default_btn"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                disabled={
                  !allowEditing || isLoading || selectedStatus === "Processing"
                }
                className={
                  allowEditing || !isLoading
                    ? "secondary_btn"
                    : "secondary_btn_disabled"
                }
              >
                Save Changes
              </Button>
            </div>
          </Row> */}
        </Form>
      </Card>
    </div>
  );
};

export default NewReloadEdit;
