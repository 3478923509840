import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { Card, Form, Row, Button, message, Select, Input } from "antd";
import { map } from "lodash";
import { unformat, formatNumber } from "accounting-js";
import numeral from "numeral";
// ============
import styles from "./styles.module.less";
import HistoryTable from "../../../components/history-table";
import { findActivityLogsByRefIdAndRefTable } from "@store/app";
import {
  retrieveCryptoProviders,
  getCryptoProviderById,
  findCryptoNetworkById,
  retrieveCryptoNetworks,
  createCryptoAccount,
  findCryptoAccountById,
  updateCryptoAccount
} from "../../../store/crypto";
const { Option } = Select;

const CryptoAccountEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { params } = useMatch("settings/crypto-account/:id");
  const [provider, setProvider] = useState([]);
  const [network, setNetwork] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [historyData, setHistoryData] = useState([]);

  const formRef = useRef();

  const statusOption = [
    { value: 1, label: "Active" },
    { value: 2, label: "Inactive" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve crypto account details
        const cryptoAccountDetails = await dispatch(
          findCryptoAccountById({
            id: parseInt(params.id)
          })
        ).unwrap();

        const { data: cryptoAccountInfo } = cryptoAccountDetails;

        formRef.current.setFieldsValue({
          crypto_provider_id: cryptoAccountInfo.crypto_provider_id,
          crypto_network_id: cryptoAccountInfo.crypto_network_id,
          address: cryptoAccountInfo.address,
          minimum_amount: numeral(
            parseFloat(cryptoAccountInfo.minimum_amount)
          ).format("0,0.00"),
          maximum_amount: numeral(
            parseFloat(cryptoAccountInfo.maximum_amount)
          ).format("0,0.00"),

          status:
            cryptoAccountInfo.status === 1
              ? statusOption[0].value
              : statusOption[1].value
        });

        // Retrieve providers
        const provider = await dispatch(retrieveCryptoProviders()).unwrap();
        const { data: providersData } = provider;
        setProvider(providersData);

        // Retrieve networks
        const network = await dispatch(retrieveCryptoNetworks()).unwrap();
        const { data: networksData } = network;
        setNetwork(networksData);

        // Retrieve history
        const { data: historyData } = await dispatch(
          findActivityLogsByRefIdAndRefTable({
            reference_id: params.id,
            reference_table: "crypto_accounts"
          })
        ).unwrap();

        setHistoryData(historyData);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    values = {
      ...values,
      id: params.id
    };
    try {
      if (values) {
        let minimum_amount =
          unformat(values.minimum_amount.replace(/(\.|\,)/gi, "")) / 100;

        let maximum_amount =
          unformat(values.maximum_amount.replace(/(\.|\,)/gi, "")) / 100;
        await dispatch(
          updateCryptoAccount({
            id: params.id,
            crypto_provider_id: values.crypto_provider_id,
            crypto_network_id: values.crypto_network_id,
            address: values.address,
            minimum_amount: minimum_amount,
            maximum_amount: maximum_amount,
            status: parseInt(values.status)
          })
        ).unwrap();

        message.success("Successfully updated the crypto account");
        navigate(-1);
      } else {
        console.error("Some required values are missing");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const minAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const minimum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(minimum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      minimum_amount: formattedAmount
    });
  });

  const maxAmountOnChange = useCallback((text) => {
    if (typeof text === "number") {
      text = text.toString();
    }

    const maximum_amount = unformat(text.replace(/(\.|\,)/g, "")) / 100;
    const formattedAmount = formatNumber(maximum_amount, { precision: 2 });

    formRef.current.setFieldsValue({
      maximum_amount: formattedAmount
    });
  });

  const providerOnChange = async (id) => {
    const { data: providerDetails } = await dispatch(
      getCryptoProviderById({
        id
      })
    ).unwrap();

    setSelectedProvider(providerDetails);
  };
  const networkOnChange = async (id) => {
    const { data: networkDetails } = await dispatch(
      findCryptoNetworkById({
        id
      })
    ).unwrap();

    setSelectedNetwork(networkDetails);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card_container}>
        <Row>
          <div className={styles.section_title}>Edit USDT Crypto Account</div>
        </Row>

        <Form
          ref={formRef}
          colon={false}
          name="basic"
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 20
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off"
          className="form-details"
        >
          <Form.Item name="status" label="Status">
            <Select name="status">
              {map(statusOption, (item) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="crypto_provider_id"
            label="Provider"
            rules={[
              {
                required: true,
                message: "Please select a provider"
              }
            ]}
          >
            <Select
              name="crypto_provider_id"
              // value={selectedProvider}
              placeholder="Select Provider"
              onChange={providerOnChange}
            >
              {map(provider, (item) => {
                return (
                  <Option key={item.value} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="crypto_network_id"
            label="Network"
            rules={[
              {
                required: true,
                message: "Please select a network"
              }
            ]}
          >
            <Select name="crypto_network_id" placeholder="Select Network">
              {map(network, (item) => {
                return (
                  <Option key={item.value} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="address"
            className="input"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter the address"
              }
            ]}
          >
            <Input placeholder="Address" />
          </Form.Item>

          <Form.Item
            label="Min Amount"
            name="minimum_amount"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the min amount"
              },
              {
                required: true,
                validator: async (_, value) => {
                  let maxAmount =
                    formRef.current.getFieldValue("maximum_amount");
                  maxAmount = unformat(maxAmount?.replace(/(\,)/g, ""));

                  value = value ? unformat(value.replace(/(\,)/g, "")) : "";

                  if (value) {
                    if (value === 0) {
                      throw new Error("Please enter the min amount");
                    } else if (
                      parseFloat(value) <
                      numeral(
                        parseFloat(selectedCurrency?.minimum_amount)
                      ).format("0.00")
                    ) {
                      throw new Error(
                        `Minimum amount is ${numeral(
                          parseFloat(selectedCurrency?.minimum_amount)
                        ).format("0,0.00")} ${selectedCurrency?.iso_code}`
                      );
                    } else if (parseFloat(value) >= maxAmount) {
                      throw new Error(
                        "Min Amount must be lower than Max Amount"
                      );
                    }
                  } else {
                    // throw new Error("Please enter Min Amount");
                  }
                }
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={(e) => {
                minAmountOnChange(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Max Amount"
            name="maximum_amount"
            className="input"
            rules={[
              {
                required: true,
                message: "Please enter the max amount"
              },
              {
                validator: async (_, value) => {
                  value = value ? unformat(value.replace(/(\,)/g, "")) : null;

                  if (value) {
                    if (value === 0) {
                      throw new Error("Please enter the max amount");
                    }
                  } else {
                    // throw new Error("Please enter Max Amount");
                  }
                }
              }
            ]}
          >
            <Input
              className="input"
              placeholder="0.00"
              style={{ textAlign: "right" }}
              onChange={(e) => {
                maxAmountOnChange(e.target.value);
              }}
            />
          </Form.Item>

          <Row justify="end">
            <div className={styles.btn_container}>
              <Button
                onClick={() => navigate("/settings/crypto-account")}
                className="default_btn"
              >
                Cancel
              </Button>
            </div>
            <div className={styles.btn_container}>
              <Button
                type="default"
                htmlType="submit"
                className="ant-btn-green"
              >
                Save
              </Button>
            </div>
          </Row>
        </Form>

        <HistoryTable data={historyData} />
      </Card>
    </div>
  );
};

export default CryptoAccountEdit;
