import React, { useEffect, useState, useCallback } from "react";
import { Row, Button, Col, Divider } from "antd";
import { Link, useNavigate } from "react-router-dom";
// ============
import styles from "./styles.module.less";
import { useMemo } from "react";
import { chain, filter, find, isArray, map, size, some } from "lodash";
import { useSelector } from "react-redux";
import { createMongoAbility } from "@casl/ability";

const Settings = () => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.app.user);
  const [filteredAbilitySetting, setFilteredAbilitySetting] = useState([]);
  const [filteredAbilitySetting2, setFilteredAbilitySetting2] = useState([]);

  const settingItems = useMemo(() => {
    return [
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/bank-account">Bank Accounts</Link>
            </Button>
          </div>
        ),
        subject: "BankAccount"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/bank">Banks</Link>
            </Button>
          </div>
        ),
        subject: "Bank"
      },

      {
        label: <Divider className="settings-divider" />,
        subject: ["USDTCryptoAccount", "CryptoProviders"]
      },

      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/crypto-account">USDT Crypto Accounts</Link>
            </Button>
          </div>
        ),
        subject: "USDTCryptoAccount"
      },

      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/crypto-provider">Crypto Providers</Link>
            </Button>
          </div>
        ),
        subject: "CryptoProvider"
      },
      {
        label: <Divider className="settings-divider" />,
        subject: ["Currency", "ActiveCountries"]
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/currency">Currencies</Link>
            </Button>
          </div>
        ),
        subject: "Currency"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/country">Active Countries</Link>
            </Button>
          </div>
        ),
        subject: "ActiveCountry"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/fee">Fees</Link>
            </Button>
          </div>
        ),
        subject: "Fee"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/margin">Margin</Link>
            </Button>
          </div>
        ),
        subject: "Margin"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/cost-exchange-rate">Cost Exchange Rates</Link>
            </Button>
          </div>
        ),
        subject: "CostExchangeRate"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/manual-cost-exchange-rate">
                Manual Cost Exchange Rates
              </Link>
            </Button>
          </div>
        ),
        subject: "CostExchangeRateSetting"
      }
    ];
  });

  const settingItems2 = useMemo(() => {
    return [
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/staff">Staff</Link>
            </Button>
          </div>
        ),
        subject: "User"
      },
      {
        label: <Divider className="settings-divider" />,
        subject: ["BusinessType"]
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/business-type">Business Types</Link>
            </Button>
          </div>
        ),
        subject: "BusinessType"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/money-changer">Money Changers</Link>
            </Button>
          </div>
        ),
        subject: "MoneyChanger"
      },

      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/merchant">Merchants</Link>
            </Button>
          </div>
        ),
        subject: "Merchant"
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/banner">Banners</Link>
            </Button>
          </div>
        ),
        subject: "Banner"
      },

      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/communication-channel">
                Communication Channels
              </Link>
            </Button>
          </div>
        ),
        subject: "CommunicationChannel"
      },

      {
        label: (
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/faq")}
            >
              FAQ
            </Button>
          </div>
        ),
        subject: "Faq"
      },

      {
        label: <Divider className="settings-divider" />,
        subject: ["DeleteMemberRequest"]
      },
      {
        label: (
          <div>
            <Button className="text-blue ant-btn-link">
              <Link to="/settings/delete-member-request">
                Member Deletion Requests
              </Link>
            </Button>
          </div>
        ),
        subject: "DeleteMemberRequest"
      }
      // {
      //   label: <Divider className="settings-divider" />,
      //   subject: ["Faq"]
      // }
    ];
  });

  useEffect(() => {
    if (permissions && permissions.length > 0) {
      const findSetting = find(permissions, { subject: "Setting" });

      if (findSetting) {
        const ability = createMongoAbility(findSetting?.child);

        const filterAbility = chain(settingItems)
          .filter((item) => {
            const { subject } = item;

            if (isArray(subject)) {
              return some(subject, (item) => ability.can("read", item));
            }

            return ability.can("read", item.subject);
          })
          .map((item) => {
            const { children } = item;

            if (size(children)) {
              const filteredChildren = filter(children, (item) => {
                const { subject } = item;

                return ability.can("read", subject);
              });

              return {
                ...item,
                children: filteredChildren
              };
            }

            return item;
          })
          .value();

        const filterAbility2 = chain(settingItems2)
          .filter((item) => {
            const { subject } = item;

            if (isArray(subject)) {
              return some(subject, (item) => ability.can("read", item));
            }

            return ability.can("read", item.subject);
          })
          .map((item) => {
            const { children } = item;

            if (size(children)) {
              const filteredChildren = filter(children, (item) => {
                const { subject } = item;

                return ability.can("read", subject);
              });

              return {
                ...item,
                children: filteredChildren
              };
            }

            return item;
          })
          .value();
        setFilteredAbilitySetting(filterAbility);
        setFilteredAbilitySetting2(filterAbility2);
      }

      // setFilteredAbility(filterAbility);
    }
  }, [permissions]);

  return (
    <div className={styles.card_container}>
      <Row>
        <div className={styles.section_title}>Settings</div>
      </Row>
      <Row>
        <Col span={4}>
          {map(filteredAbilitySetting, (item) => {
            console.log(item);
            return item.label;
          })}
          {/* <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/bank-account")}
            >
              Bank Accounts
            </Button>
          </div>

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/crypto-account")}
            >
              USDT Crypto Accounts
            </Button>
          </div>

          <Divider className="settings-divider" />

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/bank")}
            >
              Banks
            </Button>
          </div>

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/crypto-provider")}
            >
              Crypto Providers
            </Button>
          </div>
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/currency")}
            >
              Currencies
            </Button>
          </div>
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/country")}
            >
              Active Countries
            </Button>
          </div>

          <Divider className="settings-divider" />
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/fee")}
            >
              Fees
            </Button>
          </div>
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/margin")}
            >
              Margin
            </Button>
          </div>
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/cost-exchange-rate")}
            >
              Cost Exchange Rates
            </Button>
          </div>

          <Divider className="settings-divider" /> */}
        </Col>
        <Col span={2}></Col>
        <Col span={4}>
          {/* <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/customer-service-user")}
            >
              Customer Service Users
            </Button>
          </div>
          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/super-admin")}
            >
              Super Admins
            </Button>
          </div> */}
          {map(filteredAbilitySetting2, (item) => {
            return item.label;
          })}
          {/* <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/staff")}
            >
              Users
            </Button>
          </div>

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/delete-member-request")}
            >
              Delete Member Requests
            </Button>
          </div>

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/communication-channel")}
            >
              Communication Channel
            </Button>
          </div>

          <Divider className="settings-divider" />

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/banner")}
            >
              Banner
            </Button>
          </div>

          <Divider className="settings-divider" />

          <div>
            <Button
              className="text-blue ant-btn-link"
              onClick={() => navigate("/settings/money-changer")}
            >
              Money Changer
            </Button>
          </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
